import { Bar } from '@ant-design/plots';
import { Card } from 'antd';
import { Dayjs } from 'dayjs';
import { Block } from '@components/layouts';
import { Salon } from '@components/state';
import { chartHelper } from '@/helpers';
import { useCustomerSourceChartRequests } from '@hooks/useCustomerSourceChartRequests';
import { useHttpQueries } from '@/hooks';
import { useMemo } from 'react';

interface ReportCustomerSourceBarChartProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly salons?: Salon[] | undefined;
    readonly selectedSalonBranchId?: number | undefined;
    readonly salon?: Salon;
}
interface BarDataSourceChart {
    labelName?: string;
    value?: number;
}
function ReportCustomerSourceBarChart(props: ReportCustomerSourceBarChartProps) {
    const { dateRange, salons, selectedSalonBranchId, salon } = props;
    const viewType = chartHelper.getViewTypeChart(dateRange);

    const {
        customerSourceChartRequests
    } = useCustomerSourceChartRequests({
        dateRange,
        salons,
        selectedSalonBranchId,
        salon,
        viewType,
    });

    const queriesCustomerSourceChart = useHttpQueries(customerSourceChartRequests);

    const totalCustomerSourceChart = useMemo(() => {
        const isLoading = queriesCustomerSourceChart.some(query => query.isLoading);
        const CustomerNewCharts = queriesCustomerSourceChart.flatMap(o => o.data);
        return { isLoading, CustomerNewCharts };
    }, [queriesCustomerSourceChart]);

    const dataChart: BarDataSourceChart[] = totalCustomerSourceChart.CustomerNewCharts.map(item => ({
        labelName: item?.typeSource,
        value: item?.valueSource
    })) ?? [];
    
    const config = {
        data: dataChart,
        xField: 'labelName',
        yField: 'value',
        height: dataChart.length * 90,
        style: {
            maxWidth: 10,
            radius: 10,
        },
        className: 'bar-chart',
        markBackground: {
            label: {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                text: ({ originData }: any) => {
                    return `${originData.value}`;
                },
                position: 'right',
                dy: -20,
                style: {
                    fill: '#aaa',
                    fillOpacity: 1,
                    fontSize: 14,
                },
            },
            style: {
                fill: '#eee',
            },
        },
        label: {
            position: 'left',
            dy: -20,
            text: ({ labelName }: BarDataSourceChart) => {
                return `${labelName}`;
            },
            style: {
                fill: '#1E1E1E',
            }
        },
        scale: {
            y: {
                domain: [0, 200],
            },
        },
        axis: {
            x: {
                tick: false,
                title: false,
                label: false
            },
        },
        interaction: {
            elementHighlightByColor: false,
        },
    };
    return (
        <Block width='100%'>
            <Card title="Nguồn khách hàng">
                <Bar {...config} loading={totalCustomerSourceChart.isLoading} />
            </Card>
        </Block>
    );
}

export default ReportCustomerSourceBarChart;