import React from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { ModalCustom } from './ModalCustom';
import { globalModalState } from '../state';

export const ModalGlobal = () => {
    const [modalState] = useRecoilState(globalModalState);
    const resetModalState = useResetRecoilState(globalModalState);

    const onCancel = React.useCallback(() => {
        modalState?.onCancel?.();
        resetModalState();
    }, [modalState, resetModalState]);

    const titleModal = typeof modalState?.titleTransCode === 'string' ? modalState.titleTransCode : modalState?.titleTransCode;
    return (
        <ModalCustom
            destroyOnClose
            open={modalState?.isOpen}
            closeIcon={modalState?.closeIcon}
            title={titleModal}
            onCancel={onCancel}
            cancelText={modalState?.cancelText}
            okText={modalState?.okText}
            footer={modalState?.footer}
            width={modalState?.width}
            onOk={modalState?.onOk}
            showDivider={modalState?.showDivider}
        >
            {modalState?.content}
        </ModalCustom>
    );
};
