export const SplitPaymentIcon = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.5581 0H2.44186C1.09326 0 0 1.09326 0 2.44186V10.814C0 12.1626 1.09326 13.2558 2.44186 13.2558H27.5581C28.9067 13.2558 30 12.1626 30 10.814V2.44186C30 1.09326 28.9067 0 27.5581 0Z" fill="#ACCEFC" />
            <path d="M24.7674 5.58105H8.0232C7.74677 5.58467 7.48268 5.69609 7.2872 5.89157C7.09172 6.08705 6.9803 6.35114 6.97668 6.62757V27.5578C6.98035 28.2043 7.23879 28.8233 7.69594 29.2804C8.15308 29.7376 8.77205 29.996 9.41855 29.9997H23.372C24.0185 29.996 24.6375 29.7376 25.0946 29.2804C25.5518 28.8233 25.8102 28.2043 25.8139 27.5578V6.62757C25.8103 6.35114 25.6989 6.08705 25.5034 5.89157C25.3079 5.69609 25.0438 5.58467 24.7674 5.58105Z" fill="#ACCEFC" />
            <path d="M21.9769 5.58105H5.23267C4.95624 5.58467 4.69215 5.69609 4.49667 5.89157C4.30119 6.08705 4.18977 6.35114 4.18616 6.62757V23.3718C4.18982 24.0182 4.44826 24.6372 4.90541 25.0944C5.36256 25.5515 5.98153 25.8099 6.62802 25.8136H20.5815C21.228 25.8099 21.847 25.5515 22.3041 25.0944C22.7613 24.6372 23.0197 24.0182 23.0234 23.3718V6.62757C23.0198 6.35114 22.9083 6.08705 22.7129 5.89157C22.5174 5.69609 22.2533 5.58467 21.9769 5.58105Z" fill="#3D8CF5" />
            <path d="M26.1626 7.67408H3.83704C3.55949 7.67408 3.2933 7.56382 3.09704 7.36756C2.90078 7.1713 2.79053 6.90512 2.79053 6.62757C2.79053 6.35001 2.90078 6.08383 3.09704 5.88757C3.2933 5.69131 3.55949 5.58105 3.83704 5.58105H26.1626C26.4402 5.58105 26.7064 5.69131 26.9026 5.88757C27.0989 6.08383 27.2091 6.35001 27.2091 6.62757C27.2091 6.90512 27.0989 7.1713 26.9026 7.36756C26.7064 7.56382 26.4402 7.67408 26.1626 7.67408Z" fill="#3D8CF5" />
            <path d="M16.1874 17.5369C15.9387 17.5369 15.7003 17.4381 15.5245 17.2623C15.3487 17.0865 15.2499 16.8481 15.2499 16.5994V14.5244C15.2499 14.1744 14.6249 14.1744 14.6249 14.5244V15.3494C14.6249 15.9296 14.3944 16.486 13.9842 16.8962C13.5739 17.3064 13.0175 17.5369 12.4374 17.5369C11.8572 17.5369 11.3008 17.3064 10.8906 16.8962C10.4803 16.486 10.2499 15.9296 10.2499 15.3494V13.2744C10.2499 13.0258 10.3487 12.7873 10.5245 12.6115C10.7003 12.4357 10.9387 12.3369 11.1874 12.3369C11.436 12.3369 11.6745 12.4357 11.8503 12.6115C12.0261 12.7873 12.1249 13.0258 12.1249 13.2744V15.3494C12.1249 15.6994 12.7499 15.6994 12.7499 15.3494V14.5244C12.7499 13.9443 12.9803 13.3879 13.3906 12.9776C13.8008 12.5674 14.3572 12.3369 14.9374 12.3369C15.5175 12.3369 16.0739 12.5674 16.4842 12.9776C16.8944 13.3879 17.1249 13.9443 17.1249 14.5244V16.5994C17.1249 16.8481 17.0261 17.0865 16.8503 17.2623C16.6745 17.4381 16.436 17.5369 16.1874 17.5369Z" fill="white" />
            <path d="M17.4376 15.875H16.1876C15.939 15.875 15.7005 15.7762 15.5247 15.6004C15.3489 15.4246 15.2501 15.1861 15.2501 14.9375C15.2501 14.6889 15.3489 14.4504 15.5247 14.2746C15.7005 14.0988 15.939 14 16.1876 14H17.4376C17.6863 14 17.9247 14.0988 18.1005 14.2746C18.2763 14.4504 18.3751 14.6889 18.3751 14.9375C18.3751 15.1861 18.2763 15.4246 18.1005 15.6004C17.9247 15.7762 17.6863 15.875 17.4376 15.875Z" fill="white" />
            <path d="M11.1875 15.875H9.9375C9.68886 15.875 9.4504 15.7762 9.27459 15.6004C9.09877 15.4246 9 15.1861 9 14.9375C9 14.6889 9.09877 14.4504 9.27459 14.2746C9.4504 14.0988 9.68886 14 9.9375 14H11.1875C11.4361 14 11.6746 14.0988 11.8504 14.2746C12.0262 14.4504 12.125 14.6889 12.125 14.9375C12.125 15.1861 12.0262 15.4246 11.8504 15.6004C11.6746 15.7762 11.4361 15.875 11.1875 15.875Z" fill="white" />
        </svg>
    );
}
