import { TableColumnsType } from "antd";
import { TableCustom } from "@components/table";
import { Dayjs } from "dayjs";
import { useHttpQuery } from "@/hooks";
import { getRevenueOfObjectTypeDetail, GetRevenueOfObjectTypeDetailResponse } from "@/api/report/revenue";
import { salonActiveState } from "@/components/state";
import { useRecoilState } from "recoil";

export interface RevenueByObjectTypeDetailData {
    readonly id?: string;
    readonly name?: string;
    readonly revenue?: string;
    readonly quantity?: string;
    readonly discount?: string;
}

interface RevenueByObjectTypeDetailTableProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly objectType: "SERVICE" | "PRODUCT";
    readonly objectId: number;
}

export default function RevenueByObjectTypeDetailTable(props: RevenueByObjectTypeDetailTableProps) {
    const [salonActive] = useRecoilState(salonActiveState);

    const {data : revenueOfObjectTypeDetail} = useHttpQuery(getRevenueOfObjectTypeDetail, {
        query: {
            startDate: props.dateRange[0].toISOString(),
            endDate: props.dateRange[1].toISOString(),
            objectType: props.objectType,
            objectId: props.objectId,
            salonId: salonActive?.id ?? 0
        }
    });

    const columns: TableColumnsType<GetRevenueOfObjectTypeDetailResponse> = [
        {
            title: 'Chi nhánh',
            dataIndex: 'salonBranchId',
            key: 'salonBranchId',
            render: (salonBranchId: number) => {
                return salonActive?.salonBranches?.find(branch => branch.id === salonBranchId)?.name;
            }
        },
        {
            title: 'Số lượng',
            dataIndex: 'totalQuantity',
            key: 'totalQuantity',
        },
        {
            title: 'Doanh thu',
            dataIndex: 'totalRevenue',
            key: 'revenue',
            width: 150
        }
    ];

    return (
        <div style={{ paddingTop: 20 }}>
            <TableCustom
                showHeader={false}
                columns={columns}
                dataSource={revenueOfObjectTypeDetail?.items}
                showFooter={false}
            />
        </div>
    )
}
