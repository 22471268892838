
export const ExportIconWareHouse = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z" fill="#F6FFED" />
            <path d="M24.75 33C28.3552 32.9994 30.183 32.9738 31.3284 31.8284C32.5 30.6569 32.5 28.7713 32.5 25V18.998C32.3548 19.0001 32.1509 19 32 19H16C15.8491 19 15.6452 19.0001 15.5 18.998V25C15.5 28.7713 15.5 30.6569 16.6716 31.8284C17.817 32.9738 19.6449 32.9994 23.25 33L23.25 25.9545L21.5575 27.8351C21.2804 28.1429 20.8062 28.1679 20.4983 27.8908C20.1904 27.6137 20.1654 27.1395 20.4425 26.8316L23.4425 23.4983C23.5848 23.3402 23.7874 23.25 24 23.25C24.2126 23.25 24.4152 23.3402 24.5575 23.4983L27.5575 26.8316C27.8346 27.1395 27.8096 27.6137 27.5017 27.8908C27.1938 28.1679 26.7196 28.1429 26.4425 27.8351L24.75 25.9545L24.75 33Z" fill="#56C22D" />
            <path opacity="0.5" d="M14 17C14 16.0572 14 15.5858 14.2929 15.2929C14.5858 15 15.0572 15 16 15H32C32.9428 15 33.4142 15 33.7071 15.2929C34 15.5858 34 16.0572 34 17C34 17.9428 34 18.4142 33.7071 18.7071C33.4142 19 32.9428 19 32 19H16C15.0572 19 14.5858 19 14.2929 18.7071C14 18.4142 14 17.9428 14 17Z" fill="#56C22D" />
        </svg>
    );
};

