import { Route, Routes } from 'react-router-dom'
import { Login } from './login/Login';

export const Auth = () => {
    return (
        <Routes>
            <Route path='/login' Component={Login} />
        </Routes>
    );
};
