import { TableColumnsType } from "antd";
import { ColumnActions, TableCustom } from "@components/table";
import { useHttpQuery } from "@hooks/useHttpQuery";
import { GetAllAccountResponse, getAllAccounts } from "@/api";
import { globalModalState } from "@components/state";
import { useRecoilState } from "recoil";

export function AccountTable() {
    const { data } = useHttpQuery(getAllAccounts, {});
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);

    const createCustomerGroup = () => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: "Thêm tài khoản mới",
            content: <></>
        });
    };

    const accounts = data;
    const columns: TableColumnsType<GetAllAccountResponse> = [
        {
            title: 'Tên tài khoản',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Điện thoại',
            dataIndex: 'email',
            key: 'email',
            width: '100px'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '300px'
        },
        {
            title: 'Vai trò',
            dataIndex: 'role',
            key: 'role',
            width: '250px'
        },
        {
            title: 'Salon',
            dataIndex: 'role',
            key: 'role',
            width: '250px'
        },
        {
            title: "",
            render: () => {
                return (
                    <ColumnActions
                        viewDetailUrl=""
                        onClickEdit={() => {
                        }}
                        onClickDelete={() => {
                        }}

                    />
                );
            },
        },
    ];

    return (
        <TableCustom
            scroll={{ x: 800 }}
            placeholderSearchTransCode='Search ID, Client, Phone, Email'
            showHeader={true}
            columns={columns}
            onCreate={createCustomerGroup}
            dataSource={accounts?.items}
        />
    )
}
