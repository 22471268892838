import { TableColumnsType } from "antd";
import { TableCustom } from "@components/table";

export interface OverviewAppointmentDetailData {
    readonly id?: string;
    readonly name?: string;
    readonly value?: string;

}

export function OverviewAppointmentDetailTable() {

    const data: OverviewAppointmentDetailData[] = [
        {
            id: '1',
            value: "250",
            name: "Chi nhánh 1",
        },
        {
            id: '2',
            value: "615000",
            name: "Chi nhánh 2",
        },
        {
            id: '3',
            value: "432000",
            name: "Chi nhánh 3",
        },
        {
            id: '5',
            value: "45000",
            name: "Chi nhánh 4",
        }
    ];

    const columns: TableColumnsType<OverviewAppointmentDetailData> = [
        {
            title: 'Chi nhánh',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Giá trị',
            dataIndex: 'value',
            key: 'value',
            width: 150
        }
    ];

    return (
        <div style={{ paddingTop: 20 }}>
            <TableCustom
                showHeader={false}
                columns={columns}
                dataSource={data}
                showFooter={false}
            />
        </div>
    )
}
