import { TableColumnsType } from "antd";
import { TableCustom } from "@components/table";

export interface ServiceByStaffAppointmentDetailData {
    readonly id?: string;
    readonly name?: string;
    readonly value?: string;
    readonly quantity?: string;
    readonly url?: string;
}

export function ServiceByStaffAppointmentDetailTable() {

    const data: ServiceByStaffAppointmentDetailData[] = [
        {
            id: '1',
            value: "250",
            name: "Service 1",
            quantity: "8",
        },
        {
            id: '2',
            value: "615000",
            name: "Service 2",
            quantity: "7",
        },
        {
            id: '3',
            value: "432000",
            name: "Service 4",
            quantity: "5",
        },
        {
            id: '5',
            value: "45000",
            name: "Service 6",
            quantity: "2",
        },
        {
            id: '6',
            value: "345000",
            name: "Service 8",
            quantity: "5",
        }
    ];

    const columns: TableColumnsType<ServiceByStaffAppointmentDetailData> = [
        {
            title: 'Dịch vụ',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 250
        },
        {
            title: 'Doanh thu',
            dataIndex: 'value',
            key: 'value',
            width: 150
        }
    ];

    return (
        <div style={{ paddingTop: 20 }}>
            <TableCustom
                showHeader={false}
                columns={columns}
                dataSource={data}
                showFooter={false}
            />
        </div>
    )
}
