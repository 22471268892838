import { Dayjs } from 'dayjs';
import { Card, Dropdown, MenuProps } from 'antd';
import { Block, FlexBox, Spacer } from '@components/layouts';
import { Text } from "@components/typo";
import { AppButton } from '@components/buttons';
import { IconMore } from '@components/icons';
import { useState } from 'react';
import { Column } from '@ant-design/charts';

interface WarehouseReportChartProps {
    readonly dateRange: [Dayjs, Dayjs];
}
interface ColumnData {
    readonly xField: string;
    readonly yField: number;
    readonly name?: string;
    readonly color?: string;
    readonly value?: number;
    readonly category?: string;
}
export default function WarehouseReportChart(props: WarehouseReportChartProps) {
    const { dateRange } = props;
    console.log(dateRange)
    const [viewType, setViewType] = useState(true);

    const items: MenuProps['items'] = [
        {
            key: 1,
            label: (
                <Text onClick={() => { setViewType(true) }}>Hiển thị theo biểu đồ cột</Text>
            ),
        },
        {
            key: 2,
            label: (
                <Text onClick={() => { setViewType(false) }}>Hiển thị theo biểu đồ đường</Text>
            )
        },
    ];

    const appointmentsData = [
        {
            "date": "2024-01-01",
            "value": 10,
            "type": "Tôn"
        },
        {
            "date": "2024-02-01",
            "value": 12,
            "type": "Tôn"
        },
        {
            "date": "2024-03-01",
            "value": 15,
            "type": "Tôn"
        },
        {
            "date": "2024-04-01",
            "value": 20,
            "type": "Tôn"
        },
        {
            "date": "2024-05-01",
            "value": 18,
            "type": "Tôn"
        },
        {
            "date": "2024-06-01",
            "value": 22,
            "type": "Tôn"
        },
        {
            "date": "2024-07-01",
            "value": 25,
            "type": "Tôn"
        },
        {
            "date": "2024-08-01",
            "value": 30,
            "type": "Tôn"
        },
        {
            "date": "2024-09-01",
            "value": 28,
            "type": "Tôn"
        },
        {
            "date": "2024-10-01",
            "value": 35,
            "type": "Tôn"
        },
        {
            "date": "2024-11-01",
            "value": 38,
            "type": "Tôn"
        },
        {
            "date": "2024-12-01",
            "value": 40,
            "type": "Tôn"
        },
        {
            "date": "2024-01-01",
            "value": 8,
            "type": "Xuất kho"
        },
        {
            "date": "2024-02-01",
            "value": 10,
            "type": "Xuất kho"
        },
        {
            "date": "2024-03-01",
            "value": 12,
            "type": "Xuất kho"
        },
        {
            "date": "2024-04-01",
            "value": 14,
            "type": "Xuất kho"
        },
        {
            "date": "2024-05-01",
            "value": 16,
            "type": "Xuất kho"
        },
        {
            "date": "2024-06-01",
            "value": 18,
            "type": "Xuất kho"
        },
        {
            "date": "2024-07-01",
            "value": 20,
            "type": "Xuất kho"
        },
        {
            "date": "2024-08-01",
            "value": 22,
            "type": "Xuất kho"
        },
        {
            "date": "2024-09-01",
            "value": 24,
            "type": "Xuất kho"
        },
        {
            "date": "2024-10-01",
            "value": 26,
            "type": "Xuất kho"
        },
        {
            "date": "2024-11-01",
            "value": 28,
            "type": "Xuất kho"
        },
        {
            "date": "2024-12-01",
            "value": 30,
            "type": "Xuất kho"
        },
        {
            "date": "2024-01-01",
            "value": 5,
            "type": "Nhập kho"
        },
        {
            "date": "2024-02-01",
            "value": 7,
            "type": "Nhập kho"
        },
        {
            "date": "2024-03-01",
            "value": 8,
            "type": "Nhập kho"
        },
        {
            "date": "2024-04-01",
            "value": 9,
            "type": "Nhập kho"
        },
        {
            "date": "2024-05-01",
            "value": 10,
            "type": "Nhập kho"
        },
        {
            "date": "2024-06-01",
            "value": 12,
            "type": "Nhập kho"
        },
        {
            "date": "2024-07-01",
            "value": 14,
            "type": "Nhập kho"
        },
        {
            "date": "2024-08-01",
            "value": 16,
            "type": "Nhập kho"
        },
        {
            "date": "2024-09-01",
            "value": 18,
            "type": "Nhập kho"
        },
        {
            "date": "2024-10-01",
            "value": 20,
            "type": "Nhập kho"
        },
        {
            "date": "2024-11-01",
            "value": 22,
            "type": "Nhập kho"
        },
        {
            "date": "2024-12-01",
            "value": 25,
            "type": "Nhập kho"
        }
    ];

    const config = {
        data: appointmentsData,
        xField: 'date',
        yField: 'value',
        colorField: 'type',
        stack: true,
        style: {
            radiusTopLeft: 10,
            radiusTopRight: 10,
            insetLeft: 10,
            insetRight: 10,
            fill: (type: ColumnData) => {
                return type.color;
            },
            maxWidth: 40,
        },
        scale: {
            x: {
                type: 'band',
            },
            color: {
                range: ['#004EEB', '#40A9FF', '#91D5FF'],
            },
            width: 10
        },
        legend: {
            color: {
                layout: {
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    alignContent: 'flex-end',
                    alignItems: 'flex-end'
                },
                itemWidth: '100%',
            },
        },

        interaction: {
            // tooltip: {
            //     render: (e: any, { title, items }: any) => {
            //         return (
            //             <div key={title}>
            //                 <h4>{title}</h4>
            //                 {items.map((item: { name: any; value: any; color: any; }) => {
            //                     const { name, value, color } = item;
            //                     return (
            //                         <div>
            //                             <div style={{ margin: 0, display: 'flex', justifyContent: 'space-between' }}>
            //                                 <div>
            //                                     <span
            //                                         style={{
            //                                             display: 'inline-block',
            //                                             width: 6,
            //                                             height: 6,
            //                                             borderRadius: '50%',
            //                                             backgroundColor: color,
            //                                             marginRight: 6,
            //                                         }}
            //                                     ></span>
            //                                     <span>{name}</span>
            //                                 </div>
            //                                 <b>{value}</b>
            //                             </div>
            //                         </div>
            //                     );
            //                 })}
            //             </div>
            //         );
            //     },
            // },
        },
    };

    return (
        <Block overflow='hidden' maxWidth='100%'>
            <Spacer top={30}>
                <Card>
                    <FlexBox direction='row-reverse'>
                        <Dropdown trigger={['click']} menu={{ items }}>
                            <AppButton border={0}>
                                <IconMore />
                            </AppButton>
                        </Dropdown>
                    </FlexBox>
                    {viewType == true && (
                        <Column {...config} />
                    )}
                    {viewType == false && (
                        <Column {...config} />
                    )}
                </Card>
            </Spacer>
        </Block>
    );
}
