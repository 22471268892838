import { FormProvider, useForm } from 'react-hook-form';

interface FormBaseProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly onSubmit: any;
    readonly defaultValues?: unknown;
    readonly children: ((form: ReturnType<typeof useForm>) => React.ReactNode) | React.ReactNode;
}

export const FormBase = (props: FormBaseProps) => {
    const form = useForm({
        shouldUseNativeValidation: true,
        defaultValues: props.defaultValues ?? {},
    });

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(props.onSubmit)}>
                {props.children instanceof Function ? props.children(form) : props.children}
            </form>
        </FormProvider>
    );
};