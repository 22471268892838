import { atom } from 'recoil';

export interface LoginState {
    readonly accessToken?: string;
    readonly refreshToken?: string;
}

export const loginState = atom<LoginState | null>({
    key: 'loginState',
    default: null
});