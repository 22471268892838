import dayjs, { Dayjs } from 'dayjs';
import { ViewType } from '../shared/enum/chartEnum';

export const chartHelper = {
    getDayOrdinalFromDate: (dateString?: string) => {
        const parsedDate = dayjs(dateString);

        const dayOfMonth = parsedDate.date();

        const result = `Ngày ${dayOfMonth}`;

        return result;
    },

    getViewTypeChart: (dateRange: [Dayjs, Dayjs]) => {
        const isStartAndEndOfMonth = dateRange[0].isSame(dateRange[0].startOf('month')) && dateRange[1].isSame(dateRange[1].endOf('month')) && dateRange[0].isSame(dateRange[1], 'month');
        const isStartAndEndOfWeek = dateRange[0].isSame(dateRange[0].startOf('week')) && dateRange[1].isSame(dateRange[1].endOf('week'));
        const isStartAndEndOfYear = dateRange[0].isSame(dateRange[0].startOf('year')) && dateRange[1].isSame(dateRange[1].endOf('year')) && dateRange[0].isSame(dateRange[1], 'year');
        const isOnlyOneDay = dateRange[0].isSame(dateRange[1], 'day');

        if (isOnlyOneDay) {
            return ViewType.Hour;
        }

        if (isStartAndEndOfWeek) {
            return ViewType.Week;
        }

        if (isStartAndEndOfMonth) {
            return ViewType.Day;
        }

        if (isStartAndEndOfYear) {
            return ViewType.Month;
        }

        return undefined;
    },


    getLabelDateByViewChart: (viewType?: ViewType, date?: string) => {
        switch (viewType) {
            case ViewType.Hour:
                return dayjs(date).format('HH:mm');
            case ViewType.Day:
                return chartHelper.getDayOrdinalFromDate(date);
            case ViewType.Week:
                return dayjs(date).format('dddd').charAt(0).toUpperCase() + dayjs(date).format('dddd').slice(1);
            case ViewType.Month:
                return `Tháng ${dayjs(date).month() + 1}`;
        }
    }
};