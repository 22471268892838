
import { env } from '@env';
import { HttpRequestData, PaginationResponse, httpUtil } from '@/framework';

export interface GetAllAccountRequest extends HttpRequestData {
}

export interface GetAllAccountResponse {
    readonly id?: string;
    readonly email?: string;
    readonly name?: string;

}

export const getAllAccounts = httpUtil.createHttpRequestMeta<
    GetAllAccountRequest,
    PaginationResponse<GetAllAccountResponse>
>({
    baseUrl: env.orgServer,
    path: '/accounts',
    method: 'GET',
    authentication: 'bearer'
});
