
import { MutationOptions, useMutation } from '@tanstack/react-query';
import { HttpClient, HttpRequestError, HttpRequestMeta, viewRequests } from '../framework';
import { useLoginStore } from './useExistingLogin';
import { useLogout } from './useLogout';

export const useHttpCommand = <TRequest, TResponse>(requestMeta: HttpRequestMeta<TRequest, TResponse>, options?: MutationOptions<TResponse, unknown, TRequest>) => {
    const logout = useLogout();
    const loginInfo = useLoginStore();

    const query = useMutation<TResponse, Error, TRequest>({
        mutationKey: [requestMeta.method, requestMeta.baseUrl, requestMeta.path],
        mutationFn: async <TRequest>(requestData: TRequest) => {
            const httpClient = new HttpClient();

            if (requestMeta.authentication === 'bearer') {

                const accessToken = loginInfo.getData()?.accessToken;
                if (!accessToken) {
                    throw new Error('Access token is null');
                }

                httpClient.setAuthorization('bearer', accessToken);
            }

            try {
                return await httpClient.send<TResponse>({
                    ...requestMeta,
                    ...requestData,
                });
            } catch (error) {
                if (error instanceof Error && error.name === 'HttpRequestError') {
                    const requestError = error as HttpRequestError;

                    if (requestError.statusCode === 401) {
                        logout();
                        location.href = '/error/unauthorized';
                    }

                    throw error;
                }

                viewRequests.showNotification.send({
                    type: 'error',
                    title: (error as Error).name,
                    message: (error as Error).message,
                });

                throw error;
            }
        },
        onSuccess: options?.onSuccess,
        onError: options?.onError,
    });

    return query;
};
