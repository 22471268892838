import './ColumnActions.scss';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import { IconEye, IconPen, IconTrash, } from '../../icons';
import { Block, FlexBox } from '../../layouts';
import { LinkOutlined } from '@ant-design/icons';

interface ColumnActionsProps {
    viewDetailUrl?: string;
    onClickEdit?: () => void;
    onClickDelete?: () => void;
    onChangeStatus?: () => void;
    onRedirect?: () => void;
    status?: string;
}

export const ColumnActions = (props: ColumnActionsProps) => {
    const { viewDetailUrl, onClickEdit, onClickDelete, onChangeStatus, onRedirect } = props;
    return (
        <Block id='column-actions'>
            <FlexBox alignItems='center' gap={18}>
                {viewDetailUrl && (
                    <Tooltip placement='top' title="View">
                        <Link to={viewDetailUrl} >
                            <IconEye
                                className='icon-view'
                            />
                        </Link>
                    </Tooltip>
                )}
                {onRedirect && (
                    <Tooltip
                        placement='top'
                        title="Đến salon"
                    >
                        <span>
                            <LinkOutlined
                                style={{ fontSize: 20 }}
                                onClick={onRedirect}
                            />
                        </span>
                    </Tooltip>
                )}
                {onClickEdit && (
                    <Tooltip placement='top' title="Edit">
                        <span>
                            <IconPen
                                onClick={onClickEdit}
                                className='icon-edit'
                            />
                        </span>
                    </Tooltip>
                )}
                {onClickDelete && (
                    <Tooltip
                        placement='top'
                        title="Delete"
                    >
                        <span>
                            <IconTrash
                                onClick={onClickDelete}
                                className='icon-delete'
                            />
                        </span>
                    </Tooltip>
                )}
                {onChangeStatus && (
                    <Tooltip
                        placement='top'
                        title="Edit"
                    >
                        <span style={{ color: '#0B5DCC' }} onClick={onChangeStatus}>
                            {props.status}
                        </span>
                    </Tooltip>
                )}
            </FlexBox>
        </Block>
    );
};
