import { Mediator } from '../mediator';

export interface ShowNotificationRequest {
    readonly type: 'success' | 'error';
    readonly title?: string;
    readonly message: string;
    readonly duration?: number;
}

export const viewRequests = {
    showNotification: new Mediator<ShowNotificationRequest, void>(),
    renderApplication: new Mediator<void, void>(),
};