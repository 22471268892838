import React from 'react';
import { ConfigProvider, DatePicker } from 'antd';
import { FlexBox } from '../layouts';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/vi';
import locale from 'antd/es/locale/vi_VN';
import './DatePickerRange.scss'
import { dateHelper, rangePresets } from '@/helpers';

const { RangePicker } = DatePicker;

interface DatePickerRangeProps {
    onChange: (dates: [Dayjs, Dayjs]) => void;
    defaultValue?: [Dayjs, Dayjs];
}

export const DatePickerRange = (props: DatePickerRangeProps) => {
    const { onChange, defaultValue } = props;
    dayjs.locale('vi')
    const [value, setValue] = React.useState<[Dayjs, Dayjs]>(defaultValue ?? [dayjs(), dayjs()]);

    const presetLabel = dateHelper.getLabelPresets(value);

    return (
        <FlexBox className='date-picker-range'>
            <ConfigProvider locale={locale}>
                {presetLabel && 
                    <span className='date-picker-range__label_preset'>
                        {presetLabel}
                    </span>
                }
                <RangePicker
                    presets={rangePresets}
                    placement="bottomLeft"
                    width={400}
                    value={value}
                    onChange={(dates) => {
                        const [start, end] = dates as [Dayjs, Dayjs];
                        setValue([start.startOf('day'), end.endOf('day')]);
                        onChange([start.startOf('day'), end.endOf('day')]);
                    }}
                    format="DD/MM/YYYY"
                />
            </ConfigProvider>
        </FlexBox>
    );
};
