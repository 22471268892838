import { FormBase } from "@components/base";
import { Block, FlexBox, Spacer } from "@components/layouts";
import { Header } from "@components/typo";
import ReportRevenueOverview from "./ReportRevenueOverview";
import dayjs from "dayjs";
import ReportRevenueChart from "./ReportRevenueChart";
import ReportRevenueTabs from "./ReportRevenueTabs";
import { useMemo } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { getRevenueOverview, GetRevenueOverviewResponse } from "@/api/report/revenue";
import { TypeRevenueOverview } from "@/shared/enum";
import { salonActiveState, salonsState } from "@/components/state";
import { useRecoilState } from "recoil";
import { useHttpQueries } from "@/hooks";
import { chartHelper } from "@/helpers";

 export const getTotalRevenue = (revenueOverview: UseQueryResult<GetRevenueOverviewResponse, Error>[], objectType: string) => {
    return revenueOverview?.reduce((acc, cur) => {
        if( cur.data?.objectType === objectType){
            return {
                totalRevenue: acc.totalRevenue + (cur?.data?.totalRevenue ??0 ),
                totalInvoice: acc.totalInvoice + (cur?.data?.totalInvoice ??0 )
            }
        }

        return acc;
    }, {totalRevenue: 0, totalInvoice: 0});
}

interface RevenueReportProps {
    dateRange: [dayjs.Dayjs, dayjs.Dayjs];
}


export function RevenueReport(props: RevenueReportProps) {
    const { dateRange } = props;
    const [salonActive] = useRecoilState(salonActiveState);
    const [salons] = useRecoilState(salonsState);

    const viewType = chartHelper.getViewTypeChart(dateRange);

    const allqueries = useMemo(() => {
        const objectTypes = [
            TypeRevenueOverview.All, TypeRevenueOverview.Service, TypeRevenueOverview.Product, TypeRevenueOverview.Package,
            TypeRevenueOverview.CashCard, TypeRevenueOverview.Debit, TypeRevenueOverview.Discount, TypeRevenueOverview.Booking, 
        ];

        if(salonActive){
            const queriesOverviewSalonActive = salonActive.salonBranches?.map((branch) => {
                return objectTypes.map((type) => {
                    return {
                        requestMeta: getRevenueOverview,
                        requestData: {
                            query: {
                                startDate: dateRange[0].toISOString(),
                                endDate: dateRange[1].toISOString(),
                                typeGetOverview: type,
                                salonId: salonActive.id,
                                salonBranchId: branch.id
                            }
                        }
                    }
                });
            }).flat() ?? [];

            return queriesOverviewSalonActive;
        }

        const queriesOverviewAllSalons = salons?.map((salon) => {
            return objectTypes.map((type) => {
                return {
                    requestMeta: getRevenueOverview,
                    requestData: {
                        query: {
                            startDate: dateRange[0].toISOString(),
                            endDate: dateRange[1].toISOString(),
                            typeGetOverview: type,
                            salonId: salon.id
                        }
                    }
                }
            });
        }).flat() ?? [];

        return queriesOverviewAllSalons;
    },[salonActive, salons, dateRange]);

    const revenueOverview =  useHttpQueries(allqueries);

    return (
        <FormBase onSubmit={() => { }}>
            <Block backgroundColor='white' className="tab-content">
                <FlexBox direction='column'>
                    <Spacer bottom={16}>
                        <Header level={3}>
                            Báo cáo doanh thu
                        </Header>
                    </Spacer>
                    <ReportRevenueOverview dateRange={dateRange} revenueOverview={revenueOverview}/>
                    {typeof viewType !== 'undefined' && <ReportRevenueChart dateRange={dateRange}/>}
                    <ReportRevenueTabs dateRange={dateRange} revenueOverview={revenueOverview} />
                </FlexBox>
            </Block>
        </FormBase>
    )
}
