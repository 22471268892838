import { HttpClient, HttpRequestData, HttpRequestError, HttpRequestMeta, viewRequests } from '../framework';
import { useQueries, UseQueryOptions } from '@tanstack/react-query';
import { useLogout } from './useLogout';
import { useLoginStore } from './useExistingLogin';

export interface UseHttpQueryOptions {
    enabled?: boolean;
    refetchInterval?: number | false;
}

export interface HttpQueries<TRequest, TResponse> {
    requestMeta: HttpRequestMeta<TRequest, TResponse>,
    requestData?: HttpRequestData,
    options?: UseHttpQueryOptions
}

export const useHttpQueries = <TRequest, TResponse>(queries: HttpQueries<TRequest, TResponse>[]) => {
    const logout = useLogout();
    const loginInfo = useLoginStore();

    const queryFns = queries.map(({ requestMeta, requestData, options }) => {
        const queryOptions: UseQueryOptions<TResponse, Error> = {
            ...options,
            queryKey: [requestMeta.method, requestMeta.baseUrl, requestMeta.path, requestData?.query, requestData?.pathData],
            queryFn: async () => {
                const httpClient = new HttpClient();

                if (requestMeta.authentication === 'bearer') {
                    const accessToken = loginInfo.getData()?.accessToken;
                    if (!accessToken) {
                        throw new Error('Access token is null');
                    }

                    httpClient.setAuthorization('bearer', accessToken);
                }

                if (requestMeta.authentication === 'basic') {
                    const accessKey = requestData?.header?.basic;
                    if (!accessKey) {
                        throw new Error('Access key is null');
                    }

                    httpClient.setAuthorization('basic', accessKey);
                }

                try {
                    return await httpClient.send<TResponse>({
                        ...requestMeta,
                        ...requestData,
                    });
                } catch (error) {
                    if (error instanceof Error && error.name === 'HttpRequestError') {
                        const requestError = error as HttpRequestError;

                        if (requestError.statusCode === 401) {
                            logout();
                            throw new Error('Unauthorized');
                        }

                        throw error;
                    }

                    viewRequests.showNotification.send({
                        type: 'error',
                        title: (error as Error).name,
                        message: (error as Error).message,
                    });

                    throw error;
                }
            },
        };

        return queryOptions;
    });

    return useQueries({ queries: queryFns });
};
