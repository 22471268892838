
import { Dayjs } from 'dayjs';
import { Salon } from '@components/state';
import { env } from '@env';
import { HttpRequestData, httpUtil } from '@/framework';
import { HttpQueries } from '@hooks/useHttpQueries';

export interface GetCustomerReturnOverviewRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: number;
        readonly salonBranchId: number;
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface GetCustomerReturnOverviewResponse {
    readonly totalCustomerReturn?: number;
    readonly salonBranchId?: number;
    readonly salonId?: number;
}

export const requestCustomerReturnOverviewByAll = (
    salons: Salon[] | undefined,
    dateRange: Dayjs[],
    viewType: string
): HttpQueries<GetCustomerReturnOverviewRequest, GetCustomerReturnOverviewResponse>[] => {
    const requestsData: HttpQueries<GetCustomerReturnOverviewRequest, GetCustomerReturnOverviewResponse>[] = salons!.flatMap(salon =>
        salon!.salonBranches!.map(salonBranch => ({
            requestMeta: getCustomerReturnOverview,
            requestData: {
                query: {
                    salonId: salon.id,
                    salonBranchId: salonBranch.id,
                    startDate: dateRange[0].toISOString(),
                    endDate: dateRange[1].toISOString(),
                    viewType: viewType,
                },
            },
            options: {
                enabled: true,
            },
        }))
    );
    return requestsData;
};

export const requestCustomerReturnOverviewBySalon = (
    dateRange: Dayjs[],
    viewType: string,
    salonSelect?: Salon
): HttpQueries<GetCustomerReturnOverviewRequest, GetCustomerReturnOverviewResponse>[] => {
    const requestsData: HttpQueries<GetCustomerReturnOverviewRequest, GetCustomerReturnOverviewResponse>[] = salonSelect!.salonBranches!.map(salonBranch => ({
        requestMeta: getCustomerReturnOverview,
        requestData: {
            query: {
                salonId: salonSelect?.id,
                salonBranchId: salonBranch.id,
                startDate: dateRange[0].toISOString(),
                endDate: dateRange[1].toISOString(),
                viewType: viewType,
            },
        },
        options: {
            enabled: true,
        },
    }));
    return requestsData;
};
export const requestCustomerReturnOverviewBySalonBranch = (
    dateRange: Dayjs[],
    viewType: string,
    salonSelect: Salon,
    selectedSalonBranchId: number
): HttpQueries<GetCustomerReturnOverviewRequest, GetCustomerReturnOverviewResponse>[] => {
    const requestsData: HttpQueries<GetCustomerReturnOverviewRequest, GetCustomerReturnOverviewResponse>[] = [{
        requestMeta: getCustomerReturnOverview,
        requestData: {
            query: {
                salonId: salonSelect?.id,
                salonBranchId: selectedSalonBranchId,
                startDate: dateRange[0].toISOString(),
                endDate: dateRange[1].toISOString(),
                viewType: viewType,
            },
        },
        options: {
            enabled: true,
        },
    }]
    return requestsData;
};

export const getCustomerReturnOverview = httpUtil.createHttpRequestMeta<
    GetCustomerReturnOverviewRequest,
    GetCustomerReturnOverviewResponse
>({
    baseUrl: env.orgServer,
    path: '/customers/return-overview',
    method: 'GET',
    authentication: 'bearer'
});
