import './index.css';
import ReactDOM from 'react-dom/client';
import { Router } from './page';
import { ThemeProvider } from './components/layouts/ThemeProvider';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ModalGlobal } from './components/modal';
// import { Initializer } from './components/app';
// import { ModalGlobal } from './components/modal';
// import { Initializer } from './components/app';

const getRootElement = () => document.getElementById('app')!;
let reactRoot: ReactDOM.Root | null = null;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    },
  },
});

const renderApp = () => {
  const root = getRootElement();
  const app = (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <ModalGlobal />
          <Router />
        </ThemeProvider>
      </QueryClientProvider>
    </RecoilRoot>
  );

  if (reactRoot == null) {
    reactRoot = ReactDOM.createRoot(root);
    reactRoot.render(app);
  } else {
    reactRoot.render(app);
  }
};

export const startApp = () => {

  renderApp();
};
