import { TableColumnsType } from "antd";
import { TableCustom } from "@components/table";
import { salonActiveState } from "@/components/state";
import { useRecoilState } from "recoil";

export interface OverviewRevenueDetailData {
    readonly id?: string;
    readonly title?: string;
    readonly value?: number;
}

interface OverviewRevenueDetailTableProps {
    readonly dataTable: OverviewRevenueDetailData[];
}

export function OverviewRevenueDetailTable(props: OverviewRevenueDetailTableProps) {
    const [salonActive] = useRecoilState(salonActiveState);

    const columns: TableColumnsType<OverviewRevenueDetailData> = [
        {
            title: salonActive ? 'Chi nhánh' : 'Salon',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Giá trị',
            dataIndex: 'value',
            key: 'value',
            width: 150,
            render: (value) => {
                return value.toLocaleString('en-US');
            }
        }
    ];

    return (
        <div style={{ paddingTop: 20 }}>
            <TableCustom
                showHeader={false}
                columns={columns}
                dataSource={props.dataTable}
                showFooter={false}
            />
        </div>
    )
}
