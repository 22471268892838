import { Tabs } from 'antd';
import { TabsProps } from 'antd/lib';
import { Dayjs } from 'dayjs';
import { FlexBox, Spacer } from '@components/layouts';
import { OverviewAppointmentTable } from './table/OverviewAppointmentTable';
import { StatusAppointmentTable } from './table/StatusAppointmentTable';
import { ServiceByAppointmentTable } from './table/ServiceByAppointmentTable';
import { ServiceByStaffAppointmentTable } from './table/ServiceByStaffAppointmentTable';
import { ServiceCategoryByAppointmentTable } from './table/ServiceCategoryByAppointmentTable';

interface AppointmentReportTabsProps {
    readonly dateRange: [Dayjs, Dayjs];
}

export default function AppointmentReportTabs(props: AppointmentReportTabsProps) {
    const { dateRange } = props;
    console.log(dateRange)

    const revenueProductTabsContent = (
        <FlexBox gap={24} direction='column'>
            <StatusAppointmentTable />
        </FlexBox>
    );
    const revenueServiceTabsContent = (
        <FlexBox gap={24} direction='column'>
            <ServiceByAppointmentTable />
            <ServiceByStaffAppointmentTable />
            <ServiceCategoryByAppointmentTable />
        </FlexBox>
    );

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: "Tổng quan",
            children: <OverviewAppointmentTable />,
        },
        {
            key: '2',
            label: 'Trạng thái',
            children: revenueProductTabsContent,
        },
        {
            key: '3',
            label: 'Dịch vụ',
            children: revenueServiceTabsContent,
        },
    ];

    return (
        <Spacer top={24} >
            <Tabs defaultActiveKey='1' items={items} />
        </Spacer>
    );
}
