
export const ImportIconWareHouse = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z" fill="#E6F7FF" />
            <path d="M20.4223 32.6181C22.1779 33.5395 23.0557 34.0001 24 34.0001V24.0001L14.638 19.0728C14.6242 19.0949 14.6107 19.1173 14.5974 19.1399C14 20.1544 14 21.4168 14 23.9416V24.0586C14 26.5834 14 27.8459 14.5974 28.8604C15.1948 29.8749 16.2706 30.4395 18.4223 31.5686L20.4223 32.6181Z" fill="#0068FF" />
            <path opacity="0.4" d="M29.5774 16.4315L27.5774 15.382C25.8218 14.4607 24.944 14 23.9997 14C23.0554 14 22.1776 14.4607 20.422 15.382L18.422 16.4315C16.3182 17.5355 15.2429 18.0998 14.6377 19.0726L23.9997 24L33.3617 19.0726C32.7564 18.0998 31.6811 17.5355 29.5774 16.4315Z" fill="#0068FF" />
            <path opacity="0.4" d="M33.4026 19.1399C33.3893 19.1173 33.3758 19.0949 33.362 19.0728L24 24.0001V34.0001C24.9443 34.0001 25.8221 33.5395 27.5777 32.6181L29.5777 31.5686C31.7294 30.4395 32.8052 29.8749 33.4026 28.8604C34 27.8459 34 26.5834 34 24.0586V23.9416C34 21.4168 34 20.1544 33.4026 19.1399Z" fill="#0068FF" />
            <path d="M18.3233 16.4838C18.3562 16.4666 18.3893 16.4492 18.4226 16.4317L19.9161 15.6479L29.0169 20.6534L33.0406 18.6415C33.1783 18.7974 33.298 18.9618 33.4029 19.1399C33.5525 19.394 33.6646 19.6635 33.7487 19.9645L29.7503 21.9637V25.0002C29.7503 25.4144 29.4145 25.7502 29.0003 25.7502C28.5861 25.7502 28.2503 25.4144 28.2503 25.0002V22.7137L24.7503 24.4637V33.9042C24.4934 33.9682 24.2492 34.0002 24.0003 34.0002C23.7515 34.0002 23.5072 33.9682 23.2503 33.9042V24.4637L14.252 19.9645C14.336 19.6635 14.4481 19.394 14.5977 19.1399C14.7026 18.9618 14.8223 18.7974 14.96 18.6415L24.0003 23.1617L27.3865 21.4686L18.3233 16.4838Z" fill="#0068FF" />
        </svg>
    );
};

