import { HttpRequestOptions, IHttpClient } from '../types';
import { httpUtil } from '../utils';
import { HttpRequestError } from './HttpRequestError';

interface HttpError {
    error: string;
    error_description: string;
}

export class HttpClient implements IHttpClient {
    private _basicCredential: string | null = null;
    private _accessToken: string | null = null;

    public setAuthorization = (
        type: 'bearer' | 'basic',
        credential: string
    ) => {
        if (type === 'bearer') {
            this._accessToken = credential;
            return;
        } else if (type === 'basic') {
            this._basicCredential = credential;
            return;
        }

        throw new Error('Invalid authorization type');
    };

    public send = async <TResponse>(options: HttpRequestOptions) => {
        const headers = new Headers();

        if (options.authentication == 'bearer') {
            headers.append('Authorization', `Bearer ${this._accessToken}`);
        } else if (options.authentication == 'basic') {
            headers.append('Authorization', `Basic ${this._basicCredential}`);
        }

        let body: BodyInit | null = null;

        if (options.contentType?.startsWith('application/x-www-form-urlencoded')) {
            headers.append('Content-Type', options.contentType);
            body = httpUtil.jsonToUrlEncoded(options.body as Record<string, unknown>);
        }
        else if (options.contentType?.startsWith('multipart/form-data')) {
            headers.append('Content-Type', options.contentType);
            body = httpUtil.jsonToFormData(options.body as Record<string, unknown>);
        }
        else {
            if (options.body) {
                if (typeof options.body === 'string') {
                    body = options.body;
                }
                else if (typeof options.body === 'object') {
                    headers.append('Content-Type', 'application/json;charset=UTF-8');
                    body = JSON.stringify(options.body);
                }
            }
        }

        if (options.header) {
            Object.entries(options.header).forEach(([key, value]) => {
                headers.append(key, value);
            });
        }

        const fetchInit: RequestInit = {
            method: options.method,
            headers,
            body,
            redirect: 'follow'
        };

        const path = httpUtil.parsePath(options.path, options.pathData);
        const query = httpUtil.parseQuery(options.query);

        const url = new URL(`${options.baseUrl}${path}${query}`);

        const response = await fetch(url, fetchInit);

        const isRedirect = response.redirected;
        if (isRedirect) {
            window.location.href = response.url;
            return {} as TResponse;
        }

        const responseType = response.headers.get('content-type');

        const isJson = responseType?.includes('application/json');
        let data = {} as TResponse;
        if (isJson) {
            data = await response.json() as TResponse;
        }

        const isText = responseType?.includes('text/plain');
        if (isText) {
            data = await response.text() as TResponse;
        }

        if (!response.ok) {
            const errorResponse = data as HttpError;
            const error = new Error();

            if (errorResponse.error) {
                error.name = errorResponse.error;
            }

            if (errorResponse.error_description) {
                error.message = errorResponse.error_description;
            }

            throw new HttpRequestError(error.message, response);
        }

        return data as TResponse;
    };
}
