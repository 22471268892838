import { TableColumnsType, Tag } from "antd";
import { TableCustom } from "@components/table";
import { globalModalState, salonActiveState } from "@components/state";
import { useRecoilState } from "recoil";
import { useHttpQuery, useSearchParams } from "@/hooks";
import { getRevenueOfCategoryTable, GetRevenueOfCategoryTableResponse } from "@/api/report/revenue";
import { Dayjs } from "dayjs";
import { RevenueByTypeCategoryDetailTable } from "./table-details/RevenueByTypeCategoryDetailTable";
import { useCallback, useMemo } from "react";

interface RevenueByTypeCategoryTableProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly objectType: "SERVICE" | "PRODUCT";
}

export function RevenueByTypeCategoryTable(props: RevenueByTypeCategoryTableProps) {
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const [salonActive] = useRecoilState(salonActiveState);
    const [searchParams, setSearchParams] = useSearchParams({
        categorySearch: '',
        categoryPage: '1',
        categoryPageSize: '20'
    }, ['categorySearch', 'categoryPage', 'categoryPageSize']);


    const showDetail = useCallback((title?: string, categoryId?: number) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: title,
            width: 850,
            content: (
                <RevenueByTypeCategoryDetailTable 
                    dateRange={props.dateRange} 
                    categoryId={categoryId!} 
                    objectType={props.objectType}
                />
            )
        });
    }, [globalModal, props.dateRange, props.objectType, setGlobalModal]);

    const {data : revenueOfCategoryTable,} = useHttpQuery(getRevenueOfCategoryTable, {
        query: {
            startDate: props.dateRange[0].toISOString(),
            endDate: props.dateRange[1].toISOString(),
            objectType: props.objectType,
            salonId: salonActive?.id ?? 0,
            search: searchParams.categorySearch,
            page: searchParams.categoryPage,
            pageSize: searchParams.categoryPageSize
        }
    });

    const columns: TableColumnsType<GetRevenueOfCategoryTableResponse> = useMemo(()=> [
        {
            title: 'Tên danh mục',
            dataIndex: 'categoryName',
            key: 'name',
        },
        {
            title: 'Số lượng',
            dataIndex: 'totalQuantity',
            key: 'quantity',
            width: 150
        },
        {
            title: 'Doanh thu',
            dataIndex: 'totalRevenue',
            key: 'revenue',
            width: 150
        },
        {
            title: "Liên kết",
            width: 150,
            render: (_, record) => {
                return (
                    <a>
                        <Tag onClick={() => { showDetail(record.categoryName, record.categoryId) }} style={{ borderRadius: 2 }} color="default">Xem chi tiết</Tag>
                    </a>
                );
            },
        },
    ], [showDetail]);

    const objectTypeTransCode = props.objectType === 'SERVICE' ? 'dịch vụ' : 'sản phẩm';

    return (
        <TableCustom
            scroll={{ x: 800 }}
            placeholderSearchTransCode='Tìm danh mục'
            onSearch={(value) => {
                setSearchParams({
                    ...searchParams,
                    categorySearch: value
                });
            }}
            onPageChange={(nextPage) => {
                setSearchParams({
                    ...searchParams,
                    categoryPage: nextPage.toString()
                });
            }}
            pageInfo={revenueOfCategoryTable?.meta}
            titleTableTransCode={`Doanh thu theo danh mục ${objectTypeTransCode}`}
            showHeader={true}
            columns={columns}
            dataSource={revenueOfCategoryTable?.items}
        />
    )
}
