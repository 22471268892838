import { TableColumnsType } from "antd";
import { TableCustom } from "@components/table";
import { Dayjs } from "dayjs";
import { getRevenueOfPaymentMethodDetail, GetRevenueOfPaymentMethodDetailResponse } from "@/api/report/revenue";
import { useHttpQuery } from "@/hooks";
import { useRecoilState } from "recoil";
import { salonActiveState } from "@/components/state";

export interface RevenueByPaymentMethodDetailData {
    readonly id?: string;
    readonly name?: string;
    readonly value?: string;
    readonly quantity?: string;
}

interface RevenueByPaymentMethodDetailTableProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly paymentMethodId: number;
}

export function RevenueByPaymentMethodDetailTable(props: RevenueByPaymentMethodDetailTableProps) {
    const [salonActive] = useRecoilState(salonActiveState);

    const {data : revenueOfObjectTypeDetail} = useHttpQuery(getRevenueOfPaymentMethodDetail, {
        query: {
            startDate: props.dateRange[0].toISOString(),
            endDate: props.dateRange[1].toISOString(),
            paymentMethodId: props.paymentMethodId,
            salonId: salonActive?.id ?? 0
        }
    });

    const columns: TableColumnsType<GetRevenueOfPaymentMethodDetailResponse> = [
        {
            key: 'salonBranchId',
            title: 'Chi nhánh',
            dataIndex: 'salonBranchId',
            render:(salonBranchId: number)=> {
                return salonActive?.salonBranches?.find(branch => branch.id === salonBranchId)?.name;
            }
        },
        {
            title: 'Số lượng',
            key: 'totalQuantity',
            dataIndex: 'totalQuantity',
            width: 250
        },
        {
            key: 'totalRevenue',
            title: 'Doanh thu',
            dataIndex: 'totalRevenue',
            width: 150
        }
    ];

    return (
        <div style={{ paddingTop: 20 }}>
            <TableCustom
                showHeader={false}
                columns={columns}
                dataSource={revenueOfObjectTypeDetail?.items}
                showFooter={false}
            />
        </div>
    )
}
