
import { env } from '@env';
import { HttpRequestData, httpUtil } from '@/framework';
import { ViewType } from '@/shared/enum/chartEnum';

export interface GetRevenueChartSalonBranchRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: string;
        readonly SalonBrachId: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly viewType?: ViewType;
    }
}
export interface GetRevenueChartSalonBranchResponseItem {
    readonly date: string;
    readonly totalRevenue: number;
    readonly totalInvoice: number;
    readonly salonId: number;
    readonly salonBranchId: number;
}

export const getRevenueChartSalonBranch = httpUtil.createHttpRequestMeta<
    GetRevenueChartSalonBranchRequest,
    GetRevenueChartSalonBranchResponseItem[]
>({
    baseUrl: env.orgServer,
    path: '/revenues/chart-overview/salon-branch',
    method: 'GET',
    authentication: 'bearer'
});
