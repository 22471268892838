import { Dayjs } from 'dayjs';
import { Salon } from '@components/state/accountInfoState';
import { env } from '@env';
import { HttpRequestData, httpUtil } from '@/framework';
import { ViewType } from '@shared/enum/chartEnum';
import { HttpQueries } from '@hooks/useHttpQueries';

export interface GetTotalCustomerNewChartRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: string;
        readonly salonBranchId: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly viewType?: ViewType;
    }
}


export interface GetTotalCustomerNewChartResponse {
    overviewChartInfos: TotalCustomerNewChart[];
}
export interface TotalCustomerNewChart {
    date: Dayjs;
    customerCount: number;
    salonBranchId: number;
    salonId: number;
}

export const requestCustomerNewChartByAll = (
    salons: Salon[] | undefined,
    dateRange: Dayjs[],
    viewType?: ViewType,
) => {
    const requestsData: HttpQueries<GetTotalCustomerNewChartRequest, GetTotalCustomerNewChartResponse>[] = salons!.flatMap(salon =>
        salon!.salonBranches!.map(salonBranch => ({
            requestMeta: getCustomerNewChart,
            requestData: {
                query: {
                    salonId: salon.id,
                    salonBranchId: salonBranch.id,
                    startDate: dateRange[0].toISOString(),
                    endDate: dateRange[1].toISOString(),
                    viewType: viewType,
                },
            },
            options: {
                enabled: true,
            },
        }))
    );
    return requestsData;
};
export const requestCustomerNewChartBySalon = (
    dateRange: Dayjs[],
    viewType?: ViewType,
    salonSelect?: Salon
) => {
    const requestsData: HttpQueries<GetTotalCustomerNewChartRequest, GetTotalCustomerNewChartResponse>[] = salonSelect!.salonBranches!.map(salonBranch => ({
        requestMeta: getCustomerNewChart,
        requestData: {
            query: {
                salonId: salonSelect?.id,
                salonBranchId: salonBranch.id,
                startDate: dateRange[0].toISOString(),
                endDate: dateRange[1].toISOString(),
                viewType: viewType,
            },
        },
        options: {
            enabled: true,
        },
    }));

    return requestsData;
};
export const requestCustomerNewChartBySalonBranch = (
    dateRange: Dayjs[],
    viewType?: ViewType,
    salonSelect?: Salon,
    selectedSalonBranchId?: number
) => {
    const requestsData: HttpQueries<GetTotalCustomerNewChartRequest, GetTotalCustomerNewChartResponse>[] = [{
        requestMeta: getCustomerNewChart,
        requestData: {
            query: {
                salonId: salonSelect?.id,
                salonBranchId: selectedSalonBranchId,
                startDate: dateRange[0].toISOString(),
                endDate: dateRange[1].toISOString(),
                viewType: viewType,
            },
        },
        options: {
            enabled: true,
        },
    }]
    return requestsData;
};

export const getCustomerNewChart = httpUtil.createHttpRequestMeta<
    GetTotalCustomerNewChartRequest,
    GetTotalCustomerNewChartResponse
>({
    baseUrl: env.orgServer,
    path: '/customers/new-chart',
    method: 'GET',
    authentication: 'bearer'
});
