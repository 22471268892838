import React from 'react';
import { LoginState } from '../components/state/loginState';

export function useLoginStore() {
    const getData = React.useCallback((): LoginState | null => {
        const accessToken = sessionStorage.getItem('accessToken') ?? localStorage.getItem('accessToken') ?? '';
        if (!accessToken) return null;

        return {
            accessToken: sessionStorage.getItem('accessToken') ?? localStorage.getItem('accessToken') ?? '',
            refreshToken: sessionStorage.getItem('refreshToken') ?? localStorage.getItem('refreshToken') ?? ''
        };
    }, []);

    const setData = React.useCallback((loginInfo: LoginState) => {
        sessionStorage.setItem('accessToken', loginInfo.accessToken ?? '');
        sessionStorage.setItem('refreshToken', loginInfo.refreshToken ?? '');
    }, []);

    return {
        getData,
        setData
    };
}