import { Dayjs } from 'dayjs';
import ReportCustomerSourceBarChart from './ReportCustomerSourceBarChart';
import ReportCustomerGroupBarChart from './ReportCustomerGroupBarChart';
import ReportCustomerGenderPieChart from './ReportCustomerGenderPieChart';
import { Block, FlexBox } from '@components/layouts';
import ReportCustomerRankBarChart from './ReportCustomerRankBarChart';
import { Salon, salonActiveState } from '@components/state/accountInfoState';
import { useRecoilState } from 'recoil';

interface ReportCustomerSubChartProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly salons?: Salon[] | undefined;
    readonly selectedSalonBranchId?: number | undefined;
}

export default function ReportCustomerSubChart(props: ReportCustomerSubChartProps) {
    const [salonActive] = useRecoilState(salonActiveState);

    return (
        <Block overflow='hidden' maxWidth='100%'>
            <FlexBox direction='column' gap={20}>
                <FlexBox justifyContent='space-between' gap={20} >
                    <ReportCustomerSourceBarChart salon={salonActive!} selectedSalonBranchId={props.selectedSalonBranchId} dateRange={props.dateRange} salons={props?.salons} />
                    <ReportCustomerGenderPieChart salon={salonActive!} selectedSalonBranchId={props.selectedSalonBranchId} dateRange={props.dateRange} salons={props?.salons} />

                </FlexBox>
                <FlexBox justifyContent='space-between' gap={20}>
                    <ReportCustomerGroupBarChart salon={salonActive!} selectedSalonBranchId={props.selectedSalonBranchId} dateRange={props.dateRange} salons={props?.salons} />
                    <ReportCustomerRankBarChart salon={salonActive!} selectedSalonBranchId={props.selectedSalonBranchId} dateRange={props.dateRange} salons={props?.salons} />
                </FlexBox>
            </FlexBox>
        </Block>
    );
}
