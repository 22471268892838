import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Auth } from './auth';
import { ProtectedRoute } from "../components/app/ProtectedRoute";
import { MainLayout } from '../layouts';
import Authorization from './authorization/Authorization';
import Account from './account/Account';
import Salon from './salon/Salon';
import Report from './report/Reports';

export const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/auth/*' Component={Auth} />
                <Route path='/' element={<ProtectedRoute />}>
                    <Route path='/' Component={MainLayout} >
                        <Route path='/' element={<Navigate to={'/report/*'} />} />
                        <Route path='/account/*' Component={Account} />
                        <Route path='/report/*' Component={Report} />
                        <Route path='/salon/*' Component={Salon} />
                        <Route path='/authorization/*' Component={Authorization} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};