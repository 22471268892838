import { TableColumnsType, Tag } from "antd";
import { TableCustom } from "@components/table";
import { globalModalState } from "@components/state";
import { useRecoilState } from "recoil";
import { ServiceCategoryByAppointmentDetailTable } from "./ServiceCategoryByAppointmentDetailTable";

export interface ServiceCategoryByAppointmentData {
    readonly id?: string;
    readonly name?: string;
    readonly value?: string;
    readonly quantity?: string;

}

export function ServiceCategoryByAppointmentTable() {

    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    // const resetGlobalModal = useResetRecoilState(globalModalState);

    const showDetail = (title: string) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: title,
            width: 850,
            content: <ServiceCategoryByAppointmentDetailTable />
        });
    };

    const data: ServiceCategoryByAppointmentData[] = [
        {
            id: '1',
            value: "250",
            name: "Service Category 1",
            quantity: "8"
        },
        {
            id: '2',
            value: "615000",
            name: "Service Category 2",
            quantity: "7"
        },
        {
            id: '3',
            value: "432000",
            name: "Service Category 4",
            quantity: "5"
        },
        {
            id: '5',
            value: "45000",
            name: "Service Category 6",
            quantity: "2"
        },
        {
            id: '6',
            value: "345000",
            name: "Service Category 8",
            quantity: "5"
        },
        {
            id: '7',
            value: "550000",
            name: "Service Category 10",
            quantity: "8"
        },
        {
            id: '8',
            value: "4450000",
            name: "Service Category 34",
            quantity: "8"
        },
        {
            id: '9',
            value: "550000",
            name: "Service Category 99",
            quantity: "8"
        },
        {
            id: '10',
            value: "150000",
            name: "Service Category 16",
            quantity: "8"
        },
        {
            id: '11',
            value: "89850000",
            name: "Service Category 34",
            quantity: "8"
        }
    ];

    const columns: TableColumnsType<ServiceCategoryByAppointmentData> = [
        {
            title: 'Tên danh mục',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 250
        },
        {
            title: 'Doanh thu',
            dataIndex: 'value',
            key: 'value',
            width: 150
        },
        {
            title: "Liên kết",
            width: 150,
            render: (_, record) => {
                return (
                    // <ColumnActions
                    //     viewDetailUrl=""
                    //     onClickEdit={() => {
                    //     }}
                    //     onClickDelete={() => {
                    //     }}

                    // />
                    <a>
                        <Tag onClick={() => { showDetail(record.name ?? '') }} style={{ borderRadius: 2 }} color="default">Xem chi tiết</Tag>
                    </a>
                );
            },
        },
    ];

    return (
        <TableCustom
            scroll={{ x: 800 }}
            titleTableTransCode="Theo danh mục dịch vụ"
            onSearch={() => { }}
            placeholderSearchTransCode="Tìm danh mục dịch vụ"
            showHeader={true}
            columns={columns}
            dataSource={data}
        />
    )
}
