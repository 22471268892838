import { Button, ButtonProps } from 'antd';
import React from 'react';

import { Block } from '../layouts';

export interface AppButtonProps extends ButtonProps {
    readonly text?: string;
    readonly width?: React.CSSProperties['width'];
    readonly padding?: React.CSSProperties['padding'];
    readonly textAlign?: React.CSSProperties['textAlign'];
    readonly borderColor?: React.CSSProperties['borderColor'];
    readonly border?: React.CSSProperties['border'];
}

export const AppButton = (props: AppButtonProps) => {
    const { children, text, width, padding, textAlign, borderColor, border, ...rest } = props;

    const style = React.useMemo((): React.CSSProperties => {
        return {
            width,
            padding,
            textAlign,
            borderColor,
            border
        };
    }, [border, borderColor, padding, textAlign, width]);

    return (
        <Button className='app-button' {...rest} style={style}>
            {text ? (
                <Block display='inline-block'>{text}</Block>
            ) : (
                children
            )}
        </Button>
    );
};
