import { Content, Header } from "antd/es/layout/layout";
import { Block } from "@components/layouts";
import { theme } from "antd";
import { Text } from "@components/typo"

function Authorization() {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <Block>
            <Header style={{ padding: 0, background: colorBgContainer, paddingLeft: 20 }} >
                <Text fontWeight={700} fontSize={16}>
                    Quản lý phân quyền
                </Text>
            </Header>
            <Content style={{ margin: '0 16px', padding: 20, overflow: 'initial' }}>
            </Content>
        </Block>
    )
}

export default Authorization