export const CashIcon = () => {
    return (
        <svg width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.8454 15.8957L-5.88811e-05 9.85928L25.5344 2.67661C26.1723 2.49997 26.8542 2.58261 27.4314 2.9065C28.0087 3.2304 28.4346 3.76932 28.6162 4.40582L31.8454 15.8957Z" fill="#ACCEFC" />
            <path d="M32.6029 8.66309H2.60291C1.22219 8.66309 0.102905 9.78237 0.102905 11.1631V29.9131C0.102905 31.2938 1.22219 32.4131 2.60291 32.4131H32.6029C33.9836 32.4131 35.1029 31.2938 35.1029 29.9131V11.1631C35.1029 9.78237 33.9836 8.66309 32.6029 8.66309Z" fill="#3D8CF5" />
            <path d="M30.1029 12.4131H5.10291C4.41255 12.4131 3.85291 12.9727 3.85291 13.6631V27.4131C3.85291 28.1034 4.41255 28.6631 5.10291 28.6631H30.1029C30.7933 28.6631 31.3529 28.1034 31.3529 27.4131V13.6631C31.3529 12.9727 30.7933 12.4131 30.1029 12.4131Z" fill="#ACCEFC" />
            <path d="M18.2279 25.5381H15.1029C14.7714 25.5381 14.4534 25.4064 14.219 25.172C13.9846 24.9375 13.8529 24.6196 13.8529 24.2881C13.8529 23.9566 13.9846 23.6386 14.219 23.4042C14.4534 23.1698 14.7714 23.0381 15.1029 23.0381H18.2279C18.3937 23.0381 18.5526 22.9722 18.6698 22.855C18.7871 22.7378 18.8529 22.5788 18.8529 22.4131C18.8529 22.2473 18.7871 22.0884 18.6698 21.9711C18.5526 21.8539 18.3937 21.7881 18.2279 21.7881H16.9779C16.1491 21.7881 15.3542 21.4588 14.7682 20.8728C14.1821 20.2867 13.8529 19.4919 13.8529 18.6631C13.8529 17.8343 14.1821 17.0394 14.7682 16.4534C15.3542 15.8673 16.1491 15.5381 16.9779 15.5381H20.1029C20.4344 15.5381 20.7524 15.6698 20.9868 15.9042C21.2212 16.1386 21.3529 16.4566 21.3529 16.7881C21.3529 17.1196 21.2212 17.4375 20.9868 17.672C20.7524 17.9064 20.4344 18.0381 20.1029 18.0381H16.9779C16.8121 18.0381 16.6532 18.1039 16.536 18.2211C16.4188 18.3384 16.3529 18.4973 16.3529 18.6631C16.3529 18.8288 16.4188 18.9878 16.536 19.105C16.6532 19.2222 16.8121 19.2881 16.9779 19.2881H18.2279C19.0567 19.2881 19.8516 19.6173 20.4376 20.2034C21.0237 20.7894 21.3529 21.5843 21.3529 22.4131C21.3529 23.2419 21.0237 24.0367 20.4376 24.6228C19.8516 25.2088 19.0567 25.5381 18.2279 25.5381Z" fill="#3D8CF5" />
            <path d="M17.6029 18.0381C17.2714 18.0381 16.9534 17.9064 16.719 17.672C16.4846 17.4375 16.3529 17.1196 16.3529 16.7881V14.9131C16.3529 14.5816 16.4846 14.2636 16.719 14.0292C16.9534 13.7948 17.2714 13.6631 17.6029 13.6631C17.9344 13.6631 18.2524 13.7948 18.4868 14.0292C18.7212 14.2636 18.8529 14.5816 18.8529 14.9131V16.7881C18.8529 17.1196 18.7212 17.4375 18.4868 17.672C18.2524 17.9064 17.9344 18.0381 17.6029 18.0381Z" fill="#3D8CF5" />
            <path d="M17.6029 27.4131C17.2714 27.4131 16.9534 27.2814 16.719 27.047C16.4846 26.8125 16.3529 26.4946 16.3529 26.1631V24.2881C16.3529 23.9566 16.4846 23.6386 16.719 23.4042C16.9534 23.1698 17.2714 23.0381 17.6029 23.0381C17.9344 23.0381 18.2524 23.1698 18.4868 23.4042C18.7212 23.6386 18.8529 23.9566 18.8529 24.2881V26.1631C18.8529 26.4946 18.7212 26.8125 18.4868 27.047C18.2524 27.2814 17.9344 27.4131 17.6029 27.4131Z" fill="#3D8CF5" />
            <path d="M8.85291 21.7881C9.88844 21.7881 10.7279 20.9486 10.7279 19.9131C10.7279 18.8776 9.88844 18.0381 8.85291 18.0381C7.81737 18.0381 6.97791 18.8776 6.97791 19.9131C6.97791 20.9486 7.81737 21.7881 8.85291 21.7881Z" fill="#3D8CF5" />
            <path d="M26.3529 22.4131C27.3884 22.4131 28.2279 21.5736 28.2279 20.5381C28.2279 19.5026 27.3884 18.6631 26.3529 18.6631C25.3174 18.6631 24.4779 19.5026 24.4779 20.5381C24.4779 21.5736 25.3174 22.4131 26.3529 22.4131Z" fill="#3D8CF5" />
        </svg>
    );
}