export const BankTransferIcon = () => {
    return (
        <svg width="31" height="34" viewBox="0 0 31 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.7976 0H3.86905C1.73223 0 0 1.86548 0 4.16667V29.1667C0 31.4679 1.73223 33.3333 3.86905 33.3333H17.7976C19.9344 33.3333 21.6667 31.4679 21.6667 29.1667V4.16667C21.6667 1.86548 19.9344 0 17.7976 0Z" fill="#ACCEFC" />
            <path d="M23.333 24.167C27.4751 24.167 30.833 20.8091 30.833 16.667C30.833 12.5249 27.4751 9.16699 23.333 9.16699C19.1909 9.16699 15.833 12.5249 15.833 16.667C15.833 20.8091 19.1909 24.167 23.333 24.167Z" fill="#3D8CF5" />
            <path d="M23.333 17.5C22.8386 17.5 22.3552 17.3534 21.9441 17.0787C21.533 16.804 21.2125 16.4135 21.0233 15.9567C20.8341 15.4999 20.7846 14.9972 20.881 14.5123C20.9775 14.0273 21.2156 13.5819 21.5652 13.2322C21.9149 12.8826 22.3603 12.6445 22.8453 12.548C23.3302 12.4516 23.8329 12.5011 24.2897 12.6903C24.7465 12.8795 25.137 13.2 25.4117 13.6111C25.6864 14.0222 25.833 14.5055 25.833 15C25.833 15.221 25.7452 15.433 25.5889 15.5893C25.4327 15.7455 25.2207 15.8333 24.9997 15.8333C24.7787 15.8333 24.5667 15.7455 24.4104 15.5893C24.2541 15.433 24.1663 15.221 24.1663 15C24.1663 14.8352 24.1175 14.6741 24.0259 14.537C23.9343 14.4 23.8042 14.2932 23.6519 14.2301C23.4996 14.167 23.3321 14.1505 23.1704 14.1827C23.0088 14.2148 22.8603 14.2942 22.7438 14.4107C22.6272 14.5273 22.5478 14.6758 22.5157 14.8374C22.4835 14.9991 22.5 15.1666 22.5631 15.3189C22.6262 15.4712 22.733 15.6013 22.87 15.6929C23.0071 15.7845 23.1682 15.8333 23.333 15.8333C23.554 15.8333 23.766 15.9211 23.9223 16.0774C24.0785 16.2337 24.1663 16.4457 24.1663 16.6667C24.1663 16.8877 24.0785 17.0996 23.9223 17.2559C23.766 17.4122 23.554 17.5 23.333 17.5Z" fill="white" />
            <path d="M23.333 20.834C22.67 20.834 22.0341 20.5706 21.5652 20.1018C21.0964 19.6329 20.833 18.997 20.833 18.334C20.833 18.113 20.9208 17.901 21.0771 17.7447C21.2334 17.5884 21.4453 17.5007 21.6663 17.5007C21.8874 17.5007 22.0993 17.5884 22.2556 17.7447C22.4119 17.901 22.4997 18.113 22.4997 18.334C22.4997 18.4988 22.5485 18.6599 22.6401 18.797C22.7317 18.934 22.8618 19.0408 23.0141 19.1039C23.1664 19.167 23.3339 19.1835 23.4956 19.1513C23.6572 19.1192 23.8057 19.0398 23.9223 18.9232C24.0388 18.8067 24.1182 18.6582 24.1503 18.4966C24.1825 18.3349 24.166 18.1674 24.1029 18.0151C24.0398 17.8628 23.933 17.7327 23.796 17.6411C23.6589 17.5495 23.4978 17.5007 23.333 17.5007C23.112 17.5007 22.9 17.4129 22.7438 17.2566C22.5875 17.1003 22.4997 16.8883 22.4997 16.6673C22.4997 16.4463 22.5875 16.2343 22.7438 16.0781C22.9 15.9218 23.112 15.834 23.333 15.834C23.996 15.834 24.6319 16.0974 25.1008 16.5662C25.5696 17.0351 25.833 17.6709 25.833 18.334C25.833 18.997 25.5696 19.6329 25.1008 20.1018C24.6319 20.5706 23.996 20.834 23.333 20.834Z" fill="white" />
            <path d="M23.3333 14.167C23.1123 14.167 22.9004 14.0792 22.7441 13.9229C22.5878 13.7666 22.5 13.5547 22.5 13.3337V12.5003C22.5 12.2793 22.5878 12.0673 22.7441 11.9111C22.9004 11.7548 23.1123 11.667 23.3333 11.667C23.5543 11.667 23.7663 11.7548 23.9226 11.9111C24.0789 12.0673 24.1667 12.2793 24.1667 12.5003V13.3337C24.1667 13.5547 24.0789 13.7666 23.9226 13.9229C23.7663 14.0792 23.5543 14.167 23.3333 14.167Z" fill="white" />
            <path d="M23.3333 21.667C23.1123 21.667 22.9004 21.5792 22.7441 21.4229C22.5878 21.2666 22.5 21.0547 22.5 20.8337V20.0003C22.5 19.7793 22.5878 19.5673 22.7441 19.4111C22.9004 19.2548 23.1123 19.167 23.3333 19.167C23.5543 19.167 23.7663 19.2548 23.9226 19.4111C24.0789 19.5673 24.1667 19.7793 24.1667 20.0003V20.8337C24.1667 21.0547 24.0789 21.2666 23.9226 21.4229C23.7663 21.5792 23.5543 21.667 23.3333 21.667Z" fill="white" />
            <path d="M16.6667 0L16.2557 1.89958C16.1458 2.31898 15.8961 2.68902 15.5473 2.94928C15.1985 3.20955 14.7711 3.34467 14.335 3.33259H7.34004C6.90394 3.34467 6.4766 3.20955 6.12776 2.94928C5.77893 2.68902 5.52921 2.31898 5.41936 1.89958L5 0H16.6667Z" fill="#3D8CF5" />
            <path d="M23.333 10.8337H9.16634C8.94533 10.8337 8.73337 10.7459 8.57708 10.5896C8.4208 10.4333 8.33301 10.2213 8.33301 10.0003C8.33301 9.77931 8.4208 9.56735 8.57708 9.41107C8.73337 9.25479 8.94533 9.16699 9.16634 9.16699H23.333C23.554 9.16699 23.766 9.25479 23.9223 9.41107C24.0785 9.56735 24.1663 9.77931 24.1663 10.0003C24.1663 10.2213 24.0785 10.4333 23.9223 10.5896C23.766 10.7459 23.554 10.8337 23.333 10.8337Z" fill="#3D8CF5" />
            <path d="M23.333 24.1667H9.16634C8.94533 24.1667 8.73337 24.0789 8.57708 23.9226C8.4208 23.7663 8.33301 23.5543 8.33301 23.3333C8.33301 23.1123 8.4208 22.9004 8.57708 22.7441C8.73337 22.5878 8.94533 22.5 9.16634 22.5H23.333C23.554 22.5 23.766 22.5878 23.9223 22.7441C24.0785 22.9004 24.1663 23.1123 24.1663 23.3333C24.1663 23.5543 24.0785 23.7663 23.9223 23.9226C23.766 24.0789 23.554 24.1667 23.333 24.1667Z" fill="#3D8CF5" />
            <path d="M17.4997 14.1667H12.4997C12.2787 14.1667 12.0667 14.0789 11.9105 13.9226C11.7542 13.7663 11.6664 13.5543 11.6664 13.3333C11.6664 13.1123 11.7542 12.9004 11.9105 12.7441C12.0667 12.5878 12.2787 12.5 12.4997 12.5H17.4997C17.7207 12.5 17.9327 12.5878 18.089 12.7441C18.2453 12.9004 18.333 13.1123 18.333 13.3333C18.333 13.5543 18.2453 13.7663 18.089 13.9226C17.9327 14.0789 17.7207 14.1667 17.4997 14.1667Z" fill="#3D8CF5" />
            <path d="M17.4997 20.8337H12.4997C12.2787 20.8337 12.0667 20.7459 11.9105 20.5896C11.7542 20.4333 11.6664 20.2213 11.6664 20.0003C11.6664 19.7793 11.7542 19.5673 11.9105 19.4111C12.0667 19.2548 12.2787 19.167 12.4997 19.167H17.4997C17.7207 19.167 17.9327 19.2548 18.089 19.4111C18.2453 19.5673 18.333 19.7793 18.333 20.0003C18.333 20.2213 18.2453 20.4333 18.089 20.5896C17.9327 20.7459 17.7207 20.8337 17.4997 20.8337Z" fill="#3D8CF5" />
            <path d="M16.6663 17.5007H10.833C10.612 17.5007 10.4 17.4129 10.2437 17.2566C10.0874 17.1003 9.99963 16.8883 9.99963 16.6673C9.99963 16.4463 10.0874 16.2343 10.2437 16.0781C10.4 15.9218 10.612 15.834 10.833 15.834H16.6663C16.8873 15.834 17.0993 15.9218 17.2556 16.0781C17.4118 16.2343 17.4996 16.4463 17.4996 16.6673C17.4996 16.8883 17.4118 17.1003 17.2556 17.2566C17.0993 17.4129 16.8873 17.5007 16.6663 17.5007Z" fill="#3D8CF5" />
            <path d="M21.6667 28.334V29.1673C21.6667 30.2724 21.259 31.3322 20.5334 32.1136C19.8079 32.895 18.8238 33.334 17.7976 33.334H3.86905C2.84291 33.334 1.8588 32.895 1.13322 32.1136C0.407631 31.3322 0 30.2724 0 29.1673V28.334H21.6667Z" fill="#3D8CF5" />
            <path d="M12.4997 30H9.16634C8.7061 30 8.33301 30.3731 8.33301 30.8333C8.33301 31.2936 8.7061 31.6667 9.16634 31.6667H12.4997C12.9599 31.6667 13.333 31.2936 13.333 30.8333C13.333 30.3731 12.9599 30 12.4997 30Z" fill="white" />
        </svg>
    );
};

