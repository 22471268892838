
import { env } from '@env';
import { HttpRequestData, httpUtil } from '@/framework';
import {  TypeRevenueOverview } from '@/shared/enum';

export interface GetRevenueOverviewRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: number;
        readonly salonBranchId: number;
        readonly startDate: string;
        readonly endDate: string;
        readonly typeGetOverview?: TypeRevenueOverview;
    }
}

export interface GetRevenueOverviewResponse {
    readonly totalRevenue?: number;
    readonly totalInvoice?: number;
    readonly objectType?: string;
    readonly salonId?: number;
    readonly salonBranchId?: number;
}

export const getRevenueOverview = httpUtil.createHttpRequestMeta<
    GetRevenueOverviewRequest,
    GetRevenueOverviewResponse
>({
    baseUrl: env.orgServer,
    path: '/revenues/get-total-revenue',
    method: 'GET',
    authentication: 'bearer'
});
