import { TableColumnsType } from "antd";
import { ColumnActions, TableCustom } from "@components/table";
import { Block, FlexBox } from "@components/layouts";
import { Text } from "@components/typo"
import { AppAvatar } from "@components/avatar";
import { useRecoilState } from "recoil";
import { accountInfoState } from "@components/state";

export function SalonTable() {
    const [currentUser] = useRecoilState(accountInfoState);
    const salonBranches = currentUser?.salons;
    const columns: TableColumnsType<any> = [
        {
            title: 'Tên salon',
            dataIndex: 'name',
            key: 'name',
            width: '250px',
            render: (_, record) => {
                return <FlexBox justifyContent='flex-start' alignItems='center' gap={8}>
                    <AppAvatar
                        size='sm'
                        url={record.path}
                    />
                    <Text maxWidth={350} fontWeight='bold'>{record.name}</Text>
                </FlexBox>;
            },
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created',
            key: 'created',
            width: '200px'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '250px'
        },
        {
            title: 'Đại chỉ',
            dataIndex: 'address',
            key: 'address',
            width: '400px'
        },
        {
            title: "",
            render: () => {
                return (
                    <ColumnActions
                        viewDetailUrl=""
                        onClickEdit={() => {
                        }}
                        onClickDelete={() => {
                        }}
                        onRedirect={() => {
                        }}
                    />
                );
            },
        },
    ];

    return (
        <TableCustom
            scroll={{ x: 800 }}
            subTitleTable={
                <Block>
                    <Text>Xin chào <Text fontWeight={700} color='red' >{currentUser?.email}</Text>.</Text>
                    <Text> Bạn là thành viên của các salon sau đây, đến salon mà bạn muốn truy cập ngay bây giờ.</Text>
                </Block>
            }
            placeholderSearchTransCode='Search ID, Client, Phone, Email'
            showHeader={true}
            columns={columns}
            dataSource={salonBranches}
        />
    )
}
