
import { env } from '@env';
import { HttpRequestData, httpUtil, PaginationResponse } from '@/framework';

export interface GetRevenueOfStaffTableRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: number;
        readonly startDate: string;
        readonly endDate: string;
        readonly objectType?: string;
    }
}

export interface GetRevenueOfStaffTableResponse  {
    readonly totalRevenue?: number;
    readonly totalQuantity?: number;
    readonly staffName?: string;
    readonly staffId?: number;
    readonly salonId?: number;
    readonly avatar?: string;
}

export const getRevenueOfStaffTable = httpUtil.createHttpRequestMeta<
    GetRevenueOfStaffTableRequest,
    PaginationResponse<GetRevenueOfStaffTableResponse>
>({
    baseUrl: env.orgServer,
    path: '/revenues/staffs',
    method: 'GET',
    authentication: 'bearer'
});
