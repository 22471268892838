import { Text } from '@components/typo';
import { Avatar, Card } from 'antd';
import { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { Block, FlexBox } from '@components/layouts';
import { IconGrowDown, IconGrowUp, IconRevenue, TotalImportIconWareHouse } from '@components/icons';
import { ImportIconWareHouse } from '@components/icons/ImportIconWareHouse';
import { ExportIconWareHouse } from '@components/icons/ExportIconWareHouse';

interface WarehouseReportOverviewProps {
    readonly dateRange: [Dayjs, Dayjs];
}
export default function WarehouseReportOverview(props: WarehouseReportOverviewProps) {
    const { dateRange } = props;
    console.log(dateRange)

    const renderGrow = (current: number, previous: number) => {
        const grow = current - previous;
        const color = grow >= 0 ? 'green' : 'red';

        if (previous === 0) {
            return <Text color={color}>( - )</Text>;
        }

        const percent = (grow / previous) * 100;

        return (
            <FlexBox alignItems='center' >
                <Text fontWeight={600} color={color}>({percent.toFixed(2)}%)</Text>
                {grow > 0 ? <IconGrowUp /> : <IconGrowDown />}
            </FlexBox>
        );

    };

    const WarehouseOverviews = useMemo(() => [
        {
            label: "Tổng tồn kho",
            value: 120000 ?? 0,
            preValue: 120000 ?? 0,
            color: '#EFF4FF',
            icon: <ImportIconWareHouse />
        },
        {
            label: "Tổng xuất kho",
            value: 2000000 ?? 0,
            preValue: 2000000 ?? 0,
            color: '#FEF3F2',
            icon: <ExportIconWareHouse />
        },
        {
            label: "Tổng nhập kho",
            value: 1400000 ?? 0,
            preValue: 1400000 ?? 2,
            color: '#FEF3F2',
            icon: <TotalImportIconWareHouse />
        },
        {
            label: "Tổng giá trị kho",
            value: 150 ?? 0,
            preValue: 150 ?? -1,
            color: '#FFF1F0',
            icon: <IconRevenue color='#F04438' />
        }
    ], []);

    return (
        <FlexBox justifyContent='space-between'>
            {WarehouseOverviews.map((o, i) => (
                <Card key={i} className='card-overview-revenue'>
                    <FlexBox gap={8} alignItems='center' flex='none'>
                        <Avatar
                            style={{ backgroundColor: o.color }}
                            size={42}
                            icon={o.icon}
                        />
                        <FlexBox direction='column'>
                            <Text maxLines={1} maxWidth={200} fontWeight={600} >{o.label}</Text>
                            <FlexBox alignItems='center' gap={4} flexWrap='wrap'>
                                <Block>
                                    <Text color='#101828' fontWeight='700' fontSize={18}>
                                        {o.value}
                                    </Text>
                                </Block>
                                {renderGrow(o.value, o.preValue)}
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </Card>
            ))}
        </FlexBox>
    );
}
