import { useMemo } from 'react';
import { Dayjs } from 'dayjs';

import { Salon } from '../components/state';
import { HttpQueries } from '.';
import { GetCustomerSourceChartRequest, GetCustomerSourceChartResponse, requestCustomerSourceChartByAll, requestCustomerSourceChartBySalon, requestCustomerSourceChartBySalonBranch } from '../api/report/customer/chart/getCustomerSourceChart';

interface UseCustomerSourceChartRequestsProps {
    dateRange: Dayjs[];
    salons?: Salon[];
    selectedSalonBranchId?: number;
    salon?: Salon;
    viewType?: number;
}

interface UseCustomerSourceChartRequestsResult {
    customerSourceChartRequests: HttpQueries<GetCustomerSourceChartRequest, GetCustomerSourceChartResponse>[];
}

export function useCustomerSourceChartRequests({
    dateRange,
    salons,
    selectedSalonBranchId,
    salon,
    viewType,
}: UseCustomerSourceChartRequestsProps): UseCustomerSourceChartRequestsResult {

    const customerSourceChartRequests = useMemo(() => {
        if (salon == undefined && selectedSalonBranchId == undefined) {
            return requestCustomerSourceChartByAll(salons, dateRange, viewType);
        }
        if (salon != undefined && selectedSalonBranchId == undefined) {
            return requestCustomerSourceChartBySalon(dateRange, viewType, salon);
        }
        if (salon != undefined && selectedSalonBranchId != undefined) {
            return requestCustomerSourceChartBySalonBranch(dateRange, viewType, salon, selectedSalonBranchId);
        }
        return [];
    }, [dateRange, viewType, salon, selectedSalonBranchId, salons]);


    return {
        customerSourceChartRequests,
    };
}
