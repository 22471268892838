import { FormBase } from "@components/base";
import { Block, FlexBox } from "@components/layouts";
import { AppButton } from "@components/buttons";
import { IconPrint } from "@components/icons";
import { Header, Text } from "@components/typo";
import AppointmentReportOverview from "./AppointmentReportOverview";
import dayjs from "dayjs";
import AppointmentReportChart from "./AppointmentReportChart";
import AppointmentReportTabs from "./AppointmentReportTabs";

export function AppointmentReport() {
    const currentDate = dayjs();

    const startOfWeek = currentDate.clone().startOf('week').add(1, 'day').endOf('day');
    const endOfWeek = currentDate.clone().endOf('week').add(1, 'day').endOf('day');
    return (
        <FormBase onSubmit={() => { }}>
            <Block backgroundColor='white' className="tab-content">
                <FlexBox direction='column' gap={20}>
                    <FlexBox alignItems='center'>
                        <FlexBox>
                            <Header level={3}>
                                Báo cáo doanh thu
                            </Header>
                        </FlexBox>
                        {/* <Spacer right={850}></Spacer> */}
                        <FlexBox flex={0}>
                            <AppButton >
                                <FlexBox gap={6}>
                                    <IconPrint />
                                    <Text>In tổng hợp</Text>
                                </FlexBox>
                            </AppButton>
                        </FlexBox>
                    </FlexBox>
                    <AppointmentReportOverview dateRange={[startOfWeek, endOfWeek]}></AppointmentReportOverview>
                    <AppointmentReportChart dateRange={[startOfWeek, endOfWeek]} />
                    <AppointmentReportTabs dateRange={[startOfWeek, endOfWeek]} />
                </FlexBox>
            </Block>
        </FormBase>
    )
}
