import { Bar } from '@ant-design/plots';
import { Card } from 'antd';
import { Dayjs } from 'dayjs';
import { Block } from '@components/layouts';
import { Salon } from '@components/state';
import { chartHelper } from '@/helpers';
import { useCustomerGroupChartRequests } from '@hooks/useCustomerGroupChartRequests';
import { useHttpQueries } from '@/hooks';
import { useMemo } from 'react';

interface ReportCustomerGroupBarChartProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly salons?: Salon[] | undefined;
    readonly selectedSalonBranchId?: number | undefined;
    readonly salon?: Salon;
}
interface BarDataGroupChart {
    labelName?: string;
    value?: number;
}
function ReportCustomerGroupBarChart(props: ReportCustomerGroupBarChartProps) {
    const { dateRange, salons, selectedSalonBranchId, salon } = props;
    const viewType = chartHelper.getViewTypeChart(dateRange);

    const {
        customerGroupChartRequests
    } = useCustomerGroupChartRequests({
        dateRange,
        salons,
        selectedSalonBranchId,
        salon,
        viewType,
    });

    const queriesCustomerGroupChart = useHttpQueries(customerGroupChartRequests);

    const totalCustomerGroupChart = useMemo(() => {
        const isLoading = queriesCustomerGroupChart.some(query => query.isLoading);
        const CustomerNewCharts = queriesCustomerGroupChart.flatMap(o => o.data);
        return { isLoading, CustomerNewCharts };
    }, [queriesCustomerGroupChart]);
    const dataChart: BarDataGroupChart[] = totalCustomerGroupChart.CustomerNewCharts?.map(item => ({
        labelName: item?.typeGroup,
        value: item?.valueGroup
    })) ?? [];

    const config = {
        data: dataChart,
        xField: 'labelName',
        yField: 'value',
        height: (dataChart?.length ?? 0) * 90,
        style: {
            maxWidth: 10,
            radius: 10,
        },
        className: 'bar-chart',
        markBackground: {
            label: {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                text: ({ originData }: any) => {
                    return `${originData.value}`;
                },
                position: 'right',
                dy: -20,
                style: {
                    fill: '#aaa',
                    fillOpacity: 1,
                    fontSize: 14,
                },
            },
            style: {
                fill: '#eee',
            },
        },
        label: {
            position: 'left',
            dy: -20,
            text: ({ labelName }: BarDataGroupChart) => {
                return `${labelName}`;
            },
            style: {
                fill: '#1E1E1E',
            }
        },
        scale: {
            y: {
                domain: [0, 200],
            },
        },
        axis: {
            x: {
                tick: false,
                title: false,
                label: false
            },
        },
        interaction: {
            elementHighlightByColor: false,
        },
    };
    return (
        <Block width='100%'>
            <Card title="Nhóm khách hàng">
                {/* <Bar {...config} loading={isLoading} /> */}
                <Bar {...config} />
            </Card>
        </Block>

    );
}

export default ReportCustomerGroupBarChart;