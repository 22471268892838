import { Text } from '@components/typo';
import { Avatar, Card } from 'antd';
import { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { Block, FlexBox } from '@components/layouts';
import { IconRevenue } from '@components/icons';
import { GetRevenueOverviewResponse } from '@/api/report/revenue/overview/getRevenueOverview';
import { UseQueryResult } from '@tanstack/react-query';

const getTotalRevenue = (revenueOverview: UseQueryResult<GetRevenueOverviewResponse, Error>[], objectType: string) => {
    return revenueOverview.reduce((acc, cur) => {
        if( cur.data?.objectType === objectType){
            return acc + (cur?.data?.totalRevenue ??0 );
        }

        return acc;
    }, 0);
}

interface ReportRevenueOverviewProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly revenueOverview: UseQueryResult<GetRevenueOverviewResponse, Error>[]
}
export default function ReportRevenueOverview(props: ReportRevenueOverviewProps) {
    const { revenueOverview } = props;
    const isLoading = revenueOverview.some(query => query.isLoading);
    const revenueOverviews = useMemo(() => [
        {
            label: "Tổng doanh thu",
            value: getTotalRevenue(revenueOverview, "ALL").toLocaleString('en-US'),
            color: '#EFF4FF',
            icon: <IconRevenue />
        },
        {
            label: "Dịch vụ",
            value: getTotalRevenue(revenueOverview, "SERVICE").toLocaleString('en-US'),
            color: '#F6FFED',
            icon: <IconRevenue color='#17B26A' />
        },
        {
            label: "Sản phẩm",
            value: getTotalRevenue(revenueOverview, "PRODUCT").toLocaleString('en-US'),
            color: '#FFF7E6',
            icon: <IconRevenue color='#D48806' />
        },
        {
            label: "Gói dịch vụ",
            value: getTotalRevenue(revenueOverview, "PACKAGE").toLocaleString('en-US'),
            color: '#FFF1F0',
            icon: <IconRevenue color='#F04438' />
        }
    ], [revenueOverview]);

    return (
        <FlexBox justifyContent='space-between'>
            {revenueOverviews.map((o, i) => (
                <Card key={i} className='card-overview-revenue' loading={isLoading}>
                    <FlexBox gap={8} alignItems='center' flex='none'>
                        <Avatar
                            style={{ backgroundColor: o.color }}
                            size={42}
                            icon={o.icon}
                        />
                        <FlexBox direction='column'>
                            <Text color='#8F9BB2' maxLines={1} maxWidth={200} fontWeight={500} >{o.label}</Text>
                            <FlexBox alignItems='center' gap={4} flexWrap='wrap'>
                                <Block>
                                    <Text color='#0A1F44' fontWeight={500} fontSize={20}>
                                        {o.value}
                                    </Text>
                                </Block>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </Card>
            ))}
        </FlexBox>
    );
}
