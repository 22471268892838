import dayjs, { Dayjs } from 'dayjs';
import { Card } from 'antd';
import { Block, Spacer } from '@components/layouts';
import { Text } from "@components/typo";
import { Column } from '@ant-design/charts';
import { chartHelper } from '@/helpers';
import { ViewType } from '@shared/enum/chartEnum';

interface AppointmentReportChartProps {
    readonly dateRange: [Dayjs, Dayjs];
}
interface ColumnData {
    readonly xField: string;
    readonly yField: number;
    readonly name?: string;
    readonly color?: string;
    readonly value?: number;
    readonly category?: string;
}
export default function AppointmentReportChart(props: AppointmentReportChartProps) {
    const { dateRange } = props;
    const viewType = chartHelper.getViewTypeChart(dateRange);
    const appointmentsData = [
        {
            date: "2024-07-31T17:00:00Z",
            totalAppointment: 16,
            totalAppointmentCancel: 1,
            totalAppointmentOnline: 10,
            totalAppointmentSalon: 6,
            totalAppointmentValue: 290
        },
        {
            date: "2024-08-01T17:00:00Z",
            totalAppointment: 1,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 1,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-02T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-03T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-04T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-05T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-06T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-07T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-08T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-09T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-10T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-11T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-12T17:00:00Z",
            totalAppointment: 5,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 2,
            totalAppointmentSalon: 3,
            totalAppointmentValue: 480
        },
        {
            date: "2024-08-13T17:00:00Z",
            totalAppointment: 2,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 2,
            totalAppointmentValue: 190
        },
        {
            date: "2024-08-14T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-15T17:00:00Z",
            totalAppointment: 2,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 2,
            totalAppointmentValue: 100
        },
        {
            date: "2024-08-16T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-17T17:00:00Z",
            totalAppointment: 1,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 1,
            totalAppointmentValue: 130
        },
        {
            date: "2024-08-18T17:00:00Z",
            totalAppointment: 1,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 1,
            totalAppointmentValue: 30
        },
        {
            date: "2024-08-19T17:00:00Z",
            totalAppointment: 3,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 1,
            totalAppointmentSalon: 2,
            totalAppointmentValue: 180
        },
        {
            date: "2024-08-20T17:00:00Z",
            totalAppointment: 1,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 1,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-21T17:00:00Z",
            totalAppointment: 1,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 1,
            totalAppointmentValue: 40
        },
        {
            date: "2024-08-22T17:00:00Z",
            totalAppointment: 1,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 1,
            totalAppointmentValue: 40
        },
        {
            date: "2024-08-23T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-24T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-25T17:00:00Z",
            totalAppointment: 4,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 1,
            totalAppointmentSalon: 3,
            totalAppointmentValue: 210
        },
        {
            date: "2024-08-26T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-27T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-28T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-29T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-30T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        },
        {
            date: "2024-08-31T17:00:00Z",
            totalAppointment: 0,
            totalAppointmentCancel: 0,
            totalAppointmentOnline: 0,
            totalAppointmentSalon: 0,
            totalAppointmentValue: 0
        }
    ];

    const dataChart = appointmentsData?.map((item) => {
        let columns: ColumnData[] = [];
        if (viewType === ViewType.Hour) {
            columns = [
                {
                    xField: dayjs(item.date).format('HH:mm'),
                    yField: item.totalAppointment,
                    category: 'Total appointment',
                    value: item.totalAppointment,
                },
                {
                    xField: dayjs(item.date).format('HH:mm'),
                    yField: item.totalAppointmentCancel,
                    category: 'Cancel appointment',
                    value: item.totalAppointmentCancel,
                },
            ];
            return columns;
        }

        columns = [
            {
                xField: chartHelper.getDayOrdinalFromDate(item.date),
                yField: item.totalAppointment,
                name: 'Total appointment',
                color: '#2970FF',
            },
            {
                xField: chartHelper.getDayOrdinalFromDate(item.date),
                yField: item.totalAppointmentCancel,
                name: 'Cancel appointment',
                color: '#F04438',
            },
        ];
        return columns;
    }).flat() ?? [];


    const configChartColumn = {
        data: dataChart,
        xField: 'xField',
        yField: 'yField',
        colorField: 'name',
        group: true,
        sizeField: 35,
        scrollbar: {
            x: {
                ratio: 0.5,
                style: { trackSize: 15 }
            }
        },
        style: {
            radiusTopLeft: 10,
            radiusTopRight: 10,
            insetLeft: 10,
            insetRight: 10,
            fill: (type: ColumnData) => {
                return type.color;
            },
        },
        scale: {
            x: {
                type: 'band',
            },
            color: {
                range: ['#2970FF', '#F04438'],
            },
        },
        legend: {
            color: {
                layout: {
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    alignContent: 'flex-end',
                    alignItems: 'flex-end'
                },
                itemWidth: '100%',
            },
        },
    };


    return (
        <Block overflow='hidden' maxWidth='100%'>
            <Spacer top={30}>
                <Card>
                    <Text fontSize={18} fontWeight={600}>Tổng số lịch hẹn</Text>
                    {/* {!isLoading && (
                        <>
                            {viewType === ViewType.Hour && (
                                <DualAxes {...configLineChart} />
                            )}
                            {viewType !== ViewType.Hour && (
                                <DualAxes {...configColumnChart} />
                            )}
                        </>
                    )} */}
                    <Column {...configChartColumn}
                    // loading={isLoading} 
                    />
                </Card>
            </Spacer>
        </Block>
    );
}
