import { Dayjs } from 'dayjs';
import { env } from '@env';
import { HttpRequestData, httpUtil } from '@/framework';
import { ViewType } from '@shared/enum/chartEnum';
import { Salon } from '@components/state/accountInfoState';
import { HttpQueries } from '@hooks/useHttpQueries';

export interface GetCustomerSourceChartRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: string;
        readonly salonBranchId: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly viewType?: ViewType;
    }
}

export interface GetCustomerSourceChartResponse {
    typeSource: string;
    valueSource: number;
    salonBranchId: number;
    salonId: number;
}

export const requestCustomerSourceChartByAll = (
    salons: Salon[] | undefined,
    dateRange: Dayjs[],
    viewType?: ViewType,
) => {
    const requestsData: HttpQueries<GetCustomerSourceChartRequest, GetCustomerSourceChartResponse>[] = salons!.flatMap(salon =>
        salon!.salonBranches!.map(salonBranch => ({
            requestMeta: getCustomerSourceChart,
            requestData: {
                query: {
                    salonId: salon.id,
                    salonBranchId: salonBranch.id,
                    startDate: dateRange[0].toISOString(),
                    endDate: dateRange[1].toISOString(),
                    viewType: viewType,
                },
            },
        }))
    );
    return requestsData;
};
export const requestCustomerSourceChartBySalon = (
    dateRange: Dayjs[],
    viewType?: ViewType,
    salonSelect?: Salon
) => {
    const requestsData: HttpQueries<GetCustomerSourceChartRequest, GetCustomerSourceChartResponse>[] = salonSelect!.salonBranches!.map(salonBranch => ({
        requestMeta: getCustomerSourceChart,
        requestData: {
            query: {
                salonId: salonSelect?.id,
                salonBranchId: salonBranch.id,
                startDate: dateRange[0].toISOString(),
                endDate: dateRange[1].toISOString(),
                viewType: viewType,
            },
        },
    }));
    return requestsData;
};
export const requestCustomerSourceChartBySalonBranch = (
    dateRange: Dayjs[],
    viewType?: ViewType,
    salonSelect?: Salon,
    selectedSalonBranchId?: number
) => {
    const requestsData: HttpQueries<GetCustomerSourceChartRequest, GetCustomerSourceChartResponse>[] = [{
        requestMeta: getCustomerSourceChart,
        requestData: {
            query: {
                salonId: salonSelect?.id,
                salonBranchId: selectedSalonBranchId,
                startDate: dateRange[0].toISOString(),
                endDate: dateRange[1].toISOString(),
                viewType: viewType,
            },
        },
    }]
    return requestsData;
};

export const getCustomerSourceChart = httpUtil.createHttpRequestMeta<
    GetCustomerSourceChartRequest,
    GetCustomerSourceChartResponse
>({
    baseUrl: env.orgServer,
    path: '/customers/source-chart',
    method: 'GET',
    authentication: 'bearer'
});
