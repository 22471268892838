import { Tabs } from 'antd';
import { TabsProps } from 'antd/lib';
import { Dayjs } from 'dayjs';
import { Spacer } from '@components/layouts';
import { WareHoseTable } from './table/WareHoseTable';

interface WarehouseReportTabsProps {
    readonly dateRange: [Dayjs, Dayjs];
}

export default function WarehouseReportTabs(props: WarehouseReportTabsProps) {
    const { dateRange } = props;
    console.log(dateRange)

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: "Sản phẩm",
            children: <WareHoseTable />,
        }
    ];

    return (
        <Spacer top={24} >
            <Tabs defaultActiveKey='1' items={items} />
        </Spacer>
    );
}
