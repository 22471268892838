import { useMemo } from 'react';
import { Dayjs } from 'dayjs';

import { Salon } from '../components/state';
import { HttpQueries } from '.';
import { GetTotalCustomerNewChartRequest, GetTotalCustomerNewChartResponse, requestCustomerNewChartByAll, requestCustomerNewChartBySalon, requestCustomerNewChartBySalonBranch } from '../api/report/customer/chart/getCustomerNewChart';
import { GetTotalCustomerReturnChartRequest, GetTotalCustomerReturnChartResponse, requestCustomerReturnChartByAll, requestCustomerReturnChartBySalon, requestCustomerReturnChartBySalonBranch } from '../api/report/customer/chart/getCustomerReturnChart';
import { GetTotalCustomerWalkInChartRequest, GetTotalCustomerWalkInChartResponse, requestCustomerWalkInChartByAll, requestCustomerWalkInChartBySalon, requestCustomerWalkInChartBySalonBranch } from '../api/report/customer/chart/getCustomerWalkInChart';

interface UseCustomerOverviewRequestsProps {
    dateRange: Dayjs[];
    salons?: Salon[];
    selectedSalonBranchId?: number;
    salon?: Salon;
    viewType?: number;
}

interface UseCustomerOverviewRequestsResult {
    customerNewChartRequests: HttpQueries<GetTotalCustomerNewChartRequest, GetTotalCustomerNewChartResponse>[];
    customerReturnChartRequests: HttpQueries<GetTotalCustomerReturnChartRequest, GetTotalCustomerReturnChartResponse>[];
    customerWalkInChartRequests: HttpQueries<GetTotalCustomerWalkInChartRequest, GetTotalCustomerWalkInChartResponse>[];
}

export function useCustomerMainChartRequests({
    dateRange,
    salons,
    selectedSalonBranchId,
    salon,
    viewType,
}: UseCustomerOverviewRequestsProps): UseCustomerOverviewRequestsResult {

    const customerNewChartRequests = useMemo(() => {
        if (salon == undefined && selectedSalonBranchId == undefined) {
            return requestCustomerNewChartByAll(salons, dateRange, viewType);
        }
        if (salon != undefined && selectedSalonBranchId == undefined) {
            return requestCustomerNewChartBySalon(dateRange, viewType, salon);
        }
        if (salon != undefined && selectedSalonBranchId != undefined) {
            return requestCustomerNewChartBySalonBranch(dateRange, viewType, salon, selectedSalonBranchId);
        }
        return [];
    }, [dateRange, viewType, salon, selectedSalonBranchId, salons]);

    const customerReturnChartRequests = useMemo(() => {
        if (salon == undefined && selectedSalonBranchId == undefined) {
            return requestCustomerReturnChartByAll(salons, dateRange, viewType);
        }
        if (salon != undefined && selectedSalonBranchId == undefined) {
            return requestCustomerReturnChartBySalon(dateRange, viewType, salon);
        }
        if (salon != undefined && selectedSalonBranchId != undefined) {
            return requestCustomerReturnChartBySalonBranch(dateRange, viewType, salon, selectedSalonBranchId);
        }
        return [];
    }, [dateRange, viewType, salon, selectedSalonBranchId, salons]);


    const customerWalkInChartRequests = useMemo(() => {
        if (salon == undefined && selectedSalonBranchId == undefined) {
            return requestCustomerWalkInChartByAll(salons, dateRange, viewType);
        }
        if (salon != undefined && selectedSalonBranchId == undefined) {
            return requestCustomerWalkInChartBySalon(dateRange, viewType, salon);
        }
        if (salon != undefined && selectedSalonBranchId != undefined) {
            return requestCustomerWalkInChartBySalonBranch(dateRange, viewType, salon, selectedSalonBranchId);
        }
        return [];
    }, [dateRange, viewType, salon, selectedSalonBranchId, salons]);


    return {
        customerNewChartRequests,
        customerReturnChartRequests,
        customerWalkInChartRequests
    };
}
