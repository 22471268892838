import { IconProps } from '../../Types';

export const IconAppointmentCancel = (props: IconProps) => {
    return (
        <svg
            width='25'
            height='24'
            viewBox='0 0 25 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path
                d='M7.95004 2.5C7.95004 2.08579 7.61425 1.75 7.20004 1.75C6.78583 1.75 6.45004 2.08579 6.45004 2.5V4.07926C5.01071 4.19451 4.06581 4.47737 3.37161 5.17157C2.67741 5.86577 2.39455 6.81067 2.2793 8.25H22.1208C22.0055 6.81067 21.7227 5.86577 21.0285 5.17157C20.3343 4.47737 19.3894 4.19451 17.95 4.07926V2.5C17.95 2.08579 17.6143 1.75 17.2 1.75C16.7858 1.75 16.45 2.08579 16.45 2.5V4.0129C15.7848 4 15.0391 4 14.2 4H10.2C9.36101 4 8.61531 4 7.95004 4.0129V2.5Z'
                fill={props.color}
            />
            <path
                opacity='0.4'
                d='M22.2002 12V14C22.2002 14.2053 22.2002 14.405 22.2 14.5992C21.237 13.4677 19.8024 12.75 18.2002 12.75C15.3007 12.75 12.9502 15.1005 12.9502 18C12.9502 19.6022 13.6679 21.0368 14.7994 21.9998C14.6052 22 14.4055 22 14.2002 22H10.2002C6.42896 22 4.54334 22 3.37177 20.8284C2.2002 19.6569 2.2002 17.7712 2.2002 14V12C2.2002 11.161 2.2002 10.4153 2.2131 9.75H22.1873C22.2002 10.4153 22.2002 11.161 22.2002 12Z'
                fill={props.color}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M18.4502 15.5C16.9314 15.5 15.7002 16.7312 15.7002 18.25C15.7002 18.7504 15.8334 19.2191 16.0669 19.6232L19.8234 15.8667C19.4193 15.6332 18.9506 15.5 18.4502 15.5ZM20.8698 16.9416L17.1418 20.6696C17.5308 20.8805 17.9763 21 18.4502 21C19.969 21 21.2002 19.7688 21.2002 18.25C21.2002 17.7761 21.0807 17.3306 20.8698 16.9416ZM14.2002 18.25C14.2002 15.9028 16.103 14 18.4502 14C19.6392 14 20.7154 14.4893 21.4859 15.2756C22.2363 16.0414 22.7002 17.0923 22.7002 18.25C22.7002 20.5972 20.7974 22.5 18.4502 22.5C17.2924 22.5 16.2416 22.0361 15.4758 21.2857C14.6895 20.5152 14.2002 19.439 14.2002 18.25Z'
                fill={props.color}
            />
        </svg>
    );
};

IconAppointmentCancel.defaultProps = {
    className: '',
    onClick: () => { },
    color: '#F04438',
};
