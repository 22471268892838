import { TableColumnsType, Tag } from "antd";
import { TableCustom } from "@components/table";
import { FlexBox } from "@components/layouts";
import { AppAvatar } from "@components/avatar";
import { Text } from '@components/typo';
import { globalModalState, salonActiveState } from "@components/state";
import { useRecoilState } from "recoil";
import { getRevenueOfStaffTable, GetRevenueOfStaffTableResponse } from "@/api/report/revenue";
import { Dayjs } from "dayjs";
import { useHttpQuery, useSearchParams } from "@/hooks";
import { RevenueByStaffDetailTable } from "./table-details/RevenueByStaffDetailTable";
import { useCallback, useMemo } from "react";

interface RevenueByStaffTableProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly objectType: "SERVICE" | "PRODUCT";
}

export function RevenueByStaffTable(props: RevenueByStaffTableProps) {
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const [salonActive] = useRecoilState(salonActiveState);
    const [searchParams, setSearchParams] = useSearchParams({
        staffSearch: '',
        staffPage: '1',
        staffPageSize: '20'
    }, ['staffSearch', 'staffPage', 'staffPageSize']);

    const {data : revenueOfStaffTable,} = useHttpQuery(getRevenueOfStaffTable, {
        query: {
            startDate: props.dateRange[0].toISOString(),
            endDate: props.dateRange[1].toISOString(),
            objectType: props.objectType,
            salonId: salonActive?.id ?? 0,
            search: searchParams.staffSearch,
            page: searchParams.staffPage,
            pageSize: searchParams.staffPageSize
        }
    });

    const showDetail = useCallback((title?: string, staffId?: number) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode:
                <p>
                    <span>Doanh thu sản phẩm nhân viên: </span>
                    <span style={{ color: 'grey', fontWeight: 400 }}>{title}</span>
                </p>,
            width: 850,
            content: <RevenueByStaffDetailTable dateRange={props.dateRange} objectType={props.objectType} staffId={staffId!}/>
        });
    }, [globalModal, props.dateRange, props.objectType, setGlobalModal]);

    const columns: TableColumnsType<GetRevenueOfStaffTableResponse> = useMemo(() => [
        {
            title: 'Tên nhân viên',
            dataIndex: 'staffName',
            key: 'name',
            render: (_, record) => {
                return <FlexBox justifyContent='flex-start' alignItems='center' gap={8}>
                    <AppAvatar
                        size='sm'
                        url={record.avatar}
                    />
                    <Text maxWidth={350} fontWeight='bold'>{record.staffName}</Text>
                </FlexBox>;
            },
        },
        {
            title: 'Số lượng',
            dataIndex: 'totalQuantity',
            key: 'quantity',
            width: 250
        },
        {
            title: 'Doanh thu',
            dataIndex: 'totalRevenue',
            key: 'value',
            width: 150
        },
        {
            title: "Liên kết",
            width: 150,
            render: (_, record) => {
                return (
                    <a>
                        <Tag onClick={() => { showDetail(record.staffName, record.staffId) }} style={{ borderRadius: 2 }} color="default">Xem chi tiết</Tag>
                    </a>
                );
            },
        },
    ], [showDetail]);

    const objectTypeTransCode = props.objectType === 'SERVICE' ? 'dịch vụ' : 'sản phẩm';
    return (
        <TableCustom
            scroll={{ x: 800 }}
            titleTableTransCode={`Doanh thu ${objectTypeTransCode} theo nhân viên`}
            onSearch={(value) => {
                setSearchParams({
                    ...searchParams,
                    staffSearch: value
                });
            }}
            onPageChange={(nextPage) => {
                setSearchParams({
                    ...searchParams,
                    staffPage: nextPage.toString()
                });
            }}
            pageInfo={revenueOfStaffTable?.meta}
            placeholderSearchTransCode='Tìm nhân viên'
            showHeader={true}
            columns={columns}
            dataSource={revenueOfStaffTable?.items}
        />
    )
}
