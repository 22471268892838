import { Text } from '@components/typo';
import { Avatar, Card } from 'antd';
import { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { Block, FlexBox } from '@components/layouts';
import { IconAppointmentCancel, IconAppointmentOnline, IconGrowDown, IconGrowUp, IconRevenue, IconSalon, IconTotalAppointment } from '@components/icons';

interface AppointmentReportOverviewProps {
    readonly dateRange: [Dayjs, Dayjs];
}
export default function AppointmentReportOverview(props: AppointmentReportOverviewProps) {
    const { dateRange } = props;
    console.log(dateRange);
    
    const renderGrow = (current: number, previous: number) => {
        const grow = current - previous;
        const color = grow >= 0 ? 'green' : 'red';

        if (previous === 0) {
            return <Text color={color}>( - )</Text>;
        }

        const percent = (grow / previous) * 100;

        return (
            <FlexBox alignItems='center' >
                <Text fontWeight={600} color={color}>({percent.toFixed(2)}%)</Text>
                {grow > 0 ? <IconGrowUp /> : <IconGrowDown />}
            </FlexBox>
        );

    };

    const revenueOverviews = useMemo(() => [
        {
            label: "Tổng số lịch hẹn",
            value: 120000 ?? 0,
            preValue: 120000 ?? 0,
            color: '#EFF4FF',
            icon: <IconTotalAppointment />
        },
        {
            label: "Lịch hẹn đã huỷ",
            value: 2000000 ?? 0,
            preValue: 2000000 ?? 0,
            color: '#FEF3F2',
            icon: <IconAppointmentCancel />
        },
        {
            label: "Đặt lịch online",
            value: 1400000 ?? 0,
            preValue: 1400000 ?? 2,
            color: '#ECFDF3',
            icon: <IconAppointmentOnline />
        },
        {
            label: "Đặt lịch tại salon",
            value: 150 ?? 0,
            preValue: 150 ?? -1,
            color: '#FFFAEB',
            icon: <IconSalon />
        },
        {
            label: "Doanh thu đặt lịch",
            value: 150000 ?? 0,
            preValue: 150000 ?? -1,
            color: '#EFF4FF',
            icon: <IconRevenue />,
        }
    ], []);

    return (
        <FlexBox justifyContent='space-between'>
            {revenueOverviews.map((o, i) => (
                <Card key={i} className='card-overview-appointment'>
                    <FlexBox gap={8} alignItems='center' flex='none'>
                        <Avatar
                            style={{ backgroundColor: o.color }}
                            size={42}
                            icon={o.icon}
                        />
                        <FlexBox direction='column'>
                            <Text maxLines={1} maxWidth={200} fontWeight={600} >{o.label}</Text>
                            <FlexBox alignItems='center' gap={4} flexWrap='wrap'>
                                <Block>
                                    <Text color='#101828' fontWeight='700' fontSize={18}>
                                        {o.value}
                                    </Text>
                                </Block>
                                {renderGrow(o.value, o.preValue)}
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </Card>
            ))}
        </FlexBox>
    );
}
