import { getRevenueChartSalon, getRevenueChartSalonBranch } from "@/api/report/revenue";
import { salonActiveState, salonsState } from "@/components/state";
import { ViewType } from "@/shared/enum/chartEnum";
import dayjs from "dayjs";
import { useRecoilState } from "recoil";

function useQueriesRevenueChartEarlier(objectType: string , numberYear: number) {

    const [salonActive] = useRecoilState(salonActiveState);
    const [salons] = useRecoilState(salonsState);

    const viewType = ViewType.Month;

    const queries = new Array(numberYear).fill(0).map((_, index) => {
        const startDate = dayjs().subtract(index, 'year').startOf('year');
        const endDate = dayjs().subtract(index, 'year').endOf('year');

        if(salonActive)
        {
            const queriesOverviewAllSalonBranch = salonActive?.salonBranches?.map((branch) => ({
                requestMeta: getRevenueChartSalonBranch,
                requestData: {
                    query: {
                        startDate: startDate.format('YYYY-MM-DD'),
                        endDate: endDate.format('YYYY-MM-DD'),
                        salonId: branch.salonId,
                        branchId: branch.id,
                        viewType: viewType,
                        objectType: objectType ?? null,
                    }
                }
            }));

            return queriesOverviewAllSalonBranch ?? [];
        } else {
            const queriesOverviewAllSalons = salons?.map((salon) => ({
                requestMeta: getRevenueChartSalon,
                requestData: {
                    query: {
                        startDate: startDate.format('YYYY-MM-DD'),
                        endDate: endDate.format('YYYY-MM-DD'),
                        salonId: salon.id,
                        viewType: viewType,
                        objectType: objectType ?? null,
                    }
                }
            }));

            return queriesOverviewAllSalons ?? [];
        }
    });

    return queries.flat();
}

export default useQueriesRevenueChartEarlier;