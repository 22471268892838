import { FormBase } from "@components/base";
import { Block, FlexBox } from "@components/layouts";
import { AppButton } from "@components/buttons";
import { IconPrint } from "@components/icons";
import { Header, Text } from "@components/typo";
import WarehouseReportOverview from "./WarehouseReportOverview";
import dayjs from "dayjs";
import WarehouseReportChart from "./WarehouseReportChart";
import WarehouseReportTabs from "./WarehouseReportTabs";

export function WarehouseReport() {
    const currentDate = dayjs();

    const startOfWeek = currentDate.clone().startOf('week').add(1, 'day').endOf('day');
    const endOfWeek = currentDate.clone().endOf('week').add(1, 'day').endOf('day');
    return (
        <FormBase onSubmit={() => { }}>
            <Block backgroundColor='white' className="tab-content">
                <FlexBox direction='column' gap={20}>
                    <FlexBox alignItems='center'>
                        <FlexBox>
                            <Header level={3}>
                                Báo cáo kho hàng
                            </Header>
                        </FlexBox>
                        <FlexBox flex={0}>
                            <AppButton >
                                <FlexBox gap={6}>
                                    <IconPrint />
                                    <Text>In tổng hợp</Text>
                                </FlexBox>
                            </AppButton>
                        </FlexBox>
                    </FlexBox>
                    <WarehouseReportOverview dateRange={[startOfWeek, endOfWeek]}></WarehouseReportOverview>
                    <WarehouseReportChart dateRange={[startOfWeek, endOfWeek]} />
                    <WarehouseReportTabs dateRange={[startOfWeek, endOfWeek]} />
                </FlexBox>
            </Block>
        </FormBase>
    )
}
