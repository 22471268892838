import { useMemo } from 'react';
import { Dayjs } from 'dayjs';

import { Salon } from '../components/state';
import { HttpQueries } from '.';
import { GetCustomerRankChartRequest, GetCustomerRankChartResponse, requestCustomerRankChartByAll, requestCustomerRankChartBySalon, requestCustomerRankChartBySalonBranch } from '../api/report/customer/chart/getCustomerRankChart';

interface UseCustomerRankChartRequestsProps {
    dateRange: Dayjs[];
    salons?: Salon[];
    selectedSalonBranchId?: number;
    salon?: Salon;
    viewType?: number;
}

interface UseCustomerRankChartRequestsResult {
    customerRankChartRequests: HttpQueries<GetCustomerRankChartRequest, GetCustomerRankChartResponse>[];
}

export function useCustomerRankChartRequests({
    dateRange,
    salons,
    selectedSalonBranchId,
    salon,
    viewType,
}: UseCustomerRankChartRequestsProps): UseCustomerRankChartRequestsResult {

    const customerRankChartRequests = useMemo(() => {
        if (salon == undefined && selectedSalonBranchId == undefined) {
            return requestCustomerRankChartByAll(salons, dateRange, viewType);
        }
        if (salon != undefined && selectedSalonBranchId == undefined) {
            return requestCustomerRankChartBySalon(dateRange, viewType, salon);
        }
        if (salon != undefined && selectedSalonBranchId != undefined) {
            return requestCustomerRankChartBySalonBranch(dateRange, viewType, salon, selectedSalonBranchId);
        }
        return [];
    }, [dateRange, viewType, salon, selectedSalonBranchId, salons]);


    return {
        customerRankChartRequests,
    };
}
