import { TableColumnsType, Tag, } from "antd";
import { TableCustom } from "@components/table";
import { PaymentMethodColumn } from "./PaymentMethodColumn";
import { globalModalState, salonActiveState } from "@components/state";
import { useRecoilState } from "recoil";
import { RevenueByPaymentMethodDetailTable } from "./table-details/RevenueByPaymentMethodDetailTable";
import { getRevenueOfPaymentMethod, GetRevenueOfPaymentMethodResponse } from "@/api/report/revenue";
import { useHttpQuery } from "@/hooks";
import { Dayjs } from "dayjs";
interface RevenueByPaymentMethodTableProps {
    readonly dateRange: [Dayjs, Dayjs];
}

export function RevenueByPaymentMethodTable(props: RevenueByPaymentMethodTableProps) {
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const [salonActive] = useRecoilState(salonActiveState);

    const {data : revenueOfPaymentMethodResponse} = useHttpQuery(getRevenueOfPaymentMethod, {
        query: {
            startDate: props.dateRange[0].toISOString(),
            endDate: props.dateRange[1].toISOString(),
            salonId: salonActive?.id ?? 0
        }
    });

    const showDetail = (title: string, paymentMethodId: number) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: title,
            width: 850,
            content: <RevenueByPaymentMethodDetailTable paymentMethodId={paymentMethodId} dateRange={props.dateRange}/>
        });
    };
    
    const columns: TableColumnsType<GetRevenueOfPaymentMethodResponse> = [
        {
            key: 'paymentMethodCode',
            title: 'Phương thức thanh toán',
            dataIndex: 'paymentMethodCode',
            render: (_, record) => {
                return <PaymentMethodColumn paymentMethodCode={record.paymentMethodCode ?? ''} />
            },
        },
        {
            key: 'totalQuantity',
            title: 'Số lượng',
            dataIndex: 'totalQuantity',
            width: 250
        },
        {
            key: 'value',
            title: 'Doanh thu',
            dataIndex: 'totalRevenue',
            width: 150
        },
        {
            title: "Liên kết",
            width: 150,
            render: (_, record) => {
                return (
                    <a>
                        <Tag onClick={() => { showDetail(record.paymentMethodCode!, record.paymentMethodId!) }} style={{ borderRadius: 2 }} color="default">Xem chi tiết</Tag>
                    </a>
                );
            },
        },
    ];

    return (
        <TableCustom
            scroll={{ x: 800 }}
            titleTableTransCode="Doanh thu theo doanh mục sản phẩm"
            onSearch={() => { }}
            placeholderSearchTransCode="Tìm doanh mục"
            showHeader={true}
            columns={columns}
            dataSource={revenueOfPaymentMethodResponse?.items}
        />
    )
}
