import { TableColumnsType } from "antd";
import { TableCustom } from "@components/table";
import { getRevenueOfCategoryDetail, GetRevenueOfCategoryDetailResponse } from "@/api/report/revenue";
import { useHttpQuery } from "@/hooks";
import { Dayjs } from "dayjs";
import { salonActiveState } from "@/components/state";
import { useRecoilState } from "recoil";

interface RevenueByTypeCategoryDetailTableProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly objectType: "SERVICE" | "PRODUCT";
    readonly categoryId: number;
}

export function RevenueByTypeCategoryDetailTable(props: RevenueByTypeCategoryDetailTableProps) {
    const [salonActive] = useRecoilState(salonActiveState);

    const {data : revenueOfCategoryDetail} = useHttpQuery(getRevenueOfCategoryDetail, {
        query: {
            startDate: props.dateRange[0].toISOString(),
            endDate: props.dateRange[1].toISOString(),
            objectType: props.objectType,
            categoryId: props.categoryId,
            salonId: salonActive?.id ?? 0
        }
    });

    const columns: TableColumnsType<GetRevenueOfCategoryDetailResponse> = [
        {
            title: 'Chi nhánh',
            dataIndex: 'salonBranchId',
            key: 'name',
            render: (salonBranchId: number) => {
                return salonActive?.salonBranches?.find(branch => branch.id === salonBranchId)?.name;
            }
        },
        {
            title: 'Số lượng',
            dataIndex: 'totalQuantity',
            key: 'quantity',
            width: 250
        },
        {
            title: 'Doanh thu',
            dataIndex: 'totalRevenue',
            key: 'value',
            width: 150
        }
    ];

    return (
        <div style={{ paddingTop: 20 }}>
            <TableCustom
                showHeader={false}
                columns={columns}
                dataSource={revenueOfCategoryDetail?.items}
                showFooter={false}
            />
        </div>
    )
}
