import { TableColumnsType, Tag } from "antd";
import { TableCustom } from "@components/table";
import { FlexBox } from "@components/layouts";
import { AppAvatar } from "@components/avatar";
import { Text } from '@components/typo';
import { globalModalState } from "@components/state";
import { useRecoilState } from "recoil";
import { ServiceByStaffAppointmentDetailTable } from "./ServiceByStaffAppointmentDetailTable";

export interface ServiceByStaffAppointmentData {
    readonly id?: string;
    readonly name?: string;
    readonly value?: string;
    readonly quantity?: string;
    readonly url?: string;
}

export function ServiceByStaffAppointmentTable() {

    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    // const resetGlobalModal = useResetRecoilState(globalModalState);

    const showDetail = (title: string) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode:
                <p>
                    <span>Doanh thu dịch vụ theo nhân viên: </span>
                    <span style={{ color: 'grey', fontWeight: 400 }}>{title}</span>
                </p>,
            width: 850,
            content: <ServiceByStaffAppointmentDetailTable />
        });
    };

    const data: ServiceByStaffAppointmentData[] = [
        {
            id: '1',
            value: "250",
            name: "Staff 1",
            quantity: "8",
            url: "https://cly.1cdn.vn/2022/05/10/anh-nen-avatar-dep_021652403.jpg"
        },
        {
            id: '2',
            value: "615000",
            name: "Staff 2",
            quantity: "7",
            url: "https://cly.1cdn.vn/2022/05/10/anh-nen-avatar-dep_021652403.jpg"
        },
        {
            id: '3',
            value: "432000",
            name: "Staff 4",
            quantity: "5",
            url: "https://cly.1cdn.vn/2022/05/10/anh-nen-avatar-dep_021652403.jpg"
        },
        {
            id: '5',
            value: "45000",
            name: "Staff 6",
            quantity: "2",
            url: "https://cly.1cdn.vn/2022/05/10/anh-nen-avatar-dep_021652403.jpg"
        },
        {
            id: '6',
            value: "345000",
            name: "Staff 8",
            quantity: "5",
            url: "https://cly.1cdn.vn/2022/05/10/anh-nen-avatar-dep_021652403.jpg"
        },
        {
            id: '7',
            value: "550000",
            name: "Staff 10",
            quantity: "8",
            url: "https://cly.1cdn.vn/2022/05/10/anh-nen-avatar-dep_021652403.jpg"
        },
        {
            id: '8',
            value: "4450000",
            name: "Staff 34",
            quantity: "8",
            url: "https://cly.1cdn.vn/2022/05/10/anh-nen-avatar-dep_021652403.jpg"
        },
        {
            id: '9',
            value: "550000",
            name: "Staff",
            quantity: "8",
            url: "https://cly.1cdn.vn/2022/05/10/anh-nen-avatar-dep_021652403.jpg"
        },
        {
            id: '10',
            value: "150000",
            name: "Staff",
            quantity: "8",
            url: "https://cly.1cdn.vn/2022/05/10/anh-nen-avatar-dep_021652403.jpg"
        },
        {
            id: '11',
            value: "89850000",
            name: "Staff",
            quantity: "8",
            url: "https://cly.1cdn.vn/2022/05/10/anh-nen-avatar-dep_021652403.jpg"
        }
    ];

    const columns: TableColumnsType<ServiceByStaffAppointmentData> = [
        {
            title: 'Tên nhân viên',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return <FlexBox justifyContent='flex-start' alignItems='center' gap={8}>
                    <AppAvatar
                        size='sm'
                        url={record.url}
                    />
                    <Text maxWidth={350} fontWeight='bold'>{record.name}</Text>
                </FlexBox>;
            },
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 250
        },
        {
            title: 'Doanh thu',
            dataIndex: 'value',
            key: 'value',
            width: 150
        },
        {
            title: "Liên kết",
            width: 150,
            render: (_, record) => {
                return (
                    // <ColumnActions
                    //     viewDetailUrl=""
                    //     onClickEdit={() => {
                    //     }}
                    //     onClickDelete={() => {
                    //     }}

                    // />
                    <a>
                        <Tag onClick={() => { showDetail(record.name ?? '') }} style={{ borderRadius: 2 }} color="default">Xem chi tiết</Tag>
                    </a>
                );
            },
        },
    ];

    return (
        <TableCustom
            scroll={{ x: 800 }}
            titleTableTransCode="Dịch vụ theo nhân viên"
            onSearch={() => { }}
            placeholderSearchTransCode='Tìm nhân viên'
            showHeader={true}
            columns={columns}
            dataSource={data}
        />
    )
}
