import { DualAxes, Line } from '@ant-design/plots';
import dayjs, { Dayjs } from 'dayjs';
import { Card, Dropdown, MenuProps } from 'antd';
import { Block, FlexBox, Spacer } from '@components/layouts';
import { Text } from "@components/typo";
import { SelectField } from '@components/inputs/fields/SelectField';
import { AppButton } from '@components/buttons';
import { IconMore } from '@components/icons';
import { useMemo, useState } from 'react';
import { useHttpQueries } from '@/hooks';
import { salonActiveState, salonsState } from '@/components/state';
import { useRecoilState } from 'recoil';
import { useFormContext } from 'react-hook-form';
import useQueriesRevenueChart from './hooks/useQueriesRevenueChart';
import useQueriesRevenueChartEarlier from './hooks/useQueriesRevenueChartEarlier';
import { chartHelper } from '@/helpers';

const chartViewTypeOptions = [
    {
        label: "Doanh thu hoá đơn",
        value: null
    },
    {
        label: "Doanh dịch vụ",
        value: "SERVICE"
    },
    {
        label: "Doanh thu sản phẩm",
        value: "PRODUCT"
    }
]
const chartViewDateOptions = [
    {
        label: "Doanh thu 2 năm gần đây",
        value: 2
    },
    {
        label: "Doanh thu 3 năm gần đây",
        value: 3
    }
]

interface ItemChart {
    readonly date: string;
    readonly value: number;
    readonly type?: string;
    readonly children?: {
        readonly name: string;
        readonly value: number;
    }[];
}
interface ReportRevenueChartProps {
    readonly dateRange: [Dayjs, Dayjs];
}

enum ChartType {
    COLUMN = 0,
    LINE = 1,
}

export default function ReportRevenueChart(props: ReportRevenueChartProps) {
    const { dateRange } = props;
    const viewType = chartHelper.getViewTypeChart(dateRange);
    const [chartType, setChartType] = useState(ChartType.COLUMN);
    const [salonActive] = useRecoilState(salonActiveState);
    const [salons] = useRecoilState(salonsState);
    const { watch, setValue } = useFormContext();
    const numberYearQuery = watch('numberYear');

    const queriesCurrent = useQueriesRevenueChart(dateRange, watch('objectType'));
    const queriesYearEarlier = useQueriesRevenueChartEarlier(watch('objectType'), numberYearQuery);

    const queries = useMemo(() => {
        if(numberYearQuery) {
            return queriesYearEarlier;
        }
        return queriesCurrent;
    }, [queriesCurrent, queriesYearEarlier]);

    const revenueChartResponse = useHttpQueries(queries ?? []);
    const revenueAllSalon = useMemo(() => revenueChartResponse?.map((item) => item.data), [revenueChartResponse]);
    const isLoading = revenueChartResponse?.some((item) => item.isFetching);

    const revenueOverviewChartGroupByYear = useMemo(() => {
        const numberYear = numberYearQuery ?? 1;

        const dataEachYear = new Array(numberYear).fill(0)?.map((_, index) => {
            const startDate = dayjs().subtract(index, 'year').startOf('year');

            const revenueOfYear = revenueAllSalon.filter((item) => {
                return  dayjs(item?.[0]?.date).isSame(startDate, 'year');
            });

            const groupData = revenueOfYear?.[0]?.map((item, index) => {
                const totalRevenue = revenueOfYear?.reduce((acc, curr) => {
                    return acc + (curr?.[index]?.totalRevenue ?? 0);
                }, 0);

                const totalInvoice = revenueOfYear?.reduce((acc, curr) => {
                    return acc + (curr?.[index]?.totalInvoice ?? 0);
                }, 0);

                return {
                    date: item.date,
                    totalRevenue,
                    totalInvoice,
                }
            });

            return groupData;

        });

        return dataEachYear;
    }, [revenueChartResponse, revenueAllSalon]);

    const revenueOverviewChart = useMemo(() => {
        const groupData = revenueAllSalon?.[0]?.map((item, index) => {
                const totalRevenue = revenueAllSalon?.reduce((acc, curr) => {
                    return acc + (curr?.[index]?.totalRevenue ?? 0);
                }, 0);
    
                const totalInvoice = revenueAllSalon?.reduce((acc, curr) => {
                    return acc + (curr?.[index]?.totalInvoice ?? 0);
                }, 0);
    
                return {
                    date: item.date,
                    totalRevenue,
                    totalInvoice,
                }
            });

        return groupData;
    }, [revenueChartResponse, revenueAllSalon]);

    const dataLineChart = revenueOverviewChartGroupByYear?.map((dataEachYear) => {
        return dataEachYear?.map((item) => {
            return {
                date: numberYearQuery? `Tháng ${dayjs(item.date).month() + 1}`: chartHelper.getLabelDateByViewChart(viewType, item.date),
                value: item.totalRevenue,
                type: `năm ${dayjs(item.date).year()}`
            }
        })
    }).flat().filter(o=> !!o) as ItemChart[];

    const getChildrenColumnChart = (date: string) => {
        let children = revenueAllSalon?.map((revenueSalon) => {
            let nameItem = salons?.find((salon) => salon.id === revenueSalon?.[0]?.salonId)?.name

            if(salonActive) {
                nameItem = salonActive?.salonBranches?.find((branch) => branch.id === revenueSalon?.[0]?.salonBranchId)?.name
            }

            return {
                name: nameItem,
                value: revenueSalon?.find((revenue) => revenue.date === date)?.totalRevenue ?? 0
            }
        })

        children = [...children, {
            name: "Tổng doanh thu",
            value: children?.reduce((acc, curr) => acc + curr.value, 0) ?? 0
        }]

        return children;
    }

    const dataColumnChart = revenueOverviewChart?.map((item) => {

        return {
            date: chartHelper.getLabelDateByViewChart(viewType, item.date),
            value: item.totalRevenue,
            children: getChildrenColumnChart(item.date)
        }
    }) as ItemChart[] ?? [];

    const configColumnChart = {
        data: dataColumnChart,
        xField: 'date',
        legend: false,
        scrollbar: { x: { ratio: 0.5 } },
        interaction: {
            tooltip: {
            render: (_: React.ReactNode, { title }: { title: string}) => {
                const data = dataColumnChart?.find((item) => item.date === title);
                return `
                    <Block>
                    <p>${title}</p>
                    ${data?.children?.map((item) => {
                        return `
                            <p>${item.name}: ${item.value}</p>
                        `
                    }).join('')}
                    </Block>
                `
              },
            },
          },
        children: [
            {
                type: 'interval',
                yField: 'value',
                style: {
                    maxWidth: 40,
                    radiusTopLeft: 10,
                    radiusTopRight: 10,
                },
                color: '#2970FF',


            },
        ],
    };

    const items: MenuProps['items'] = [
        {
            key: 1,
            label: (
                <Text 
                    onClick={() => {
                        setChartType(ChartType.COLUMN);
                        setValue('numberYear', undefined);
                    }}
                >
                    Hiển thị theo biểu đồ cột
                </Text>
            ),
        },
        {
            key: 2,
            label: (
                <Text onClick={() => setChartType(ChartType.LINE)}>Hiển thị theo biểu đồ đường</Text>
            )
        },
    ];

    const lineChartConfig = {
        data: dataLineChart,
        xField: 'date',
        yField: 'value',
        colorField: 'type',
        scrollbar: { x: { ratio: 0.5 } },
        axis: {
            y: {
                labelFormatter: (v: unknown) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        scale: { color: { range: ['#F04438', '#17B26A', '#2970FF'] } },
        style: {
            lineWidth: 2,
        },
    };

    return (
        <Block overflow='hidden' maxWidth='100%'>
            <Spacer top={16}>
                <Card>
                    <FlexBox direction='row' justifyContent='space-between' width={"100%"}>
                        <FlexBox gap={24} >
                            <Block width={300} >
                                <SelectField size='large'
                                    showSearch
                                    defaultValue={null}
                                    options={chartViewTypeOptions}
                                    name='objectType'
                                    placeholder="Select Type"
                                />
                            </Block>
                            {chartType == ChartType.LINE && (
                                <Block width={300}>
                                    <SelectField size='large'
                                        showSearch
                                        allowClear
                                        options={chartViewDateOptions}
                                        name='numberYear'
                                        placeholder="Select Date"
                                    />
                                </Block>
                            )}
                        </FlexBox>
                        <Dropdown trigger={['click']} menu={{ items }}>
                            <AppButton border={0}>
                                <IconMore />
                            </AppButton>
                        </Dropdown>
                    </FlexBox>
                    {chartType == ChartType.COLUMN && (<DualAxes loading={isLoading} {...configColumnChart} />)}
                    {chartType == ChartType.LINE && (<Line loading={isLoading} {...lineChartConfig} />)}
                </Card>
            </Spacer>
        </Block>
    );
}
