import { TableColumnsType, Tag } from "antd";
import { TableCustom } from "@components/table";
import { globalModalState, salonActiveState, salonsState } from "@components/state";
import { useRecoilState } from "recoil";
import { OverviewRevenueDetailTable } from "./table-details/OverviewRevenueDetailTable";
import { GetRevenueOverviewResponse } from "@/api/report/revenue";
import { UseQueryResult } from "@tanstack/react-query";
import { getTotalRevenue } from "../RevenueReport";

export interface OverViewRevenueData {
    readonly id?: string;
    readonly title?: string;
    readonly objectType?: string;
    readonly value?: number | string;
}

interface OverViewRevenueTableProps {
    readonly revenueOverview: UseQueryResult<GetRevenueOverviewResponse, Error>[]
}

export function OverViewRevenueTable(props: OverViewRevenueTableProps) {
    const { revenueOverview } = props;
    const [salonActive] = useRecoilState(salonActiveState);
    const [salons] = useRecoilState(salonsState);
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);

    const revenueOverviewData = revenueOverview?.map((revenue) => revenue.data);

    const getChildrenColumnTable = (objectType: string) => {
        const isGettotalInvoice = objectType === "ALL_Quantity";
        const objectTypeGet = isGettotalInvoice ? "ALL" : objectType;

        let children = revenueOverviewData?.filter((revenue) => revenue?.objectType === objectTypeGet)
            .map((revenueSalon) => {
                let nameItem = salons?.find((salon) => salon.id === revenueSalon?.salonId)?.name;

            if(salonActive) {
                nameItem = salonActive?.salonBranches?.find((branch) => branch.id === revenueSalon?.salonBranchId)?.name
            }

            return {
                title: nameItem,
                value: (isGettotalInvoice ? revenueSalon?.totalInvoice : revenueSalon?.totalRevenue) ?? 0
            }
        })

        return children;
    }

    const showDetail = (objectType?: string, title?: string) => {
        const dataTable = getChildrenColumnTable(objectType!);

        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: title,
            width: 850,
            content: <OverviewRevenueDetailTable  dataTable={dataTable}/>
        });
    };


    const data: OverViewRevenueData[] = [
        {
            objectType: "ALL_Quantity",
            value: getTotalRevenue(revenueOverview, "ALL")?.totalInvoice ?? 0,
            title: "Tổng số hoá đơn",
        },
        {
            objectType: "ALL",
            value: getTotalRevenue(revenueOverview, "ALL")?.totalRevenue?.toLocaleString('en-US') ?? 0,
            title: "Tổng doanh thu",
        },
        {
            objectType: "BOOKING",
            value: getTotalRevenue(revenueOverview, "BOOKING")?.totalRevenue.toLocaleString('en-US') ?? 0,
            title: "Tổng doanh thu lịch hẹn",
        },
        {
            objectType: "SERVICE",
            value: getTotalRevenue(revenueOverview, "SERVICE")?.totalRevenue?.toLocaleString('en-US') ?? 0,
            title: "Tổng doanh thu dịch vụ",
        },
        {
            objectType: "PRODUCT",
            value: getTotalRevenue(revenueOverview, "PRODUCT")?.totalRevenue?.toLocaleString('en-US') ?? 0,
            title: "Tổng doanh thu sản phẩm",
        },
        {
            objectType: "PACKAGE",
            value: getTotalRevenue(revenueOverview, "PACKAGE")?.totalRevenue?.toLocaleString('en-US') ?? 0,
            title: "Tổng doanh thu gói dịch vụ",
        },
        {
            objectType: "CASH_CARD",
            value: getTotalRevenue(revenueOverview, "CASH_CARD")?.totalRevenue?.toLocaleString('en-US') ?? 0,
            title: "Tổng doanh thu thẻ tiền mặt",
        },
        {
            objectType: "DEBIT",
            value: getTotalRevenue(revenueOverview, "DEBIT")?.totalRevenue?.toLocaleString('en-US') ?? 0,
            title: "Tổng công nợ",
        },
        {
            objectType: "DISCOUNT",
            value: getTotalRevenue(revenueOverview, "DISCOUNT")?.totalRevenue?.toLocaleString('en-US') ?? 0,
            title: "Tổng giảm giá",
        },
        {
            objectType: "ALL",
            value: ((getTotalRevenue(revenueOverview, "ALL")?.totalRevenue ?? 0) - (getTotalRevenue(revenueOverview, "DEBIT")?.totalRevenue ?? 0))?.toLocaleString('en-US') ?? 0,
            title: "Tổng thanh toán",
        }
    ];

    const columns: TableColumnsType<OverViewRevenueData> = [
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Giá trị',
            dataIndex: 'value',
            key: 'value',
            width: 150
        },
        {
            title: "Liên kết",
            width: 150,
            render: (_, record) => {
                return (
                    <Tag 
                        onClick={() => { showDetail(record.objectType, record.title) }} 
                        style={{ borderRadius: 2, backgroundColor: 'white', color: '#8F9BB2', cursor: 'pointer' }}
                    >
                        Xem chi tiết
                    </Tag>
                   
                );
            },
        },
    ];

    return (
        <TableCustom
            scroll={{ x: 800 }}
            placeholderSearchTransCode='Search ID, Client, Phone, Email'
            showHeader={false}
            columns={columns}
            dataSource={data}
        />
    )
}
