
import { env } from '@env';
import { HttpRequestData, httpUtil, PaginationResponse } from '@/framework';

export interface GetRevenueOfCategoryTableRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: number;
        readonly startDate: string;
        readonly endDate: string;
        readonly objectType?: string;
    }
}

export interface GetRevenueOfCategoryTableResponse  {
    readonly totalRevenue?: number;
    readonly totalQuantity?: number;
    readonly categoryName?: string;
    readonly categoryId?: number;
    readonly salonId?: number;
    readonly salonBranchId?: number;
}

export const getRevenueOfCategoryTable = httpUtil.createHttpRequestMeta<
    GetRevenueOfCategoryTableRequest,
    PaginationResponse<GetRevenueOfCategoryTableResponse>
>({
    baseUrl: env.orgServer,
    path: '/revenues/categories',
    method: 'GET',
    authentication: 'bearer'
});
