import { useMemo } from 'react';
import { Dayjs } from 'dayjs';

import { Salon } from '../components/state';
import { HttpQueries } from '.';
import { GetCustomerGenderChartRequest, GetCustomerGenderChartResponse, requestCustomerGenderChartByAll, requestCustomerGenderChartBySalon, requestCustomerGenderChartBySalonBranch } from '../api/report/customer/chart/getCustomerGenderChart';

interface UseCustomerGenderChartRequestsProps {
    dateRange: Dayjs[];
    salons?: Salon[];
    selectedSalonBranchId?: number;
    salon?: Salon;
    viewType?: number;
}

interface UseCustomerGenderChartRequestsResult {
    customerGenderChartRequests: HttpQueries<GetCustomerGenderChartRequest, GetCustomerGenderChartResponse>[];
}

export function useCustomerGenderChartRequests({
    dateRange,
    salons,
    selectedSalonBranchId,
    salon,
    viewType,
}: UseCustomerGenderChartRequestsProps): UseCustomerGenderChartRequestsResult {

    const customerGenderChartRequests = useMemo(() => {
        if (salon == undefined && selectedSalonBranchId == undefined) {
            return requestCustomerGenderChartByAll(salons, dateRange, viewType);
        }
        if (salon != undefined && selectedSalonBranchId == undefined) {
            return requestCustomerGenderChartBySalon(dateRange, viewType, salon);
        }
        if (salon != undefined && selectedSalonBranchId != undefined) {
            return requestCustomerGenderChartBySalonBranch(dateRange, viewType, salon, selectedSalonBranchId);
        }
        return [];
    }, [dateRange, viewType, salon, selectedSalonBranchId, salons]);


    return {
        customerGenderChartRequests,
    };
}
