import { Dayjs } from 'dayjs';
import { env } from '@env';
import { HttpRequestData, httpUtil } from '@/framework';
import { ViewType } from '@shared/enum/chartEnum';
import { Salon } from '@components/state/accountInfoState';
import { HttpQueries } from '@hooks/useHttpQueries';

export interface GetTotalCustomerWalkInChartRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: string;
        readonly salonBranchId: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly viewType?: ViewType;
    }
}

export interface GetTotalCustomerWalkInChartResponse {
    overviewChartInfos: TotalCustomerWalkInChart[];
}
export interface TotalCustomerWalkInChart {
    date: Dayjs;
    customerCount: number;
    salonBranchId: number;
    salonId: number;
}

export const requestCustomerWalkInChartByAll = (
    salons: Salon[] | undefined,
    dateRange: Dayjs[],
    viewType?: ViewType,
) => {
    const requestsData: HttpQueries<GetTotalCustomerWalkInChartRequest, GetTotalCustomerWalkInChartResponse>[] = salons!.flatMap(salon =>
        salon!.salonBranches!.map(salonBranch => ({
            requestMeta: getCustomerWalkInChart,
            requestData: {
                query: {
                    salonId: salon.id,
                    salonBranchId: salonBranch.id,
                    startDate: dateRange[0].toISOString(),
                    endDate: dateRange[1].toISOString(),
                    viewType: viewType,
                },
            }
        }))
    );
    return requestsData;
};
export const requestCustomerWalkInChartBySalon = (
    dateRange: Dayjs[],
    viewType?: ViewType,
    salonSelect?: Salon
) => {
    const requestsData: HttpQueries<GetTotalCustomerWalkInChartRequest, GetTotalCustomerWalkInChartResponse>[] = salonSelect!.salonBranches!.map(salonBranch => ({
        requestMeta: getCustomerWalkInChart,
        requestData: {
            query: {
                salonId: salonSelect?.id,
                salonBranchId: salonBranch.id,
                startDate: dateRange[0].toISOString(),
                endDate: dateRange[1].toISOString(),
                viewType: viewType,
            },
        }
    }));
    return requestsData;
};
export const requestCustomerWalkInChartBySalonBranch = (
    dateRange: Dayjs[],
    viewType?: ViewType,
    salonSelect?: Salon,
    selectedSalonBranchId?: number
) => {
    const requestsData: HttpQueries<GetTotalCustomerWalkInChartRequest, GetTotalCustomerWalkInChartResponse>[] = [{
        requestMeta: getCustomerWalkInChart,
        requestData: {
            query: {
                salonId: salonSelect?.id,
                salonBranchId: selectedSalonBranchId,
                startDate: dateRange[0].toISOString(),
                endDate: dateRange[1].toISOString(),
                viewType: viewType,
            },
        }
    }]
    return requestsData;
};

export const getCustomerWalkInChart = httpUtil.createHttpRequestMeta<
    GetTotalCustomerWalkInChartRequest,
    GetTotalCustomerWalkInChartResponse
>({
    baseUrl: env.orgServer,
    path: '/customers/walk-in-chart',
    method: 'GET',
    authentication: 'bearer'
});
