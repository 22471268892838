import { env } from "../../config/env";
import { HttpRequestData, httpUtil } from "../../framework";

interface LoginRequest extends HttpRequestData {
    readonly email?: string;
    readonly password?: string;
}

export interface loginResponse {
    readonly accessToken?: string;
    readonly refreshToken?: string;
}

export const login = httpUtil.createHttpRequestMeta<
    LoginRequest,
    loginResponse
>({
    baseUrl: env.orgServer,
    path: '/accounts/login',
    method: 'POST',
});
