import { Avatar, Card, Spin } from 'antd';
import { Block, FlexBox } from '@components/layouts';
import { Dayjs } from 'dayjs';
import { IconGrowDown, IconGrowUp, IconRevenue } from '@components/icons';
import { IconTotalClient } from '@components/icons/IconTotalClient';
import { IconTotalNewClient } from '@components/icons/IconTotalNewClient';
import { IconTotalRetentionClient } from '@components/icons/IconTotalRetentionClient';
import { IconTotalWalkInClient } from '@components/icons/IconTotalWalkInClient';
import { Salon, salonActiveState } from '@components/state';
import { Text } from '@components/typo';
import { useMemo } from 'react';
import { useHttpQueries } from '@/hooks';
import { LoadingOutlined } from '@ant-design/icons';
import { useCustomerOverviewRequests } from '@hooks/useCustomerOverviewRequests';
import { dateHelper } from '@/helpers';
import { useRecoilState } from 'recoil';

interface ReportCustomerOverviewProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly salons?: Salon[] | undefined;
    readonly selectedSalonBranchId?: number | undefined;
}

export default function ReportCustomerOverview(props: ReportCustomerOverviewProps) {
    const { dateRange, salons, selectedSalonBranchId } = props;
    const [salonActive] = useRecoilState(salonActiveState);

    const viewType = 'monthly';
    const dateRangePrevious = dateHelper.getDateRangePrevious(dateRange[0], dateRange[1]);

    const {
        customerTotalOverviewRequests,
        customerNewOverviewRequests,
        customerReturnOverviewRequests,
        customerWalkInOverviewRequests,
        customerRevenueOverviewRequests,
        customerTotalOverviewPreviousRequests,
        customerNewOverviewPreviousRequests,
        customerReturnOverviewPreviousRequests,
        customerWalkInOverviewPreviousRequests,
        customerRevenueOverviewPreviousRequests,
    } = useCustomerOverviewRequests({
        dateRange,
        dateRangePrevious,
        salons,
        selectedSalonBranchId,
        salon: salonActive ?? undefined,
        viewType,
    });
    const queriesCustomerTotalOverview = useHttpQueries(customerTotalOverviewRequests);
    const queriesCustomerNewOverview = useHttpQueries(customerNewOverviewRequests);
    const queriesCustomerReturnOverview = useHttpQueries(customerReturnOverviewRequests);
    const queriesCustomerWalkInOverview = useHttpQueries(customerWalkInOverviewRequests);
    const queriesCustomerRevenueOverview = useHttpQueries(customerRevenueOverviewRequests);
    const queriesCustomerTotalOverviewPrevious = useHttpQueries(customerTotalOverviewPreviousRequests);
    const queriesCustomerNewOverviewPrevious = useHttpQueries(customerNewOverviewPreviousRequests);
    const queriesCustomerReturnOverviewPrevious = useHttpQueries(customerReturnOverviewPreviousRequests);
    const queriesCustomerWalkInOverviewPrevious = useHttpQueries(customerWalkInOverviewPreviousRequests);
    const queriesCustomerRevenueOverviewPrevious = useHttpQueries(customerRevenueOverviewPreviousRequests);

    const totalCustomer = useMemo(() => {
        const isLoading = queriesCustomerTotalOverview.some(query => query.isLoading);
        const total = queriesCustomerTotalOverview.reduce((acc, query) => acc + (query.data?.totalCustomer ?? 0), 0);
        return { isLoading, total };
    }, [queriesCustomerTotalOverview]);

    const totalCustomerNew = useMemo(() => {
        const isLoading = queriesCustomerNewOverview.some(query => query.isLoading);
        const total = queriesCustomerNewOverview.reduce((acc, query) => acc + (query.data?.totalCustomerNew ?? 0), 0);
        return { isLoading, total };
    }, [queriesCustomerNewOverview]);

    const totalCustomerReturn = useMemo(() => {
        const isLoading = queriesCustomerReturnOverview.some(query => query.isLoading);
        const total = queriesCustomerReturnOverview.reduce((acc, query) => acc + (query.data?.totalCustomerReturn ?? 0), 0);
        return { isLoading, total };
    }, [queriesCustomerReturnOverview]);

    const totalCustomerWalkIn = useMemo(() => {
        const isLoading = queriesCustomerWalkInOverview.some(query => query.isLoading);
        const total = queriesCustomerWalkInOverview.reduce((acc, query) => acc + (query.data?.totalCustomerWalkIn ?? 0), 0);
        return { isLoading, total };
    }, [queriesCustomerWalkInOverview]);

    const totalCustomerRevenue = useMemo(() => {
        const isLoading = queriesCustomerRevenueOverview.some(query => query.isLoading);
        const total = queriesCustomerRevenueOverview.reduce((acc, query) => acc + (query.data?.totalCustomerRevenue ?? 0), 0);
        return { isLoading, total };
    }, [queriesCustomerRevenueOverview]);

    const totalCustomerPrevious = useMemo(() => {
        const isLoading = queriesCustomerTotalOverviewPrevious.some(query => query.isLoading);
        const total = queriesCustomerTotalOverviewPrevious.reduce((acc, query) => acc + (query.data?.totalCustomer ?? 0), 0);
        return { isLoading, total };
    }, [queriesCustomerTotalOverviewPrevious]);

    const totalCustomerNewPrevious = useMemo(() => {
        const isLoading = queriesCustomerNewOverviewPrevious.some(query => query.isLoading);
        const total = queriesCustomerNewOverviewPrevious.reduce((acc, query) => acc + (query.data?.totalCustomerNew ?? 0), 0);
        return { isLoading, total };
    }, [queriesCustomerNewOverviewPrevious]);

    const totalCustomerReturnPrevious = useMemo(() => {
        const isLoading = queriesCustomerReturnOverviewPrevious.some(query => query.isLoading);
        const total = queriesCustomerReturnOverviewPrevious.reduce((acc, query) => acc + (query.data?.totalCustomerReturn ?? 0), 0);
        return { isLoading, total };
    }, [queriesCustomerReturnOverviewPrevious]);

    const totalCustomerWalkInPrevious = useMemo(() => {
        const isLoading = queriesCustomerWalkInOverviewPrevious.some(query => query.isLoading);
        const total = queriesCustomerWalkInOverviewPrevious.reduce((acc, query) => acc + (query.data?.totalCustomerWalkIn ?? 0), 0);
        return { isLoading, total };
    }, [queriesCustomerWalkInOverviewPrevious]);

    const totalCustomerRevenuePrevious = useMemo(() => {
        const isLoading = queriesCustomerRevenueOverviewPrevious.some(query => query.isLoading);
        const total = queriesCustomerRevenueOverviewPrevious.reduce((acc, query) => acc + (query.data?.totalCustomerRevenue ?? 0), 0);
        return { isLoading, total };
    }, [queriesCustomerRevenueOverviewPrevious]);

    const renderGrow = (current: number, previous: number) => {
        const grow = current - previous;
        const color = grow >= 0 ? 'green' : 'red';
        if (previous === 0) {
            return <Text color={color}>( - )</Text>;
        }
        const percent = (grow / previous) * 100;
        return (
            <FlexBox alignItems='center'>
                <Text fontWeight={600} color={color}>({percent.toFixed(2)}%)</Text>
                {grow > 0 ? <IconGrowUp /> : <IconGrowDown />}
            </FlexBox>
        );
    };

    const customerOverviews = useMemo(() => [
        {
            label: "Tổng số khách hàng",
            value: totalCustomer.total,
            preValue: totalCustomerPrevious.total,
            color: '#EFF4FF',
            icon: <IconTotalClient />,
            isLoading: totalCustomer.isLoading,
            isLoadingPrevious: totalCustomerPrevious.isLoading
        },
        {
            label: "Khách hàng mới",
            value: totalCustomerNew.total,
            preValue: totalCustomerNewPrevious.total,
            color: '#FEF3F2',
            icon: <IconTotalNewClient />,
            isLoading: totalCustomerNew.isLoading,
            isLoadingPrevious: totalCustomerNewPrevious.isLoading
        },
        {
            label: "Khách hàng quay lại",
            value: totalCustomerReturn.total,
            preValue: totalCustomerReturnPrevious.total,
            color: '#ECFDF3',
            icon: <IconTotalRetentionClient />,
            isLoading: totalCustomerReturn.isLoading,
            isLoadingPrevious: totalCustomerReturnPrevious.isLoading
        },
        {
            label: "Khách hàng vãng lai",
            value: totalCustomerWalkIn.total,
            preValue: totalCustomerWalkInPrevious.total,
            color: '#FFFAEB',
            icon: <IconTotalWalkInClient />,
            isLoading: totalCustomerWalkIn.isLoading,
            isLoadingPrevious: totalCustomerWalkInPrevious.isLoading
        },
        {
            label: "Tổng doanh thu",
            value: totalCustomerRevenue.total,
            preValue: totalCustomerRevenuePrevious.total,
            color: '#EFF4FF',
            icon: <IconRevenue />,
            isLoading: totalCustomerRevenue.isLoading,
            isLoadingPrevious: totalCustomerRevenuePrevious.isLoading
        }
    ], [
        totalCustomer,
        totalCustomerNew,
        totalCustomerReturn,
        totalCustomerWalkIn,
        totalCustomerRevenue,
        totalCustomerPrevious,
        totalCustomerNewPrevious,
        totalCustomerReturnPrevious,
        totalCustomerWalkInPrevious,
        totalCustomerRevenuePrevious
    ]);

    return (
        <FlexBox justifyContent='space-between'>
            {customerOverviews.map((o, i) => (
                <Card key={i} className='card-overview-customer'>
                    <FlexBox gap={8} alignItems='center' flex='none'>
                        <Avatar
                            style={{ backgroundColor: o.color }}
                            size={42}
                            icon={o.icon}
                        />
                        <FlexBox direction='column'>
                            <Text maxLines={1} maxWidth={200} fontWeight={600}>{o.label}</Text>
                            {!o.isLoading && !o.isLoadingPrevious ? (
                                <FlexBox alignItems='center' gap={4} flexWrap='wrap'>
                                    <Block>
                                        <Text color='#101828' fontWeight='700' fontSize={18}>
                                            {o.value}
                                        </Text>
                                    </Block>
                                    {renderGrow(o.value, o.preValue)}
                                </FlexBox>
                            ) : (
                                <Spin indicator={<LoadingOutlined spin />} />
                            )}
                        </FlexBox>
                    </FlexBox>
                </Card>
            ))}
        </FlexBox>
    );
}
