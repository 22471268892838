import { TableColumnsType } from "antd";
import { TableCustom } from "@components/table";

export interface WareHoseDetailData {
    readonly id?: string;
    readonly name?: string;
    readonly warehouseValue?: string;
    readonly InWarehouse?: string;
    readonly OutWarehouse?: string;
    readonly inventory?: string;
}

export function WareHoseDetailTable() {

    const data: WareHoseDetailData[] = [
        {
            id: '1',
            warehouseValue: "250",
            name: "Chi nhánh 1",
            InWarehouse: "8",
            OutWarehouse: "10",
            inventory: "20",
        },
        {
            id: '2',
            warehouseValue: "615000",
            name: "Chi nhánh 2",
            InWarehouse: "8",
            OutWarehouse: "10",
            inventory: "20",
        },
        {
            id: '3',
            warehouseValue: "432000",
            name: "Chi nhánh 4",
            InWarehouse: "8",
            OutWarehouse: "10",
            inventory: "20",
        },
        {
            id: '5',
            warehouseValue: "45000",
            name: "Chi nhánh 6",
            InWarehouse: "8",
            OutWarehouse: "10",
            inventory: "20",
        },
        {
            id: '6',
            warehouseValue: "345000",
            name: "Chi nhánh 8",
            InWarehouse: "8",
            OutWarehouse: "10",
            inventory: "20",
        },
        {
            id: '7',
            warehouseValue: "550000",
            name: "Chi nhánh 10",
            InWarehouse: "8",
            OutWarehouse: "10",
            inventory: "20",
        },
        {
            id: '8',
            warehouseValue: "4450000",
            name: "Chi nhánh 34",
            InWarehouse: "8",
            OutWarehouse: "10",
            inventory: "20",
        }
    ];

    const columns: TableColumnsType<WareHoseDetailData> = [
        {
            title: 'Chi nhánh',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Nhập kho',
            dataIndex: 'InWarehouse',
            key: 'InWarehouse',
        },
        {
            title: 'Xuất kho',
            dataIndex: 'OutWarehouse',
            key: 'OutWarehouse',
        },
        {
            title: 'Tồn kho',
            dataIndex: 'inventory',
            key: 'inventory',
        },
        {
            title: 'Giá trị kho hàng',
            dataIndex: 'warehouseValue',
            key: 'warehouseValue',
            width: 150
        }
    ];

    return (
        <div style={{ paddingTop: 20 }}>
            <TableCustom
                showHeader={false}
                columns={columns}
                dataSource={data}
                showFooter={false}
            />
        </div>
    )
}
