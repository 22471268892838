import { useMemo } from 'react';
import { Dayjs } from 'dayjs';
import {
    requestCustomerOverviewByAll,
    requestCustomerOverviewBySalon,
    requestCustomerOverviewBySalonBranch,
    GetCustomerTotalOverviewRequest,
    GetCustomerTotalOverviewResponse,
} from '../api/report/customer/overview/getCustomerTotalOverview';
import {
    requestCustomerNewOverviewByAll,
    requestCustomerNewOverviewBySalon,
    requestCustomerNewOverviewBySalonBranch,
    GetCustomerNewOverviewRequest,
    GetCustomerNewOverviewResponse,
} from '../api/report/customer/overview/getCustomerNewOverview';
import {
    requestCustomerReturnOverviewByAll,
    requestCustomerReturnOverviewBySalon,
    requestCustomerReturnOverviewBySalonBranch,
    GetCustomerReturnOverviewRequest,
    GetCustomerReturnOverviewResponse,
} from '../api/report/customer/overview/getCustomerReturnOverview';
import {
    requestCustomerWalkInOverviewByAll,
    requestCustomerWalkInOverviewBySalon,
    requestCustomerWalkInOverviewBySalonBranch,
    GetCustomerWalkInOverviewRequest,
    GetCustomerWalkInOverviewResponse,
} from '../api/report/customer/overview/getCustomerWalkInOverview';
import {
    requestCustomerRevenueOverviewByAll,
    requestCustomerRevenueOverviewBySalon,
    requestCustomerRevenueOverviewBySalonBranch,
    GetCustomerRevenueOverviewRequest,
    GetCustomerRevenueOverviewResponse,
} from '../api/report/customer/overview/getCustomerRevenueOverview';
import { Salon } from '../components/state';
import { HttpQueries } from '../hooks';

interface UseCustomerOverviewRequestsProps {
    dateRange: Dayjs[];
    dateRangePrevious: Dayjs[];
    salons?: Salon[];
    selectedSalonBranchId?: number;
    salon?: Salon;
    viewType: string;
}

interface UseCustomerOverviewRequestsResult {
    customerTotalOverviewRequests: HttpQueries<GetCustomerTotalOverviewRequest, GetCustomerTotalOverviewResponse>[];
    customerTotalOverviewPreviousRequests: HttpQueries<GetCustomerTotalOverviewRequest, GetCustomerTotalOverviewResponse>[];
    customerNewOverviewRequests: HttpQueries<GetCustomerNewOverviewRequest, GetCustomerNewOverviewResponse>[];
    customerNewOverviewPreviousRequests: HttpQueries<GetCustomerNewOverviewRequest, GetCustomerNewOverviewResponse>[];
    customerReturnOverviewRequests: HttpQueries<GetCustomerReturnOverviewRequest, GetCustomerReturnOverviewResponse>[];
    customerReturnOverviewPreviousRequests: HttpQueries<GetCustomerReturnOverviewRequest, GetCustomerReturnOverviewResponse>[];
    customerWalkInOverviewRequests: HttpQueries<GetCustomerWalkInOverviewRequest, GetCustomerWalkInOverviewResponse>[];
    customerWalkInOverviewPreviousRequests: HttpQueries<GetCustomerWalkInOverviewRequest, GetCustomerWalkInOverviewResponse>[];
    customerRevenueOverviewRequests: HttpQueries<GetCustomerRevenueOverviewRequest, GetCustomerRevenueOverviewResponse>[];
    customerRevenueOverviewPreviousRequests: HttpQueries<GetCustomerRevenueOverviewRequest, GetCustomerRevenueOverviewResponse>[];
}

export function useCustomerOverviewRequests({
    dateRange,
    dateRangePrevious,
    salons,
    selectedSalonBranchId,
    salon,
    viewType,
}: UseCustomerOverviewRequestsProps): UseCustomerOverviewRequestsResult {
    const customerTotalOverviewRequests = useMemo(() => {
        if (salon == undefined && selectedSalonBranchId == undefined) {
            return requestCustomerOverviewByAll(salons, dateRange, viewType);
        }
        if (salon != undefined && selectedSalonBranchId == undefined) {
            return requestCustomerOverviewBySalon(dateRange, viewType, salon);
        }
        if (salon != undefined && selectedSalonBranchId != undefined) {
            return requestCustomerOverviewBySalonBranch(dateRange, viewType, salon, selectedSalonBranchId);
        }
        return [];
    }, [dateRange, viewType, salon, selectedSalonBranchId, salons]);

    const customerTotalOverviewPreviousRequests = useMemo(() => {
        if (salon == undefined && selectedSalonBranchId == undefined) {
            return requestCustomerOverviewByAll(salons, dateRangePrevious, viewType);
        }
        if (salon != undefined && selectedSalonBranchId == undefined) {
            return requestCustomerOverviewBySalon(dateRangePrevious, viewType, salon);
        }
        if (salon != undefined && selectedSalonBranchId != undefined) {
            return requestCustomerOverviewBySalonBranch(dateRangePrevious, viewType, salon, selectedSalonBranchId);
        }
        return [];
    }, [dateRangePrevious, viewType, salon, selectedSalonBranchId, salons]);

    const customerNewOverviewRequests = useMemo(() => {
        if (salon == undefined && selectedSalonBranchId == undefined) {
            return requestCustomerNewOverviewByAll(salons, dateRange, viewType);
        }
        if (salon != undefined && selectedSalonBranchId == undefined) {
            return requestCustomerNewOverviewBySalon(dateRange, viewType, salon);
        }
        if (salon != undefined && selectedSalonBranchId != undefined) {
            return requestCustomerNewOverviewBySalonBranch(dateRange, viewType, salon, selectedSalonBranchId);
        }
        return [];
    }, [dateRange, viewType, salon, selectedSalonBranchId, salons]);

    const customerNewOverviewPreviousRequests = useMemo(() => {
        if (salon == undefined && selectedSalonBranchId == undefined) {
            return requestCustomerNewOverviewByAll(salons, dateRangePrevious, viewType);
        }
        if (salon != undefined && selectedSalonBranchId == undefined) {
            return requestCustomerNewOverviewBySalon(dateRangePrevious, viewType, salon);
        }
        if (salon != undefined && selectedSalonBranchId != undefined) {
            return requestCustomerNewOverviewBySalonBranch(dateRangePrevious, viewType, salon, selectedSalonBranchId);
        }
        return [];
    }, [dateRangePrevious, viewType, salon, selectedSalonBranchId, salons]);

    const customerReturnOverviewRequests = useMemo(() => {
        if (salon == undefined && selectedSalonBranchId == undefined) {
            return requestCustomerReturnOverviewByAll(salons, dateRange, viewType);
        }
        if (salon != undefined && selectedSalonBranchId == undefined) {
            return requestCustomerReturnOverviewBySalon(dateRange, viewType, salon);
        }
        if (salon != undefined && selectedSalonBranchId != undefined) {
            return requestCustomerReturnOverviewBySalonBranch(dateRange, viewType, salon, selectedSalonBranchId);
        }
        return [];
    }, [dateRange, viewType, salon, selectedSalonBranchId, salons]);

    const customerReturnOverviewPreviousRequests = useMemo(() => {
        if (salon == undefined && selectedSalonBranchId == undefined) {
            return requestCustomerReturnOverviewByAll(salons, dateRangePrevious, viewType);
        }
        if (salon != undefined && selectedSalonBranchId == undefined) {
            return requestCustomerReturnOverviewBySalon(dateRangePrevious, viewType, salon);
        }
        if (salon != undefined && selectedSalonBranchId != undefined) {
            return requestCustomerReturnOverviewBySalonBranch(dateRangePrevious, viewType, salon, selectedSalonBranchId);
        }
        return [];
    }, [dateRangePrevious, viewType, salon, selectedSalonBranchId, salons]);

    const customerWalkInOverviewRequests = useMemo(() => {
        if (salon == undefined && selectedSalonBranchId == undefined) {
            return requestCustomerWalkInOverviewByAll(salons, dateRange, viewType);
        }
        if (salon != undefined && selectedSalonBranchId == undefined) {
            return requestCustomerWalkInOverviewBySalon(dateRange, viewType, salon);
        }
        if (salon != undefined && selectedSalonBranchId != undefined) {
            return requestCustomerWalkInOverviewBySalonBranch(dateRange, viewType, salon, selectedSalonBranchId);
        }
        return [];
    }, [dateRange, viewType, salon, selectedSalonBranchId, salons]);

    const customerWalkInOverviewPreviousRequests = useMemo(() => {
        if (salon == undefined && selectedSalonBranchId == undefined) {
            return requestCustomerWalkInOverviewByAll(salons, dateRangePrevious, viewType);
        }
        if (salon != undefined && selectedSalonBranchId == undefined) {
            return requestCustomerWalkInOverviewBySalon(dateRangePrevious, viewType, salon);
        }
        if (salon != undefined && selectedSalonBranchId != undefined) {
            return requestCustomerWalkInOverviewBySalonBranch(dateRangePrevious, viewType, salon, selectedSalonBranchId);
        }
        return [];
    }, [dateRangePrevious, viewType, salon, selectedSalonBranchId, salons]);

    const customerRevenueOverviewRequests = useMemo(() => {
        if (salon == undefined && selectedSalonBranchId == undefined) {
            return requestCustomerRevenueOverviewByAll(salons, dateRange, viewType);
        }
        if (salon != undefined && selectedSalonBranchId == undefined) {
            return requestCustomerRevenueOverviewBySalon(dateRange, viewType, salon);
        }
        if (salon != undefined && selectedSalonBranchId != undefined) {
            return requestCustomerRevenueOverviewBySalonBranch(dateRange, viewType, salon, selectedSalonBranchId);
        }
        return [];
    }, [dateRange, viewType, salon, selectedSalonBranchId, salons]);

    const customerRevenueOverviewPreviousRequests = useMemo(() => {
        if (salon == undefined && selectedSalonBranchId == undefined) {
            return requestCustomerRevenueOverviewByAll(salons, dateRangePrevious, viewType);
        }
        if (salon != undefined && selectedSalonBranchId == undefined) {
            return requestCustomerRevenueOverviewBySalon(dateRangePrevious, viewType, salon);
        }
        if (salon != undefined && selectedSalonBranchId != undefined) {
            return requestCustomerRevenueOverviewBySalonBranch(dateRangePrevious, viewType, salon, selectedSalonBranchId);
        }
        return [];
    }, [dateRangePrevious, viewType, salon, selectedSalonBranchId, salons]);

    return {
        customerTotalOverviewRequests,
        customerTotalOverviewPreviousRequests,
        customerNewOverviewRequests,
        customerNewOverviewPreviousRequests,
        customerReturnOverviewRequests,
        customerReturnOverviewPreviousRequests,
        customerWalkInOverviewRequests,
        customerWalkInOverviewPreviousRequests,
        customerRevenueOverviewRequests,
        customerRevenueOverviewPreviousRequests,
    };
}
