import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { Line } from '@ant-design/charts';
import { Salon } from '@components/state';
import { useCustomerMainChartRequests } from '@hooks/useCustomerMainChartRequests';
import { useHttpQueries } from '@/hooks';
import { chartHelper } from '@/helpers';
import { ViewType } from '@shared/enum/chartEnum';

interface ReportCustomerLineChartProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly salons?: Salon[] | undefined;
    readonly selectedSalonBranchId?: number | undefined;
    readonly salon?: Salon;
}

interface LineDataChart {
    date?: string;
    type?: string;
    value?: number;
}

export interface CustomerDataChart {
    date: Dayjs;
    customerNewCount?: number;
    customerReturnCount?: number;
    customerWalkInCount?: number;
}

function groupDataByDayAndSumCustomerCount(data: CustomerDataChart[]): CustomerDataChart[] {
    const groupedData = new Map<string, CustomerDataChart>();

    data.forEach(item => {
        const dayKey = dayjs(item.date).format('YYYY-MM-DD HH:mm');

        if (groupedData.has(dayKey)) {
            const existingItem = groupedData.get(dayKey)!;
            existingItem.customerNewCount = (existingItem.customerNewCount ?? 0) + (item.customerNewCount ?? 0);
            existingItem.customerReturnCount = (existingItem.customerReturnCount ?? 0) + (item.customerReturnCount ?? 0);
            existingItem.customerWalkInCount = (existingItem.customerWalkInCount ?? 0) + (item.customerWalkInCount ?? 0);
        } else {
            groupedData.set(dayKey, {
                date: item.date,
                customerNewCount: item.customerNewCount ?? 0,
                customerReturnCount: item.customerReturnCount ?? 0,
                customerWalkInCount: item.customerWalkInCount ?? 0,
            });
        }
    });

    return Array.from(groupedData.values());
}

export default function ReportCustomerLineChart(props: ReportCustomerLineChartProps) {
    const { dateRange, salons, selectedSalonBranchId, salon } = props;
    const viewType = chartHelper.getViewTypeChart(dateRange);

    const {
        customerNewChartRequests,
        customerReturnChartRequests,
        customerWalkInChartRequests
    } = useCustomerMainChartRequests({
        dateRange,
        salons,
        selectedSalonBranchId,
        salon,
        viewType,
    });

    const queriesCustomerNewChart = useHttpQueries(customerNewChartRequests);
    const queriesCustomerReturnChart = useHttpQueries(customerReturnChartRequests);
    const queriesCustomerWalkInChart = useHttpQueries(customerWalkInChartRequests);

    const totalCustomerNew = useMemo(() => {
        const isLoading = queriesCustomerNewChart.some(query => query.isLoading);
        const CustomerNewCharts = queriesCustomerNewChart.flatMap(o => o.data?.overviewChartInfos ?? []);
        return { isLoading, CustomerNewCharts };
    }, [queriesCustomerNewChart]);

    const totalCustomerReturn = useMemo(() => {
        const isLoading = queriesCustomerReturnChart.some(query => query.isLoading);
        const CustomerReturnCharts = queriesCustomerReturnChart.flatMap(o => o.data?.overviewChartInfos ?? []);
        return { isLoading, CustomerReturnCharts };
    }, [queriesCustomerReturnChart]);

    const totalCustomerWalkIn = useMemo(() => {
        const isLoading = queriesCustomerWalkInChart.some(query => query.isLoading);
        const CustomerWalkInCharts = queriesCustomerWalkInChart.flatMap(o => o.data?.overviewChartInfos ?? []);
        return { isLoading, CustomerWalkInCharts };
    }, [queriesCustomerWalkInChart]);

    const customerNewDataCharts: CustomerDataChart[] = totalCustomerNew.CustomerNewCharts.map(o => (
        {
            date: o.date,
            customerNewCount: o.customerCount
        }
    ));
    const customerReturnDataCharts: CustomerDataChart[] = totalCustomerReturn.CustomerReturnCharts.map(o => (
        {
            date: o.date,
            customerReturnCount: o.customerCount
        }
    ));
    const customerWalkInDataCharts: CustomerDataChart[] = totalCustomerWalkIn.CustomerWalkInCharts.map(o => (
        {
            date: o.date,
            customerWalkInCount: o.customerCount
        }
    ));

    const allDataCharts = [...customerNewDataCharts, ...customerReturnDataCharts, ...customerWalkInDataCharts];

    const groupedData = groupDataByDayAndSumCustomerCount(allDataCharts);

    const dataChart: LineDataChart[] = groupedData.map(o => ([
        {
            date: chartHelper.getLabelDateByViewChart(viewType, o?.date.toString()),
            type: 'Khách mới',
            value: o?.customerNewCount
        },
        {
            date: chartHelper.getLabelDateByViewChart(viewType, o?.date.toString()),
            type: 'Khách quay lại',
            value: o?.customerReturnCount
        },
        {
            date: chartHelper.getLabelDateByViewChart(viewType, o?.date.toString()),
            type: 'Khách vãng lai',
            value: o?.customerWalkInCount
        },
    ])).flat() ?? [];

    const lineChartConfig = {
        data: dataChart,
        xField: 'date',
        yField: 'value',
        colorField: 'type',
        axis: {
            y: {
                labelFormatter: (v: unknown) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        scale: { color: { range: ['#F04438', '#17B26A', '#2970FF'] } },
        style: {
            lineWidth: 2,
        },
    };

    return (
        viewType != ViewType.Hour &&
        <Line {...lineChartConfig} loading={totalCustomerWalkIn.isLoading} />
    );
}
