
import { env } from '@env';
import { HttpRequestData, httpUtil, PaginationResponse } from '@/framework';

export interface GetRevenueOfObjectTypeDetailRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: number;
        readonly objectId: number;
        readonly startDate: string;
        readonly endDate: string;
        readonly objectType?: "SERVICE" | "PRODUCT";
    }
}

export interface GetRevenueOfObjectTypeDetailResponse  {
    readonly totalRevenue?: number;
    readonly totalQuantity?: number;
    readonly salonBranchId?: number;
}

export const getRevenueOfObjectTypeDetail = httpUtil.createHttpRequestMeta<
    GetRevenueOfObjectTypeDetailRequest,
    PaginationResponse<GetRevenueOfObjectTypeDetailResponse>
>({
    baseUrl: env.orgServer,
    path: '/revenues/object-type-detail',
    method: 'GET',
    authentication: 'bearer'
});
