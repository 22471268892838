import { TableColumnsType, Tag } from "antd";
import { TableCustom } from "@components/table";
import { globalModalState } from "@components/state";
import { useRecoilState } from "recoil";
import { WareHoseDetailTable } from "./WareHoseDetailTable";

export interface WareHoseData {
    readonly id?: string;
    readonly name?: string;
    readonly warehouseValue?: string;
    readonly InWarehouse?: string;
    readonly OutWarehouse?: string;
    readonly inventory?: string;
}

export function WareHoseTable() {

    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);

    const showDetail = (title: string) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: title,
            width: 850,
            content: <WareHoseDetailTable />
        });
    };

    const data: WareHoseData[] = [
        {
            id: '1',
            warehouseValue: "250",
            name: "Product 1",
            InWarehouse: "8",
            OutWarehouse: "10",
            inventory: "20",
        },
        {
            id: '2',
            warehouseValue: "615000",
            name: "Product 2",
            InWarehouse: "8",
            OutWarehouse: "10",
            inventory: "20",
        },
        {
            id: '3',
            warehouseValue: "432000",
            name: "Product 4",
            InWarehouse: "8",
            OutWarehouse: "10",
            inventory: "20",
        },
        {
            id: '5',
            warehouseValue: "45000",
            name: "Product 6",
            InWarehouse: "8",
            OutWarehouse: "10",
            inventory: "20",
        },
        {
            id: '6',
            warehouseValue: "345000",
            name: "Product 8",
            InWarehouse: "8",
            OutWarehouse: "10",
            inventory: "20",
        },
        {
            id: '7',
            warehouseValue: "550000",
            name: "Product 10",
            InWarehouse: "8",
            OutWarehouse: "10",
            inventory: "20",
        },
        {
            id: '8',
            warehouseValue: "4450000",
            name: "Product 34",
            InWarehouse: "8",
            OutWarehouse: "10",
            inventory: "20",
        },
        {
            id: '9',
            warehouseValue: "550000",
            name: "Product 99",
            InWarehouse: "8",
            OutWarehouse: "10",
            inventory: "20",
        },
        {
            id: '10',
            warehouseValue: "150000",
            name: "Product 16",
            InWarehouse: "8",
            OutWarehouse: "10",
            inventory: "20",
        },
        {
            id: '11',
            warehouseValue: "89850000",
            name: "Product 34",
            InWarehouse: "8",
            OutWarehouse: "10",
            inventory: "20",
        }
    ];

    const columns: TableColumnsType<WareHoseData> = [
        {
            title: 'Sản phẩm',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Nhập kho',
            dataIndex: 'InWarehouse',
            key: 'InWarehouse',
            width: 250
        },
        {
            title: 'Xuất kho',
            dataIndex: 'OutWarehouse',
            key: 'OutWarehouse',
            width: 150
        },
        {
            title: 'Tồn kho',
            dataIndex: 'inventory',
            key: 'inventory',
            width: 150
        },
        {
            title: 'Giá trị kho hàng',
            dataIndex: 'warehouseValue',
            key: 'warehouseValue',
            width: 150
        },
        {
            title: "Liên kết",
            width: 150,
            render: (_, record) => {
                return (
                    <a>
                        <Tag onClick={() => { showDetail(record.name ?? '') }} style={{ borderRadius: 2 }} color="default">Xem chi tiết</Tag>
                    </a>
                );
            },
        },
    ];

    return (
        <TableCustom
            scroll={{ x: 800 }}
            titleTableTransCode="Sản phẩm"
            onSearch={() => { }}
            placeholderSearchTransCode="Tìm kiếm sản phẩm"
            showHeader={true}
            columns={columns}
            dataSource={data}
        />
    )
}
