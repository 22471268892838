import { TableColumnsType, Tag } from "antd";
import { TableCustom } from "@components/table";
import { globalModalState } from "@components/state";
import { useRecoilState } from "recoil";
import { StatusAppointmentDetailTable } from "./StatusAppointmentDetailTable";

export interface StatusAppointmentData {
    readonly id?: string;
    readonly title?: string;
    readonly value?: string;

}

export function StatusAppointmentTable() {
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    // const resetGlobalModal = useResetRecoilState(globalModalState);

    const showDetail = (title: string) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: title,
            width: 850,
            content: <StatusAppointmentDetailTable />
        });
    };

    const data: StatusAppointmentData[] = [
        {
            id: '1',
            value: "250",
            title: "Mới",
        },
        {
            id: '2',
            value: "615000",
            title: "Xác nhận",
        },
        {
            id: '3',
            value: "432000",
            title: "Check-In",
        },
        {
            id: '5',
            value: "45000",
            title: "Check-Out",
        },
        {
            id: '6',
            value: "345000",
            title: "Hoàn thành",
        },
        {
            id: '7',
            value: "550000",
            title: "Không đến",
        },
        {
            id: '8',
            value: "550000",
            title: "Huỷ",
        }
    ];

    const columns: TableColumnsType<StatusAppointmentData> = [
        {
            title: 'Trạng thái',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Số lượng',
            dataIndex: 'value',
            key: 'value',
            width: 150
        },
        {
            title: "Liên kết",
            width: 150,
            render: (_, record) => {
                return (
                    // <ColumnActions
                    //     viewDetailUrl=""
                    //     onClickEdit={() => {
                    //     }}
                    //     onClickDelete={() => {
                    //     }}

                    // />
                    <a>
                        <Tag onClick={() => { showDetail(record.title ?? '') }} style={{ borderRadius: 2 }} color="default">Xem chi tiết</Tag>
                    </a>
                );
            },
        },
    ];

    return (
        <TableCustom
            scroll={{ x: 800 }}
            placeholderSearchTransCode='Search ID, Client, Phone, Email'
            showHeader={false}
            columns={columns}
            dataSource={data}
        />
    )
}
