import { Card } from 'antd';
import { Dayjs } from 'dayjs';
import { Block } from '@components/layouts';
import ReportCustomerLineChart from './ReportCustomerLineChart';
import { Text } from '@components/typo'
import { Salon, salonActiveState } from '@components/state';
import { useRecoilState } from 'recoil';

interface ReportCustomerMainChartProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly salons?: Salon[] | undefined;
    readonly selectedSalonBranchId?: number | undefined;
}
function ReportCustomerMainChart(props: ReportCustomerMainChartProps) {
    const [salonActive] = useRecoilState(salonActiveState);

    return (
        <Block overflow='hidden' maxWidth='100%'>
            <Card>
                <Text fontSize={18} fontWeight={600}>Tổng khách hàng</Text>
                <ReportCustomerLineChart salon={salonActive!} selectedSalonBranchId={props.selectedSalonBranchId} dateRange={props.dateRange} salons={props?.salons} />
            </Card>
        </Block>
    );
}

export default ReportCustomerMainChart;