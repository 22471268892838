
import { IconProps } from '../../Types';

export const IconTrash = (props: IconProps) => {
    return (
        <svg
            className={props.className}
            onClick={props.onClick}
            width='16'
            height='16'
            viewBox='0 0 16 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.59103 0.875016H9.4089C9.58923 0.874901 9.74633 0.874801 9.89468 0.89849C10.4808 0.992078 10.9879 1.35762 11.262 1.88403C11.3314 2.01727 11.381 2.16634 11.4379 2.33745L11.5309 2.61654C11.5467 2.66378 11.5512 2.67715 11.555 2.68768C11.7009 3.09111 12.0793 3.36383 12.5082 3.3747C12.5195 3.37498 12.5333 3.37503 12.5833 3.37503H15.0833C15.4285 3.37503 15.7083 3.65485 15.7083 4.00003C15.7083 4.34521 15.4285 4.62503 15.0833 4.62503H0.916565C0.571387 4.62503 0.291565 4.34521 0.291565 4.00003C0.291565 3.65485 0.571387 3.37503 0.916565 3.37503H3.41663C3.46667 3.37503 3.48048 3.37498 3.49176 3.3747C3.92063 3.36383 4.29899 3.09113 4.44492 2.6877C4.44875 2.67709 4.45317 2.66401 4.46899 2.61654L4.562 2.33747C4.61891 2.16637 4.6685 2.01728 4.73789 1.88403C5.01201 1.35762 5.51917 0.992078 6.10525 0.89849C6.2536 0.874801 6.41071 0.874901 6.59103 0.875016ZM5.50669 3.37503C5.54962 3.29085 5.58765 3.20337 5.62038 3.1129C5.63031 3.08543 5.64006 3.05619 5.65258 3.01861L5.73575 2.7691C5.81173 2.54118 5.82922 2.49469 5.84657 2.46137C5.93795 2.2859 6.107 2.16405 6.30236 2.13285C6.33947 2.12693 6.38909 2.12503 6.62935 2.12503H9.37058C9.61085 2.12503 9.66047 2.12693 9.69757 2.13285C9.89293 2.16405 10.062 2.2859 10.1534 2.46137C10.1707 2.49469 10.1882 2.54117 10.2642 2.7691L10.3473 3.01846L10.3796 3.11292C10.4123 3.20338 10.4503 3.29085 10.4932 3.37503H5.50669Z'
                fill={props.color}
            />
            <path
                d='M2.92914 6.04179C2.90618 5.69738 2.60836 5.43679 2.26395 5.45975C1.91954 5.48271 1.65895 5.78053 1.68191 6.12494L2.06812 11.9181C2.13936 12.987 2.19691 13.8505 2.33189 14.528C2.47222 15.2324 2.7109 15.8208 3.2039 16.2821C3.6969 16.7433 4.29985 16.9423 5.01206 17.0355C5.69708 17.1251 6.56243 17.125 7.63373 17.125H8.36615C9.43745 17.125 10.3029 17.1251 10.9879 17.0355C11.7001 16.9423 12.303 16.7433 12.796 16.2821C13.289 15.8208 13.5277 15.2324 13.668 14.528C13.803 13.8505 13.8606 12.987 13.9318 11.9181L14.318 6.12494C14.341 5.78053 14.0804 5.48271 13.736 5.45975C13.3916 5.43679 13.0938 5.69738 13.0708 6.04179L12.6875 11.7911C12.6126 12.9143 12.5593 13.6958 12.4421 14.2838C12.3285 14.8542 12.1699 15.1561 11.9421 15.3692C11.7142 15.5824 11.4024 15.7206 10.8257 15.796C10.2312 15.8738 9.4479 15.875 8.3222 15.875H7.67773C6.55203 15.875 5.76869 15.8738 5.17419 15.796C4.59752 15.7206 4.28573 15.5824 4.05788 15.3692C3.83004 15.1561 3.67143 14.8542 3.5578 14.2838C3.44066 13.6958 3.3873 12.9143 3.31242 11.7911L2.92914 6.04179Z'
                fill={props.color}
            />
            <path
                d='M5.85445 7.5448C6.19792 7.51045 6.50419 7.76104 6.53854 8.10451L6.95521 12.2712C6.98955 12.6146 6.73896 12.9209 6.3955 12.9553C6.05203 12.9896 5.74576 12.739 5.71141 12.3956L5.29474 8.22889C5.2604 7.88542 5.51099 7.57915 5.85445 7.5448Z'
                fill={props.color}
            />
            <path
                d='M10.1455 7.5448C10.489 7.57915 10.7396 7.88542 10.7052 8.22889L10.2885 12.3956C10.2542 12.739 9.94792 12.9896 9.60445 12.9553C9.26099 12.9209 9.0104 12.6146 9.04474 12.2712L9.46141 8.10451C9.49576 7.76104 9.80203 7.51045 10.1455 7.5448Z'
                fill={props.color}
            />
        </svg>
    );
};

IconTrash.defaultProps = {
    className: '',
    onClick: () => { },
    color: '#475467',
};
