
import { env } from '@env';
import { HttpRequestData, httpUtil } from '@/framework';
import { ViewType } from '@/shared/enum/chartEnum';

export interface GetRevenueOverviewChartRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly viewType?: ViewType;
    }
}
export interface GetRevenueOverviewChartResponseItem {
    readonly date: string;
    readonly totalRevenue: number;
    readonly totalInvoice: number;
    readonly salonId: number;
    readonly salonBranchId?: number;
}

export const getRevenueChartSalon = httpUtil.createHttpRequestMeta<
    GetRevenueOverviewChartRequest,
    GetRevenueOverviewChartResponseItem[]
>({
    baseUrl: env.orgServer,
    path: '/revenues/chart-overview/salon',
    method: 'GET',
    authentication: 'bearer'
});
