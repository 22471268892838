import { getRevenueChartSalon, getRevenueChartSalonBranch } from "@/api/report/revenue";
import { salonActiveState, salonsState } from "@/components/state";
import { ViewType } from "@/shared/enum/chartEnum";
import { Dayjs } from "dayjs";
import { useMemo } from "react";
import { useRecoilState } from "recoil";

function useQueriesRevenueChart(dateRange: [Dayjs, Dayjs], objectType: string) {

    const [salonActive] = useRecoilState(salonActiveState);
    const [salons] = useRecoilState(salonsState);

    const viewType = useMemo(() => {
        const isStartYear = dateRange[0].startOf('year').isSame(dateRange[0]);
        const isEndYear = dateRange[1].endOf('year').isSame(dateRange[1]) && dateRange[1].isSame(dateRange[0], 'year');

        const isOnlyOneDay = dateRange[0].isSame(dateRange[1], 'day');

        if(isOnlyOneDay) {
            return ViewType.Hour;
        }

        if(isStartYear && isEndYear) {
            return ViewType.Month;
        }

        return ViewType.Day;
    }, [dateRange]);

    if(salonActive)
    {
        const queriesOverviewAllSalonBranch = useMemo(() => salonActive?.salonBranches?.map((branch) => ({
            requestMeta: getRevenueChartSalonBranch,
            requestData: {
                query: {
                    startDate: dateRange[0].format('YYYY-MM-DD HH:mm:ss'),
                    endDate: dateRange[1].format('YYYY-MM-DD HH:mm:ss'),
                    salonId: branch.salonId,
                    branchId: branch.id,
                    viewType: viewType,
                    objectType: objectType ?? null,
                }
            }
        })), [salonActive, dateRange, objectType]);

        return queriesOverviewAllSalonBranch ?? [];
    }

    const queriesOverviewAllSalons = useMemo(() => salons?.map((salon) => ({
        requestMeta: getRevenueChartSalon,
        requestData: {
            query: {
                startDate: dateRange[0].format('YYYY-MM-DD HH:mm:ss'),
                endDate: dateRange[1].format('YYYY-MM-DD HH:mm:ss'),
                salonId: salon.id,
                viewType: viewType,
                objectType: objectType ?? null,
            }
        }
    })),[salonActive, salons, dateRange, objectType]);

    return queriesOverviewAllSalons ?? [];
}

export default useQueriesRevenueChart;