import { Header } from "antd/es/layout/layout";
import { FormBase } from "@components/base";
import { Block, FlexBox } from "@components/layouts";
import { SelectField } from "@components/inputs/fields/SelectField";
import { DatePickerRange } from "@components/date-picker";
import { Text } from '@components/typo'
import { Dayjs } from "dayjs";
import { useMemo } from "react";
import { theme } from "antd";
import { useRecoilState } from "recoil";
import { accountInfoState, salonActiveState } from "@components/state";

interface HeaderReportProps {
    readonly onChangeDate: (dates: [Dayjs, Dayjs]) => void;
    readonly dateRange: [Dayjs, Dayjs];
    readonly defaultValue?: number;
}
export function HeaderReport(props: HeaderReportProps) {
    const [currentUser] = useRecoilState(accountInfoState);
    const [salonActive, setSalonActive] = useRecoilState(salonActiveState);

    const {token: { colorBgContainer }} = theme.useToken();

    const salonOptions = useMemo(() => {
        const options = currentUser?.salons?.map(salon => ({
            label: salon.name,
            value: salon.id
        })) ?? [];
        
        return [{ label: "Tất cả salon", value: null }, ...options];
    }
    , [currentUser?.salons]);

    return (
        <Header  
            style={{background: colorBgContainer }}
        >
            <FormBase onSubmit={() => { }}>
                <FlexBox alignItems='center' justifyContent="space-between">
                    <FlexBox alignItems='center' gap={20}>
                        <Text fontWeight={700} fontSize={20}>Báo cáo</Text>
                        <Block width={300}>
                            <SelectField
                                size='large'
                                showSearch
                                options={salonOptions}
                                value={salonActive?.id ?? null}
                                name='salonId'
                                allowClear
                                label=""
                                placeholder="Select branch"
                                onChange={(value) => setSalonActive(currentUser?.salons?.find(o => o.id === value) ?? null)}
                            />
                        </Block>
                    </FlexBox>
                    <DatePickerRange
                        onChange={props.onChangeDate}
                        defaultValue={props.dateRange}
                    />
                </FlexBox>
            </FormBase>
        </Header>
    )
}
