import { TableColumnsType, Tag } from "antd";
import { TableCustom } from "@components/table";
import { globalModalState } from "@components/state";
import { useRecoilState } from "recoil";
import { ServiceByAppointmentDetailTable } from "./ServiceByAppointmentDetailTable";

export interface ServiceByAppointmentData {
    readonly id?: string;
    readonly name?: string;
    readonly quantity?: string;
    readonly revenue?: string;
}

export function ServiceByAppointmentTable() {

    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    // const resetGlobalModal = useResetRecoilState(globalModalState);

    const showDetail = (title: string) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: title,
            width: 850,
            content: <ServiceByAppointmentDetailTable />
        });
    };


    const data: ServiceByAppointmentData[] = [
        {
            id: '1',
            quantity: "250",
            name: "Servie 1",
            revenue: "230000"
        },
        {
            id: '2',
            quantity: "615000",
            name: "Servie 2",
            revenue: "230000"
        },
        {
            id: '3',
            quantity: "432000",
            name: "Servie 3",
            revenue: "230000"
        },
        {
            id: '5',
            quantity: "45000",
            name: "Servie 4",
            revenue: "230000"
        },
        {
            id: '6',
            quantity: "345000",
            name: "Servie 5",
            revenue: "230000"
        },
        {
            id: '7',
            quantity: "550000",
            name: "Servie 6",
            revenue: "230000"
        },
        {
            id: '8',
            quantity: "550000",
            name: "Servie 7",
            revenue: "230000"
        }
    ];

    const columns: TableColumnsType<ServiceByAppointmentData> = [
        {
            title: 'Tên dịch vụ',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 150
        },
        {
            title: 'Doanh thu',
            dataIndex: 'revenue',
            key: 'revenue',
            width: 150
        },
        {
            title: "Liên kết",
            width: 150,
            render: (_, record) => {
                return (
                    // <ColumnActions
                    //     viewDetailUrl=""
                    //     onClickEdit={() => {
                    //     }}
                    //     onClickDelete={() => {
                    //     }}

                    // />
                    <a>
                        <Tag onClick={() => { showDetail(record.name ?? '') }} style={{ borderRadius: 2 }} color="default">Xem chi tiết</Tag>
                    </a>
                );
            },
        },
    ];

    return (
        <TableCustom
            scroll={{ x: 800 }}
            placeholderSearchTransCode='Tìm dịch vụ'
            onSearch={() => { }}
            showHeader={true}
            titleTableTransCode="Dịch vụ"
            columns={columns}
            dataSource={data}
        />
    )
}
