import React from 'react';
import { loginState } from '../framework';
import { useResetRecoilState } from 'recoil';

export const useLogout = () => {
    const resetLoginState = useResetRecoilState(loginState);

    return React.useCallback(() => {
        try {
            resetLoginState();
            sessionStorage.clear();
            localStorage.clear();
            window.location.reload();
        } catch (error) {
            console.error("Error during logout:", error);
        }
    }, [resetLoginState]);
};
