import { useMemo } from 'react';
import { Dayjs } from 'dayjs';

import { Salon } from '../components/state';
import { HttpQueries } from '.';
import { GetCustomerGroupChartRequest, GetCustomerGroupChartResponse, requestCustomerGroupChartByAll, requestCustomerGroupChartBySalon, requestCustomerGroupChartBySalonBranch } from '../api/report/customer/chart/getCustomerGroupChart';

interface UseCustomerGroupChartRequestsProps {
    dateRange: Dayjs[];
    salons?: Salon[];
    selectedSalonBranchId?: number;
    salon?: Salon;
    viewType?: number;
}

interface UseCustomerGroupChartRequestsResult {
    customerGroupChartRequests: HttpQueries<GetCustomerGroupChartRequest, GetCustomerGroupChartResponse>[];
}

export function useCustomerGroupChartRequests({
    dateRange,
    salons,
    selectedSalonBranchId,
    salon,
    viewType,
}: UseCustomerGroupChartRequestsProps): UseCustomerGroupChartRequestsResult {

    const customerGroupChartRequests = useMemo(() => {
        if (salon == undefined && selectedSalonBranchId == undefined) {
            return requestCustomerGroupChartByAll(salons, dateRange, viewType);
        }
        if (salon != undefined && selectedSalonBranchId == undefined) {
            return requestCustomerGroupChartBySalon(dateRange, viewType, salon);
        }
        if (salon != undefined && selectedSalonBranchId != undefined) {
            return requestCustomerGroupChartBySalonBranch(dateRange, viewType, salon, selectedSalonBranchId);
        }
        return [];
    }, [dateRange, viewType, salon, selectedSalonBranchId, salons]);


    return {
        customerGroupChartRequests,
    };
}
