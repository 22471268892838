import { Dayjs } from 'dayjs';
import { env } from '@env';
import { HttpRequestData, httpUtil } from '@/framework';
import { ViewType } from '@/shared/enum/chartEnum';
import { Salon } from '@components/state/accountInfoState';
import { HttpQueries } from '@/hooks';

export interface GetTotalCustomerReturnChartRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: string;
        readonly salonBranchId: string;
        readonly startDate: string;
        readonly endDate: string;
        readonly viewType?: ViewType;
    }
}


export interface GetTotalCustomerReturnChartResponse {
    overviewChartInfos: TotalCustomerReturnChart[];
}
export interface TotalCustomerReturnChart {
    date: Dayjs;
    customerCount: number;
    salonBranchId: number;
    salonId: number;
}
export const requestCustomerReturnChartByAll = (
    salons: Salon[] | undefined,
    dateRange: Dayjs[],
    viewType?: ViewType,
) => {
    const requestsData: HttpQueries<GetTotalCustomerReturnChartRequest, GetTotalCustomerReturnChartResponse>[] = salons!.flatMap(salon =>
        salon!.salonBranches!.map(salonBranch => ({
            requestMeta: getCustomerReturnChart,
            requestData: {
                query: {
                    salonId: salon.id,
                    salonBranchId: salonBranch.id,
                    startDate: dateRange[0].toISOString(),
                    endDate: dateRange[1].toISOString(),
                    viewType: viewType,
                },
            },
        }))
    );
    return requestsData;
};
export const requestCustomerReturnChartBySalon = (
    dateRange: Dayjs[],
    viewType?: ViewType,
    salonSelect?: Salon
) => {
    const requestsData: HttpQueries<GetTotalCustomerReturnChartRequest, GetTotalCustomerReturnChartResponse>[] = salonSelect!.salonBranches!.map(salonBranch => ({
        requestMeta: getCustomerReturnChart,
        requestData: {
            query: {
                salonId: salonSelect?.id,
                salonBranchId: salonBranch.id,
                startDate: dateRange[0].toISOString(),
                endDate: dateRange[1].toISOString(),
                viewType: viewType,
            },
        },
    }));
    return requestsData;
};
export const requestCustomerReturnChartBySalonBranch = (
    dateRange: Dayjs[],
    viewType?: ViewType,
    salonSelect?: Salon,
    selectedSalonBranchId?: number
) => {
    const requestsData: HttpQueries<GetTotalCustomerReturnChartRequest, GetTotalCustomerReturnChartResponse>[] = [{
        requestMeta: getCustomerReturnChart,
        requestData: {
            query: {
                salonId: salonSelect?.id,
                salonBranchId: selectedSalonBranchId,
                startDate: dateRange[0].toISOString(),
                endDate: dateRange[1].toISOString(),
                viewType: viewType,
            },
        },
    }]
    return requestsData;
};

export const getCustomerReturnChart = httpUtil.createHttpRequestMeta<
    GetTotalCustomerReturnChartRequest,
    GetTotalCustomerReturnChartResponse
>({
    baseUrl: env.orgServer,
    path: '/customers/return-chart',
    method: 'GET',
    authentication: 'bearer'
});
