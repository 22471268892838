
import { env } from '@env';
import { HttpRequestData, httpUtil, PaginationResponse } from '@/framework';

export interface GetRevenueOfPaymentMethodRequest extends HttpRequestData {
    readonly query: {
        readonly salonId: number;
        readonly startDate: string;
        readonly endDate: string;
    }
}

export interface GetRevenueOfPaymentMethodResponse  {
    readonly totalRevenue?: number;
    readonly totalQuantity?: number;
    readonly paymentMethodId?: number;
    readonly paymentMethodCode?: string;
}

export const getRevenueOfPaymentMethod = httpUtil.createHttpRequestMeta<
    GetRevenueOfPaymentMethodRequest,
    PaginationResponse<GetRevenueOfPaymentMethodResponse>
>({
    baseUrl: env.orgServer,
    path: '/revenues/payment-method',
    method: 'GET',
    authentication: 'bearer'
});
