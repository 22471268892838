export const CreditCardIcon = () => {
    return (
        <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.5806 0H2.70968C1.21316 0 0 1.21316 0 2.70968V21.2903C0 22.7868 1.21316 24 2.70968 24H30.5806C32.0772 24 33.2903 22.7868 33.2903 21.2903V2.70968C33.2903 1.21316 32.0772 0 30.5806 0Z" fill="#ACCEFC" />
            <path d="M0 6.58008H33.2903V11.2252H0V6.58008Z" fill="#3D8CF5" />
            <path d="M10.0646 20.1292H5.41947C5.11148 20.1292 4.8161 20.0069 4.59831 19.7891C4.38053 19.5713 4.25818 19.2759 4.25818 18.9679C4.25818 18.6599 4.38053 18.3646 4.59831 18.1468C4.8161 17.929 5.11148 17.8066 5.41947 17.8066H10.0646C10.3726 17.8066 10.668 17.929 10.8858 18.1468C11.1036 18.3646 11.2259 18.6599 11.2259 18.9679C11.2259 19.2759 11.1036 19.5713 10.8858 19.7891C10.668 20.0069 10.3726 20.1292 10.0646 20.1292Z" fill="white" />
            <path d="M25.4195 20.1292H23.355C23.2181 20.1292 23.0868 20.0069 22.99 19.7891C22.8932 19.5713 22.8389 19.2759 22.8389 18.9679C22.8389 18.6599 22.8932 18.3646 22.99 18.1468C23.0868 17.929 23.2181 17.8066 23.355 17.8066H25.4195C25.5564 17.8066 25.6877 17.929 25.7845 18.1468C25.8813 18.3646 25.9356 18.6599 25.9356 18.9679C25.9356 19.2759 25.8813 19.5713 25.7845 19.7891C25.6877 20.0069 25.5564 20.1292 25.4195 20.1292Z" fill="white" />
            <path d="M30.0647 20.1292H28.0001C27.8633 20.1292 27.732 20.0069 27.6352 19.7891C27.5384 19.5713 27.484 19.2759 27.484 18.9679C27.484 18.6599 27.5384 18.3646 27.6352 18.1468C27.732 17.929 27.8633 17.8066 28.0001 17.8066H30.0647C30.2015 17.8066 30.3328 17.929 30.4296 18.1468C30.5264 18.3646 30.5808 18.6599 30.5808 18.9679C30.5808 19.2759 30.5264 19.5713 30.4296 19.7891C30.3328 20.0069 30.2015 20.1292 30.0647 20.1292Z" fill="white" />
        </svg>
    );
}
