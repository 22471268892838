import { BankTransferIcon } from '@components/icons/BankTransferIcon';
import { CashIcon } from '@components/icons/CashIcon';
import { CreditCardIcon } from '@components/icons/CreditCardIcon';
import { SplitPaymentIcon } from '@components/icons/SplitPaymentIcon';
import { PrepaidCashIcon } from '@components/icons/PrepaidCashIcon';
import { MembershipCreditIcon } from '@components/icons/MembershipCreditIcon';
import { FlexBox, Spacer } from '@/components/layouts';

interface PaymentMethodImageProps {
    readonly paymentMethodCode: string;

    
}

export const PaymentMethodColumn = (props: PaymentMethodImageProps) => {
    const { paymentMethodCode } = props;

    const paymentMethod = getPaymentMethod(paymentMethodCode);

    return (
        <FlexBox>
            {paymentMethod?.icon}
            <Spacer right='xs' />
            {paymentMethod?.name}
        </FlexBox>
    );
};


const getPaymentMethod = (paymentMethodName: string) => {
    switch (paymentMethodName) {
        case "CASH_CARD":
            return {
                icon: <CreditCardIcon />,
                name: "Thẻ tiền mặt"
            }
        case "CASH":
            return {
                icon: <CashIcon />,
                name: "Tiền mặt"
            }
        case "BANK_TRANSFER":
            return {
                icon: <BankTransferIcon />,
                name: "Chuyển khoản"
            }
        case "SWIPE_CARD":
            return {
                icon: <SplitPaymentIcon />,
                name: "Quẹt thẻ"
            }
        case "DEBIT":
            return {
                icon: <PrepaidCashIcon />,
                name: "Ghi nợ"
            }
        case "LOYALTY":
            return {
                icon: <MembershipCreditIcon />,
                name: "Điểm tích luỹ"
            }
        default:
            return {
                icon: null,
                name: "Khác"
            }
    }
};
