import { login } from '../../../api';
import { FormBase } from '../../base';
import { FlexBox } from '../../layouts';
import { InputField } from '../../inputs/fields/InputField';
import { AppButton } from '../../buttons';
import { useHttpCommand } from '../../../hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoginStore } from '../../../hooks/useExistingLogin';
interface LoginFormValues {
    readonly email: string;
    readonly password: string;
    readonly rememberMe: boolean;
}

export const LoginForm = () => {
    const navigate = useNavigate();
    const { mutateAsync, isPending } = useHttpCommand(login);
    const {
        setData: setSaveLogin,
    } = useLoginStore();


    const onSubmit = React.useCallback(
        async (data: LoginFormValues) => {

            await mutateAsync(
                {
                    body: {
                        email: data.email,
                        password: data.password
                    },
                },
                {
                    async onSuccess(response) {
                        setSaveLogin(response);
                        navigate("/");
                    },
                }
            );

        },
        [mutateAsync, navigate, setSaveLogin]
    );
    return (
        <FormBase onSubmit={onSubmit}>
            <FlexBox direction='column' gap={22}>
                <FlexBox direction='column'>
                    <InputField
                        name='email'
                        label=""
                        placeholder="Email"
                    />
                </FlexBox>
                <FlexBox direction='column'>
                    <InputField
                        type='password'
                        name='password'
                        label=''
                        placeholder='Password'
                    />
                </FlexBox>
                <AppButton
                    loading={isPending}
                    htmlType='submit'
                    type='primary'
                    text='Login'
                />
            </FlexBox>
        </FormBase>
    );
};
