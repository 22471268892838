import { SelectField } from "@components/inputs/fields/SelectField";
import { Block, FlexBox, Spacer } from "@components/layouts";
import { Header } from "@components/typo/Header";
import { useRecoilState } from "recoil";
import { Salon, accountInfoState, salonActiveState } from "@components/state";
import { FormBase } from "@components/base";
import { AppButton } from "@components/buttons";
import { IconPrint } from "@components/icons";
import { Text } from '@components/typo'
import ReportCustomerOverview from "./ReportCustomerOverview";
import { Dayjs } from "dayjs";
import ReportCustomerMainChart from "./ReportCustomerMainChart";
import ReportCustomerSubChart from "./ReportCustomerSubChart";
import { useMemo, useState } from "react";

interface CustomerReportProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly salons?: Salon[] | undefined;
}
export default function CustomerReport(props: CustomerReportProps) {
    const [currentUser] = useRecoilState(accountInfoState);
    const [salonActive] = useRecoilState(salonActiveState);

    const [selectedSalonBranchId, setSelectedSalonBranchId] = useState<number | undefined>(salonActive?.salonBranches?.[0].id);
    const salonBranchOptions = useMemo(() => {
        return salonActive?.salonBranches?.map(i => ({
            label: i?.name,
            value: i?.id
        })) ?? []
    }, [salonActive?.salonBranches]);
    const handleSalonBranchChange = (value: number | undefined) => {
        setSelectedSalonBranchId(value);
    };
    return (
        <FormBase onSubmit={() => { }}>
            <Block backgroundColor='white' className="tab-content">
                <FlexBox direction='column' gap={20}>
                    <FlexBox alignItems='center'>
                        <FlexBox>
                            <Header level={3}>
                                Báo cáo khách hàng
                            </Header>
                        </FlexBox>
                        <Spacer right={400}></Spacer>
                        <FlexBox gap={20}>
                            <SelectField size='large'
                                showSearch
                                options={salonBranchOptions}
                                name='salon'
                                label=""
                                placeholder="Select Salon branch"
                                onChange={handleSalonBranchChange}
                                defaultValue={selectedSalonBranchId}
                            />
                            <AppButton >
                                <FlexBox gap={6}>
                                    <IconPrint />
                                    <Text>In tổng hợp</Text>
                                </FlexBox>
                            </AppButton>
                        </FlexBox>
                    </FlexBox>
                    <ReportCustomerOverview  selectedSalonBranchId={selectedSalonBranchId} dateRange={props.dateRange} salons={currentUser?.salons}></ReportCustomerOverview>
                    <ReportCustomerMainChart  selectedSalonBranchId={selectedSalonBranchId} dateRange={props.dateRange} salons={currentUser?.salons}></ReportCustomerMainChart>
                    <ReportCustomerSubChart  selectedSalonBranchId={selectedSalonBranchId} dateRange={props.dateRange} salons={currentUser?.salons}></ReportCustomerSubChart>
                </FlexBox>
            </Block>
        </FormBase>
    )
}
