
import { AccountInfo } from '@components/state';
import { env } from '@env';
import { HttpRequestData, httpUtil } from '@/framework';
import { Dayjs } from 'dayjs';

export interface GetAccountCurrentRequest extends HttpRequestData {
}
export interface SalonBrach {
    readonly id: string;
    readonly name: string;
    readonly address: string;
    readonly salonId: number;
    readonly state: string;
    readonly create: Dayjs;
}
export interface Salon {
    readonly id: string;
    readonly photoId: number;
    readonly name?: string;
    readonly url?: string;
    readonly path?: string;
    readonly created?: Dayjs;
    readonly salonBranches?: SalonBrach;
}
export interface GetAccountCurrentResponse {
    readonly id: string;
    readonly email: string;
    readonly salons: Salon;
}

export const getAccountCurrent = httpUtil.createHttpRequestMeta<
    GetAccountCurrentRequest,
    AccountInfo
>({
    baseUrl: env.orgServer,
    path: '/accounts/current',
    method: 'GET',
    authentication: 'bearer'
});
