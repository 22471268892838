import { IconProps } from '../../Types';

export const IconPen = (props: IconProps) => {
    return (
        <svg
            className={props.className}
            onClick={props.onClick}
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M12.2972 2.18453C13.821 0.660699 16.2916 0.660699 17.8154 2.18453C19.3393 3.70836 19.3393 6.17898 17.8154 7.70281L9.91102 15.6072C9.45942 16.0589 9.19358 16.3247 8.89783 16.5554C8.54925 16.8273 8.17209 17.0604 7.77301 17.2506C7.43443 17.4119 7.07774 17.5308 6.47182 17.7328L3.69589 18.6581L3.02749 18.8809C2.48535 19.0616 1.88764 18.9205 1.48356 18.5164C1.07948 18.1123 0.938378 17.5146 1.11909 16.9725L2.26719 13.5282C2.46915 12.9222 2.58803 12.5655 2.74939 12.227C2.93958 11.8279 3.17268 11.4507 3.44457 11.1021C3.67525 10.8064 3.94112 10.5405 4.39277 10.0889L12.2972 2.18453ZM3.66705 17.3501L6.03498 16.5608C6.69425 16.341 6.97435 16.2465 7.23524 16.1222C7.55235 15.971 7.85206 15.7858 8.12906 15.5698C8.35694 15.392 8.56673 15.1837 9.05812 14.6924L15.366 8.38446C14.7088 8.15267 13.8622 7.72301 13.0696 6.93041C12.277 6.13781 11.8473 5.29115 11.6155 4.63395L5.3076 10.9418C4.81622 11.4332 4.60795 11.643 4.43021 11.8709C4.21416 12.1479 4.02893 12.4476 3.8778 12.7647C3.75347 13.0256 3.65896 13.3057 3.4392 13.965L2.64989 16.3329L3.66705 17.3501ZM12.6294 3.62003C12.6579 3.76583 12.7061 3.96402 12.7865 4.19572C12.9674 4.71734 13.3093 5.40239 13.9534 6.04653C14.5976 6.69066 15.2826 7.03252 15.8042 7.21349C16.0359 7.29387 16.2341 7.34204 16.3799 7.37054L16.9316 6.81893C17.9672 5.78325 17.9672 4.10409 16.9316 3.06841C15.8959 2.03274 14.2167 2.03274 13.181 3.06841L12.6294 3.62003Z'
                fill={props.color}
            />
        </svg>
    );
};

IconPen.defaultProps = {
    className: '',
    onClick: () => { },
    color: '#475467',
};
