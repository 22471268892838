import { TimeRangePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
dayjs.locale('vi')

export const rangePresets: TimeRangePickerProps['presets'] = [
    { label: 'Hôm Nay', value: [dayjs().startOf('day'), dayjs().endOf('day')] },
    { label: 'Hôm qua', value: [dayjs().add(-1, 'd').startOf('day'), dayjs().add(-1, 'd').endOf('day')] },
    { label: 'Tuần này', value: [dayjs().startOf('week'), dayjs().endOf('week')] },
    { label: 'Tuần trước', value: [dayjs().add(-1, 'w').startOf('week'), dayjs().add(-1, 'w').endOf('week')] },
    { label: 'Tháng này', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
    { label: 'Tháng trước', value: [dayjs().add(-1, 'M').startOf('month'), dayjs().add(-1, 'M').endOf('month')] },
    { label: 'Năm này', value: [dayjs().startOf('year'), dayjs().endOf('year')] },
    { label: 'Năm trước', value: [dayjs().add(-1, 'y').startOf('year'), dayjs().add(-1, 'y').endOf('year')] },
];

export const dateHelper = {
    getStartAndEndOfWeek: () => {
        const currentDate = dayjs();

        const startOfWeek = currentDate.clone().startOf('week').add(1, 'day').endOf('day');
        const endOfWeek = currentDate.clone().endOf('week').add(1, 'day').endOf('day');

        return {
            startOfWeek: startOfWeek,
            endOfWeek: endOfWeek,
        };
    },
    getLabelPresets: (dateRange: [Dayjs, Dayjs]) => {
        const foundPreset = rangePresets.find((preset) => {
            const [start, end] = preset.value as [Dayjs, Dayjs];

            return start.isSame(dateRange[0]) && end.isSame(dateRange[1]);
        });

        return foundPreset?.label;
    },
    getStartAndEndOfMonth: (date?: string) => {
        const currentDate = date ? dayjs(date) : dayjs();
        const startOfMonth = currentDate.clone().startOf('month');
        const endOfMonth = currentDate.clone().endOf('month');

        return {
            startOfMonth: startOfMonth,
            endOfMonth: endOfMonth,
        };
    },
    checkIsRangeDateOfMonth: (startDate: Dayjs, endDate: Dayjs) => {
        const startOfMonth = startDate.startOf('month');
        const endOfMonth = startDate.endOf('month');

        return startOfMonth.isSame(startDate) && endOfMonth.isSame(endDate);
    },
    getDateRangePrevious: (startDate: Dayjs, endDate: Dayjs) => {
        const numberDaysRange = endDate.diff(startDate, 'd');

        const isViewByOneDay = numberDaysRange === 0;
        const isViewByMonth = dateHelper.checkIsRangeDateOfMonth(startDate, endDate);

        const dateBefore = startDate.add(-1, 'd');

        if (isViewByOneDay) {
            return [dateBefore, endDate.add(-1, 'd')];
        }

        if (isViewByMonth) {
            return [startDate.add(-1, 'month'), dateBefore];
        }

        return [startDate.add(-numberDaysRange, 'd'), dateBefore];
    }
};