import { useRecoilState } from "recoil";
import { accountInfoState, salonActiveState } from "../../components/state/index.ts";
import { Block } from "../../components/layouts/index.ts";
import { HeaderReport } from "./children.tsx/index.ts";
import { useState } from "react";
import { Tabs, TabsProps } from "antd";
import './report.scss';
import CustomerReport from "./children.tsx/customer/CustomerReport.tsx";
import { RevenueReport } from "./children.tsx/revenue/RevenueReport.tsx";
import dayjs, { Dayjs } from "dayjs";
import { AppointmentReport } from "./children.tsx/appointment/AppointmentReport.tsx";
import { WarehouseReport } from "./children.tsx/warehouse/WarehouseReport.tsx";

const reportTabKeys = {
    REVENUE: 'REVENUE',
    CUSTOMER: 'CUSTOMER',
    APPOINTMENT: 'APPOINTMENT',
    WAREHOUSE: 'WAREHOUSE',
};

function Report() {
    const [currentUser] = useRecoilState(accountInfoState);
    const [salonActive] = useRecoilState(salonActiveState);

    const [activeTab, setActiveTab] = useState(reportTabKeys.REVENUE);
  
    const currentDate = dayjs();
    const startOfMonth = currentDate.clone().startOf('month');
    const endOfMonth = currentDate.clone().endOf('month');

    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([startOfMonth, endOfMonth]);

    const tabItems: TabsProps['items'] = [
        {
            key: reportTabKeys.REVENUE,
            label: 'Doanh thu',
            children: <RevenueReport dateRange={dateRange}/>,
        },
        {
            key: reportTabKeys.CUSTOMER,
            label: 'Khách hàng',
            children: <CustomerReport salons={currentUser?.salons} dateRange={dateRange}/>
        },
        {
            key: reportTabKeys.APPOINTMENT,
            label: 'Lịch hẹn',
            children: <AppointmentReport/>
        },
        {
            key: reportTabKeys.WAREHOUSE,
            label: 'Kho hàng',
            children: <WarehouseReport/>
        },
    ];

    return (
        <Block>
            <HeaderReport 
                defaultValue={salonActive?.id} 
                dateRange={dateRange} 
                onChangeDate={setDateRange} 
            />
            <Tabs defaultActiveKey={activeTab} items={tabItems} onChange={setActiveTab} destroyInactiveTabPane/>
        </Block >
    )
}
export default Report