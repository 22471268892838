import { Dayjs } from 'dayjs';
import { Pie } from '@ant-design/plots';
import { Badge, Card } from 'antd';
import { Block, FlexBox, Spacer } from '@components/layouts';
import { IconGrowDown, IconGrowUp } from '@components/icons';
import { Text } from '@components/typo'
import { Salon } from '@components/state';
import { chartHelper } from '@/helpers';
import { useCustomerGenderChartRequests } from '@hooks/useCustomerGenderChartRequests';
import { useHttpQueries } from '@/hooks';
import { useMemo } from 'react';

interface ReportCustomerGenderPieChartProps {
    readonly dateRange: [Dayjs, Dayjs];
    readonly salons?: Salon[] | undefined;
    readonly selectedSalonBranchId?: number | undefined;
    readonly salon?: Salon;
}

interface PieDataChart {
    type: string;
    color: string;
    value: number;
    preValue: number;
}

export default function ReportCustomerGenderPieChart(props: ReportCustomerGenderPieChartProps) {
    const { dateRange, salons, selectedSalonBranchId, salon } = props;
    const viewType = chartHelper.getViewTypeChart(dateRange);

    const { customerGenderChartRequests
    } = useCustomerGenderChartRequests({
        dateRange,
        salons,
        selectedSalonBranchId,
        salon,
        viewType,
    });

    const queriesCustomerGenderChart = useHttpQueries(customerGenderChartRequests);

    const totalCustomerGenderChart = useMemo(() => {
        const isLoading = queriesCustomerGenderChart.some(query => query.isLoading);
        const CustomerNewCharts = queriesCustomerGenderChart.flatMap(o => o.data);
        return { isLoading, CustomerNewCharts };
    }, [queriesCustomerGenderChart]);

    const renderGrow = (current: number, previous: number) => {
        const grow = current - previous;
        const color = grow >= 0 ? 'green' : 'red';

        if (previous === 0) {
            return (
                <FlexBox>
                    <Text fontWeight={600} color={color}>(000.00%)</Text>
                    <IconGrowUp />
                </FlexBox>
            );
        }

        const percent = (grow / previous) * 100;

        return (
            <FlexBox alignItems='center' >
                <Text fontWeight={600} color={color}>({percent.toFixed(2)}%)</Text>
                {grow > 0 ? <IconGrowUp /> : <IconGrowDown />}
            </FlexBox>
        );

    };
    const dataChart: PieDataChart[] = totalCustomerGenderChart.CustomerNewCharts.map(o => (
        {
            type: o?.typeGender ?? 'Khac',
            value: o?.valueGender ?? 0,
            preValue: 107 ?? 0,
            color: '#2970FF'
        }
    ));
    const config = {
        data: dataChart,
        angleField: 'value',
        colorField: 'type',
        height: 300,
        className: 'pie-chart',
        legend: false,
        scale: { color: { range: ['#2970FF', '#84ADFF', '#B2CCFF', '#D1E0FF'] } },
        tooltip: {
            formatter: (datum: PieDataChart) => ({
                name: datum.type,
                value: datum.value
            }),
        },
    };

    return (
        <Block width='100%'>
            <Card style={{ height: 557 }} title="Giới tính">
                <Pie {...config} loading={totalCustomerGenderChart.isLoading} />
                <Spacer top={35} left_right={20}>
                    {dataChart.map(o => {
                        // const resultPercent = ((o.value! / (overviewChartClientGender?.totalClient ?? 0)) * 100).toFixed(2);
                        return (
                            <FlexBox key={o.type} justifyContent='space-between'>
                                <Badge color={o.color} text={o.type} />
                                <Block>
                                    <FlexBox gap={8}>
                                        <Text translateCode={`40 %  `} />
                                        {renderGrow(o.value, o.preValue)}
                                    </FlexBox>
                                </Block>
                            </FlexBox>
                        );
                    })}
                </Spacer>
            </Card>
        </Block>

    );
}
