import { TableColumnsType } from "antd";
import { TableCustom } from "@components/table";

export interface ServiceByAppointmentDetailData {
    readonly id?: string;
    readonly name?: string;
    readonly quantity?: string;
    readonly revenue?: string;
}

export function ServiceByAppointmentDetailTable() {

    const data: ServiceByAppointmentDetailData[] = [
        {
            id: '1',
            quantity: "250",
            name: "Chi nhánh 1",
            revenue: "230000"
        },
        {
            id: '2',
            quantity: "615000",
            name: "Chi nhánh 2",
            revenue: "230000"
        },
        {
            id: '3',
            quantity: "432000",
            name: "Chi nhánh 3",
            revenue: "230000"
        },
        {
            id: '5',
            quantity: "45000",
            name: "Chi nhánh 4",
            revenue: "230000"
        }
    ];

    const columns: TableColumnsType<ServiceByAppointmentDetailData> = [
        {
            title: 'Chi nhánh',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 150
        },
        {
            title: 'Doanh thu',
            dataIndex: 'revenue',
            key: 'revenue',
            width: 150
        }
    ];

    return (
        <div style={{ paddingTop: 20 }}>
            <TableCustom
                showHeader={false}
                columns={columns}
                dataSource={data}
                showFooter={false}
            />
        </div>
    )
}
