
export const IconTotalWalkInClient = () => {
    return (
        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.800049 24C0.800049 10.7452 11.5452 0 24.8 0C38.0549 0 48.8 10.7452 48.8 24C48.8 37.2548 38.0549 48 24.8 48C11.5452 48 0.800049 37.2548 0.800049 24Z" fill="#FFF7E6" />
            <path opacity="0.4" d="M17.3798 18.9448C17.027 18.6312 16.6962 18.9007 16.2552 19.3858C16.0949 19.5622 15.629 20.0693 15.241 20.5103C14.7559 21.0615 15.2182 21.7712 15.571 22.1461C15.9238 22.5209 23.2962 29.8554 25.1625 31.7155C26.6185 33.1667 27.743 33.2769 28.2942 33.2769H32.7703C34.6893 33.2769 34.754 31.6053 34.7981 30.7894C34.8422 29.9736 34.0925 29.7531 33.6075 29.7531H29.4181C28.3163 29.7531 27.8532 29.5285 27.3241 29.0434C26.9138 28.6674 17.7766 19.2976 17.3798 18.9448Z" fill="#F8AC2F" />
            <path d="M20.2677 15.443C20.0214 15.6918 18.3641 17.3613 17.5776 18.1771L22.4947 23.05C22.5902 21.5139 22.804 18.3274 22.8922 17.3572C22.9985 16.1886 22.804 15.7255 22.0978 15.2225C21.4502 14.7613 20.5991 15.1081 20.2677 15.443Z" fill="#F8AC2F" />
            <path d="M24.8981 18.8606C24.6864 19.2487 23.8691 19.2575 23.4869 19.2134L23.1782 23.7336C24.1851 24.7405 26.4324 26.9545 27.2573 27.8568C28.3826 29.0875 28.889 28.9813 29.4182 28.9813H33.0784C31.9546 27.8568 30.4104 25.0565 29.8151 23.7336C29.5137 24.0717 28.8229 24.814 28.4701 25.0786C28.0952 25.255 27.8086 24.7478 28.007 24.5494C28.1658 24.3906 29.0874 23.4102 29.5284 22.9398L28.7347 20.7789C28.1099 21.4184 26.7987 22.7458 26.5518 22.9398C26.2431 23.1823 25.8903 22.6091 26.199 22.3004C26.5077 21.9917 27.4337 20.9994 28.4701 19.9852C27.9409 18.0669 27.4778 17.7141 26.5518 17.67C25.6257 17.6259 25.1626 18.3756 24.8981 18.8606Z" fill="#F8AC2F" />
            <rect x="14.8" y="29.749" width="6.52664" height="0.970176" rx="0.485088" fill="#F8AC2F" />
            <rect x="14.8" y="32.3066" width="10.0986" height="0.970176" rx="0.485088" fill="#F8AC2F" />
        </svg>
    );
};

