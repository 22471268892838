import { LoginForm } from "@components/form/login/LoginForm";
import { Block, FlexBox } from "@components/layouts";

export function Login() {
    return (
        <FlexBox>
            <Block alignItems='center' display='flex' backgroundImage="url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg)" width="100vw" height="100vh">
                <FlexBox justifyContent='center'>
                    <Block textAlign='center' width="30%">
                        <img style={{ maxWidth: 400, height: 'auto', maxHeight: 170, paddingBottom: 30 }} src="https://my.easysalon.vn/static/assets/logo/logo-my.png" alt="logo" />
                        <LoginForm />
                    </Block>
                </FlexBox>
            </Block >
        </FlexBox>
    )
}
